import { Box } from "@chakra-ui/react";
import CustomTabs from "../../../components/customTabs/customTabs";
import Navbar from "../../../components/navbar/navbar";
import Rewards from "./rewards";
import Prizes from "./prizes";
import { useEffect, useState } from "react";
import axiosInstance from "../../../services/api";
import { useParams } from "react-router-dom";
import SpinLoader from "../../../components/spinLoader/spinLoader";
import Winners from "./winners";

const Games = () => {
    const [loading, setLoading] = useState(true)
    const [initiativeData, setInitiativeData] = useState([]) 
    const {id} = useParams()

    const getInitiativeData = async() => {
        try {
            const { data } = await axiosInstance.get(`/initiative-stores/${id}`)
            setInitiativeData(data?.data)
        } catch(error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
      }
    
      useEffect(() => {
        getInitiativeData()
      }, [])

  const data = [
    {
      title: "Games",
      render: <Rewards initiativeData={initiativeData} />
    },
    {
      title: "Winners",
      render: <Winners initiativeData={initiativeData} />,
    },
    {
      title: "Prizes",
      render: <Prizes initiativeData={initiativeData} />,
    },
  ];
  return (
    <>
      <Navbar title={"Games & Rewards"} showIcon={false} />
      {loading ?
     <SpinLoader />
     :
      <Box pt="4rem" px="1rem">
        <CustomTabs data={data} />
      </Box>}
    </>
  );
};

export default Games;
