import { Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'

const SkeletonLoader = () => {
  return (
    <Stack mt=".7rem">
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
        <Skeleton height={"30px"} />
    </Stack>
  )
}

export default SkeletonLoader