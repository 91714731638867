import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/customInput/customInput';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../services/api';
import { Box, Flex, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import CustomSelect from '../../../components/customSelect/customSelect';
import statesLgaJson from '../../../utils/stateLGAs';
import ImageUploader from '../../../components/imageUploader/imageUploader';
import CustomTextArea from '../../../components/customTextArea/customTextArea';

const places = statesLgaJson?.map(i => ({
  title: i?.state,
  value: i?.state,
  listValue: i?.lgas?.map(i => ({
    title: i,
    value: i,
  }))
}))

const CreateMessage = ({loading, setLoading, setTriggerClose, id}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [loadingi, setLoadingi] = useState(false);

  const toast = useToast();
  const [titles, setTitles] = useState([])

  const getTitles = async() => {
    setLoading(true)
    try {
      const {data} = await axiosInstance.get(`messages/title-options`)
      setTitles(data?.data)
    } catch(error) {
      // toast({
      //   title: "An Error Occurred",
      //   description: error?.response?.data?.message,
      //   status: "error",
      // });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTitles()
  }, [])

  const titlesData = titles?.map(i => ({
    title: i,
    value: i
  }))

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };

  const createMessage = async(e) => {
    e.preventDefault()
    setLoading(true)

    const datax = imageURL !== '' ?
    {
      title,
      description,
      initiativeStoreId: id,
      imageURL
    }
    :
    {
      title,
      description,
      initiativeStoreId: id
    }

    try {
      const {data} = await axiosInstance.post('/messages', datax)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoading(false)
    }
  }



  return (
    <form onSubmit={createMessage}>
        <Box height={"58vh"} overflowY={"scroll"}>
            <CustomSelect
            required
            onChange={e => setTitle(e.target.value)}
            mb=".8rem"
            label="Messsage Title"
            options={titlesData}
            />
        
            <CustomTextArea
            rows="3"
            mt="0"
            onChange={e => setDescription(e.target.value)}
            mb=".8rem"
            label="Description"
            required
            />
            <Flex mb=".8rem" gap=".5rem">
                {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                <ImageUploader
                onImagesUpload={handleImagesUpload}
                setLoading={setLoadingi}
                loading={loadingi}
                max={1}
                src="camera"
                />
            </Flex>
        </Box>
        <PrimaryButton mt="1rem" type="submit" text="Create Message" icon="add" func={() => {}} loading={loading} />
    </form>
  )
}

export default CreateMessage;