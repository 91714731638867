import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons';
import CustomTable from '../../../components/customTable/customTable';
import CustomDrawer from '../../../components/customDrawer/customDrawer';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import CustomInput from '../../../components/customInput/customInput';
import CustomSelect from '../../../components/customSelect/customSelect';
import Navbar from '../../../components/navbar/navbar';
import Overview from '../../../components/overView/overView';
import CustomTabs from '../../../components/customTabs/customTabs';
import AllTasks from './allTasks/allTasks';
import PendingTasks from './pendingTasks/pendingTasks';
import CompletedTasks from './completedTasks/completedTasks';
import EmptyState from '../../../components/emptyState/emptyState';
import axiosInstance from '../../../services/api';
import { useParams } from 'react-router-dom';
import SpinLoader from '../../../components/spinLoader/spinLoader';

const Tasks = ({source}) => {
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingu, setLoadingu] = useState(false);
  const [month, setMonth] = useState('');
  const [tasks, setTasks] = useState([])
  const [refetch, setRefetch] = useState('')

  const {id} = useParams()

  


  const options2 = [
    {
      title: "Sku 1",
      value: "Sku 1"
    },
    {
      title: "Sku 2",
      value: "Sku 2"
    }
  ]

  const getTasks = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(`tasks/initiative-store-tasks/${id}`)
        setTasks(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

    useEffect(() => {
        getTasks()
    }, [refetch])


const data = [
    {
        title: "All",
        render: <AllTasks sid={id} loading={loading} tasks={tasks} setLoading={setLoading} loadingu={loadingu} setLoadingu={setLoadingu} />,
    },
    // {
    //     title: "Completed",
    //     render: <CompletedTasks loading={loadingx} setComment={setComment} tasks={tasks} uploadedImages={uploadedImages} setLoading={setLoadingi} handleImagesUpload={handleImagesUpload} />,
    // },
    // {
    //     title: "Pending",
    //     render: <PendingTasks loading={loadingx} setComment={setComment} tasks={tasks} uploadedImages={uploadedImages} setLoading={setLoadingi} handleImagesUpload={handleImagesUpload} />,
    // }
]

  return (
    <Box overflowY={"scroll"}>
        {source !== 'dashboard' && source === "in" && <Navbar title={"Tasks"} showIcon={false} />}
        <Box pt={source === 'dashboard' ? "0" : source !== "in" ? "" : "3.5rem"} px={source !== "in" ? "0" : "1rem"} pb="4.5rem">
            {/* <Flex alignItems={"center"} gap="1rem" mt=".5rem">
                <CustomSelect 
                placeholder={"Month"}
                selection={month}
                setSelection={setMonth}
                options={options2}
                mb=".8rem"
                height="38px"
                width="95px"
                fontSize=".9rem"
                />
            </Flex> */}
            {loading ?
            <SpinLoader />
            :
            tasks?.length < 1 ?
            <Flex justifyContent={"center"} mt="2rem">
              <Box>
                <EmptyState text={"No tasks"} />
                <Text mt=".5rem" maxW={"300px"} textAlign={"center"} mx="auto" color={"#667085"}>You can be assigned a task  by a store by the store owner or supervisor</Text>
              </Box>
            </Flex>
            :
            <CustomTabs data={data} />}
        </Box>
    </Box>
  )
}

export default Tasks;