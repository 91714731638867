import React from "react";
import Assets from "./assets";
import CreatedAssets from "./createdAssets/createdAssets";
import { Box, Text } from "@chakra-ui/react";
import CustomTabs from "../../components/customTabs/customTabs";

const MainAssets = () => {
  const data = [
    {
      title: "Scanned Assets",
      render: <Assets />,
    },
    {
      title: "Created Assets",
      render: <CreatedAssets />,
    },
  ];
  return (
    <>
      <CustomTabs data={data} />
    </>
  );
};

export default MainAssets;
