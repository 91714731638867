import { Box, Heading, Image, Text } from '@chakra-ui/react'
import PrimaryButton from '../../components/primaryButton/primarybutton'
import { useNavigate } from 'react-router-dom'
import VerifyIllustration from '../../assets/verifyIllustration.svg'

const VerifySuccess = () => {
  const navigate = useNavigate()
  return (
    <Box padding="1rem">
        <Box mt="10rem">
            <Image width={"20%"} src={VerifyIllustration} mx="auto" />
            <br />
            <Heading textAlign={"center"} mt={6} fontWeight={500} fontSize="1.1rem" color="#030303" fontFamily='Roboto'>Password changed</Heading>
            <Text textAlign={"center"} mt={2} mb="2rem" color={"#667085"} fontSize={".9rem"}>Password succesfully reset</Text>
        </Box>
        <br />
        <PrimaryButton type="submit" text="Login" icon="next" func={() => navigate("/login")} />
    </Box>
  )
}

export default VerifySuccess