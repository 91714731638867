import { Box } from '@chakra-ui/react';
import Select from 'react-select';

const SearchableSelect = ({ options, onChange, placeholder, isRequired }) => {
    const customStyles = {
        control: (provided) => ({
          ...provided,
          minHeight: '50px',
          width: '100%',
          borderRadius: "8px",
          borderColor: "#667085"
        }),
        option: (provided) => ({
          ...provided,
          padding: '10px',
          cursor: "pointer",
        }),
    };
  return (
    <Box zIndex={"100 !important"}>
      <Select
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isSearchable
        styles={customStyles}
        required={isRequired}
      />
    </Box>
  );
};

export default SearchableSelect;