import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Bell from './bell.svg'
import { truncateText } from '../../../utils/helper'
import {TfiAngleLeft} from 'react-icons/tfi'
import SpinLoader from '../../../components/spinLoader/spinLoader'
import axiosInstance from '../../../services/api'
import moment from 'moment'

const dummy = [
    {
        time: "10:12AM",
        message: "You have a message from someone close to you, please read."
    },
    {
        time: "9:05AM",
        message: "You have a message from someone close to you, please read."
    },
    {
        time: "7:23AM",
        message: "You have a message from someone close to you, please read."
    }
]

const Notifications = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(dummy)
  const [loading, setLoading] = useState(false)
  const [loadingm, setLoadingm] = useState(false)
  const [trigger, setTrigger] = useState(Math.random())

  const getNotifications = async () => {
        setLoading(true)
        try {
            const {data} = await axiosInstance.get("/notifications/in-app")
            // console.log('notifications', data?.data)
            setNotifications(data?.data)
        } catch (error) {
            console.log(error?.response)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getNotifications()
    }, [trigger])

    const markAll = async() => {
    setLoadingm(true)
    try {
        const {data} = await axiosInstance.patch("/notifications/in-app/mark-all-as-read")
        setTrigger(Math.random())
        // console.log(data)
    } catch(error) {
        console.log(error?.response?.data?.message)
    } finally {
        setLoadingm(false)
    }
    }

    const handleNotifClick = (type, entityId, id) => {
    switch (type) {
        case 'Device':
            navigate(`/requests`);
        break;
        case 'Sale Item Offer':
            navigate(`/offers`)
        break;
        case 'Free Item Entrant':
            navigate(`/giveaway`)
        break;
        default:
        break;
    }
    }


  return (
    <>
        {loading ?
        <SpinLoader />
        :
        <Box p="2rem .5rem 4.5rem .5rem">
            <Flex gap=".7rem" alignItems={"center"}>
                <TfiAngleLeft color="#292D32" fontSize={"1.3rem"} onClick={() => navigate(-1)} style={{cursor: "pointer"}} />
                <Text fontSize={"1.2rem"} fontWeight={"600"} color="#475467">Notifications</Text>
            </Flex>
            <Box px=".6rem" mt="1.3rem" pb=".5rem" height={"65vh"} overflowY={"scroll"}
            sx={
                { 
                '::-webkit-scrollbar':{
                    display:'none'
                }
                }
            }>
                {notifications?.map(({createdAt, description}, index) => (
                    <Flex key={index} p=".7rem" gap="1rem" border="1px solid #7F56D9" alignItems={"center"} borderRadius={"8px"} mb=".5rem">
                    <Image src={Bell} />
                        <Box>
                            <Text fontSize={".9rem"} lineHeight={"16px"} fontWeight={"500"} color={"#475467"}>{description}</Text>
                            <Text color="#98A2B3" mt=".3rem" fontSize={"10px"}>{moment(createdAt).calendar()}</Text>
                        </Box>
                    </Flex>
                ))}
            </Box>
        </Box>}
    </>
  )
}

export default Notifications;