import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import GoBack from '../../../../components/goBack'
import One from '../../../../assets/dummyImages/one.svg'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import Illustration from "../../../../assets/clockInIllustration.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import Map from '../../../../components/mapView/mapView'
import { getDistance } from 'geolib'
import SpinLoader from '../../../../components/spinLoader/spinLoader'
import axiosInstance from '../../../../services/api'
import { useSelector } from 'react-redux'

const StoreFront = () => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const [coordinates, setCoordinates] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingx, setLoadingx] = useState(false)

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  console.log(state)
  const toast = useToast()

  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude })
          setLoading(false)
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  const distance = coordinates !== null ? getDistance(
    { latitude: coordinates?.latitude, longitude: coordinates?.longitude },
    { latitude: state?.store?.location?.coordinates[1], longitude: state?.store?.location?.coordinates[0] }
  ) : ''


  const clockIn = async() => {
    if(currentUser?.deviceId !== null) {
      setLoadingx(true)
    const datax = {
      deviceId: currentUser?.deviceId,
      latitude: coordinates?.latitude,
      longitude: coordinates?.longitude,
      requestSourcePlatform: 'web'
    }
    try {
      const { data } = await axiosInstance.post(`/initiative-stores/${state?.id}/attendances/clock-in`, datax)
      navigate('dashboard')
      toast({
        title: "",
        description: data?.message,
        status: "success",
      });
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoadingx(false)
    }
    } else {
      toast({
        title: "An Error Occurred",
        description: 'No device ID found, please ensure you have previously signed up and logged in on the mobile app.',
        status: "error",
        position: 'top'
      });
    }
  }

  return (
    <Box position={"relative"}>
        <Box position={"absolute"} top="1%" left="3%" zIndex={"5"}>
            <GoBack />
        </Box>

        {loading ?
        <SpinLoader />
        :
        <>
          <Map coordinates={state?.store?.location?.coordinates} height={"350px"} />
          <Flex transform={"translateY(-2.3rem)"} bg="#FFF" alignItems={"center"} gap=".5rem" maxW={"250px"} mx="auto" p=".7rem" boxShadow="0px 4px 24px 0px #0000001A" border="1px solid #D0D5DD" borderRadius={"68px"}>
              <Box height={"50px"} width={"60px"} backgroundImage={One} borderRadius={"108px"} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} />
              <Box>
                  <Text mb=".3rem" fontSize={"1rem"} color="#475467" fontWeight={"600"} textTransform={"capitalize"}>{state?.store?.name}</Text>
                  <Text fontSize={".85rem"} color="#667085" fontWeight={"500"}><HiOutlineLocationMarker style={{display: "inline", color: "#32D583", transform: "translateY(2px) scale(1.1)"}} />{`${state?.store?.area}, ${state?.store?.state}`}</Text>
              </Box>
          </Flex>
          <Box padding={"0 1rem"}>
              <Flex justifyContent={"center"} mb=".5rem">
                  <Box>
                  <Image mx="auto" src={Illustration} />
                  <Text fontSize={".9rem"} textAlign={"center"} mt=".3rem" fontWeight={"400"} maxW={"280px"} color="#667085">{distance <= 150 ? "You are within 150m of this store entrance and you can clock in" : `You are ${distance?.toLocaleString()}m away from this store entrance and cannot clock in`}</Text>
                  </Box>
              </Flex>
              <br />
              <PrimaryButton loading={loadingx} disabled={distance > 150} text="Clock in" icon="next" onClick={clockIn} />
              <PrimaryButton mt="1rem" loading={loadingx} text="Proceed without clock in" icon="next" onClick={() => navigate('dashboard')} />
              {/* <Text mt="1rem" textAlign={"center"} cursor={"pointer"} fontSize={".9rem"} color="#344054" fontWeight={"600"}>Skip clock in</Text> */}
          </Box>
        </>}
    </Box>
  )
}

export default StoreFront