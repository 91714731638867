import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/customInput/customInput';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../services/api';
import { Box, Flex, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import CustomSelect from '../../../components/customSelect/customSelect';
import statesLgaJson from '../../../utils/stateLGAs';
import ImageUploader from '../../../components/imageUploader/imageUploader';
import CustomTextArea from '../../../components/customTextArea/customTextArea';
import countriesData from '../../../utils/countries.json'

const places = statesLgaJson?.map(i => ({
  title: i?.state,
  value: i?.state,
  listValue: i?.lgas?.map(i => ({
    title: i,
    value: i,
  }))
}))

const CreateStore = ({ loading, setLoading, setTriggerClose }) => {
  const [streetName, setStreetName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [ownerFirstName, setOwnerFirstName] = useState("");
  const [ownerLastName, setOwnerLastName] = useState("");
  const [email, setEmail] = useState("")
  const [storeType, setStoreType] = useState("");
  const [storeLevel, setStoreLevel] = useState("");
  const [phone, setPhone] = useState("");
  const [outletPhone, setOutletPhone] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [category, setCategory] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [state, setState] = useState("");
  const [area, setArea] = useState("");
  const [town, setTown] = useState("");
  const [country, setCountry] = useState("");
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [loadingi, setLoadingi] = useState(false);
  const [team, setTeam] = useState("")
  const [brand, setBrand] = useState("")
  const [additionalInfo, setAdditionalInfo] = useState('')

  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [loadingx, setLoadingx] = useState(false);

  const [categories, setCategories] = useState([]);

  // const [purchaseWillingness, setPurchaseWillingness] = useState("")
  // const [buyingPreferences, setBuyingPreferences] = useState([])

  const toast = useToast();


  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude })
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  useEffect(() => {
    // Fetch the user's country using ipinfo.io
    fetch("https://ipinfo.io/json?token=1920c15b6512bf")
      .then((response) => response.json())
      .then((data) => {
        const countryName = data.country; // ISO country code
        // const countryFullName = data.country_name || countryName; // Full country name (depends on the service used)
        console.log(countryName)
        // Format the response
        setDefaultCountry(countryName);
      })
      .catch((error) => console.error("Error fetching location:", error));
  }, []);

  const getCategories = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(`store-categories`)
      setCategories(data?.data)
    } catch (error) {
      console.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  const categoriesx = categories?.filter(k => k?.isSelectable === true)?.map(i => ({
    title: i?.name,
    value: i?.name,
    listValue: i?.types?.map(v => ({
      title: v?.name,
      value: v?.name,
      listValue: v?.levels?.map(k => ({
        title: k,
        value: k
      }))
    }))
  }))

  const getClients = async () => {
    setLoadingx(true)
    try {
      const { data } = await axiosInstance.get('clients?showOnStoreCreation=true')
      setClients(data?.data)
    } catch (error) {
      console.error(error?.response?.data?.message)
    } finally {
      setLoadingx(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const createStore = async (e) => {
    e.preventDefault()
    setLoading(true)

    const myObj = {}

    const datax = {
      name: storeName,
      phoneNumber: outletPhone,
      streetName,
      state,
      area,
      town,
      ownerFirstName,
      ownerLastName,
      ownerPhoneNumber: phone,
      ownerEmail: email,
      type: storeType,
      level: storeLevel,
      coordinates,
      category,
      imageURL,
      clientId: client,
      team,
      brand,
      additionalInfo,
      streetNumber,
      country: country || countries?.find(x => x.code === defaultCountry)?.value
    }

    for (const key in datax) {
      const value = datax[key];
      if (value !== "" && value !== undefined && value !== null) {
        myObj[key] = value;
      }
    }

    try {
      const { data } = await axiosInstance.post('/stores', myObj)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoading(false)
    }
  }

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };

  const clientOptions = clients?.map(i => ({
    title: i?.companyName,
    value: i?._id
  }))

  const countries = countriesData?.map(x => ({
    title: x?.name,
    value: x?.name?.toLowerCase(),
    code: x?.code2,
    states: x?.states
  }))

  const places2 = countries?.find(x => x?.value === (country || "nigeria"))?.states?.map(i => ({
    title: i?.name,
    value: i?.name?.toLowerCase(),
    listValue: i?.subdivision?.map(i => ({
      title: i,
      value: i,
    }))
  })) || []

  const teamOptions = [];

  for (let i = 0; i < 100; i++) {
    const newObj = {
      title: `Team ${i + 1}`,
      value: i + 1,
    };
    teamOptions.push(newObj);
  }

  const arr1 = categoriesx?.find(i => i?.value === category)
  const arr2 = arr1?.listValue?.find(i => i?.value === storeType)

  // const sourceOpts = [
  //   {
  //     title: "Open Market",
  //     value: "open market"
  //   },
  //   {
  //     title: "Wholesaler near me",
  //     value: "wholesaler near me"
  //   },
  //   {
  //     title: "Direct from the distributor",
  //     value: "direct from the distributor"
  //   },
  //   {
  //     title: "Company supplies me directly in my outlet",
  //     value: "company supplies me directly in my outlet"
  //   },
  //   {
  //     title: "Not applicable",
  //     value: "not applicable"
  //   },
  // ]

  // const products = [
  //   'Raw Foodstuff',
  //   'Packaged Company Products',
  //   'Fruits & Vegetables',
  //   'Meat & Frozen Food'
  // ]

  // const handleStateChange = (e) => {
  //   const stateName = e.target.value;
  //   const isChecked = e.target.checked;
  //   if (isChecked) {
  //     setBuyingPreferences([...buyingPreferences, stateName])
  //   } else {

  //   }
  // };


  return (
    <form onSubmit={createStore}>
      <Box height={"58vh"} overflowY={"scroll"}>
        <CustomInput
          type={"text"}
          required
          placeholder={"Store Name"}
          onChange={e => setStoreName(e.target.value)}
          mb=".8rem"
        />
        <CustomInput
          type={"tel"}
          placeholder={"Outlet Phone"}
          minLength={11}
          maxLength={11}
          onChange={e => setOutletPhone(e.target.value)}
          mb=".8rem"
        />



        <Flex mb=".8rem" gap={".8rem"}>
          <CustomInput
            type={"number"}
            placeholder={"Street Number"}
            onChange={e => setStreetNumber(e.target.value)}
            mb=".8rem"
          />

          <CustomInput
            type={"text"}
            required
            placeholder={"Street name"}
            onChange={e => setStreetName(e.target.value)}
            mb=".8rem"
          />
        </Flex>

        <Flex mb=".8rem" gap={".8rem"}>
        <CustomSelect
            required
            options={[{title: countries?.find(x => x?.code === defaultCountry)?.title, value: countries?.find(x => x?.code === defaultCountry)?.value}, ...countries]}
            // placeholder={"Country"}
            onChange={e => setCountry(e.target.value)}
          />
          <CustomSelect
            required
            options={places2}
            placeholder={"State"}
            onChange={e => setState(e.target.value)}
          />
          
        </Flex>

        <Flex mb=".8rem" gap={".8rem"}>
        <CustomSelect
            required
            options={places2?.filter(i => i.value === state)[0]?.listValue}
            placeholder={"Area"}
            onChange={e => setArea(e.target.value)}
          />
          <CustomInput
            type={"text"}
            required
            placeholder={"Town"}
            onChange={e => setTown(e.target.value)}
          />
         
        </Flex>


        <Flex mb=".8rem" gap={".8rem"}>
          <CustomInput
            type={"text"}
            placeholder={"Contact's First Name"}
            onChange={e => setOwnerFirstName(e.target.value)}
            mb=".8rem"
          />

          <CustomInput
            type={"text"}
            required
            placeholder={"Contact's Last Name"}
            onChange={e => setOwnerLastName(e.target.value)}
            mb=".8rem"
          />
        </Flex>



        <CustomInput
          type={"tel"}
          placeholder={"Contact's Phone Number"}
          minLength={11}
          maxLength={11}
          onChange={e => setPhone(e.target.value)}
          mb=".8rem"
        />
        <CustomInput
          type={"email"}
          placeholder={"Contact's Email Address"}
          onChange={e => setEmail(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required
          options={categoriesx}
          placeholder={"Store category"}
          onChange={e => setCategory(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required
          options={categoriesx?.filter(i => i.value === category)[0]?.listValue}
          placeholder={"Type of store"}
          onChange={e => setStoreType(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required
          options={arr2?.listValue}
          placeholder={"Level of store"}
          onChange={e => setStoreLevel(e.target.value)}
          mb=".8rem"
        />


        {/* <FormControl>
          <FormLabel fontSize={".87rem"}>Brand</FormLabel> */}
        <CustomInput
          type={"text"}
          placeholder={"Brand"}
          onChange={e => setBrand(e.target.value)}
          mb=".8rem"
        />
        {/* </FormControl> */}

        <CustomTextArea
          rows="1"
          mt="0"
          placeholder={"Additional Information"}
          onChange={e => setAdditionalInfo(e.target.value)}
          mb=".8rem"
          label="Additional Information"
        />


        {/* <FormControl mt="1rem">
          <FormLabel fontSize={".87rem"}>Would you buy from us if we brought products to you?</FormLabel>
          <CustomSelect
          required
          options={purchaseOpts}
          placeholder={"Select"}
          onChange={e => setPurchaseWillingness(e.target.value)}
          mb=".8rem"
          />
        </FormControl>
        <FormControl mt="1rem" mb=".8rem">
          <FormLabel fontSize={".87rem"}>Which of these would you buy if we deliver to you? </FormLabel>
          <Flex gap=".5rem" p=".3rem" border=".5px solid lightgray" borderRadius={"8px"} alignItems={"center"} flexWrap={"wrap"}>
            {products?.map((state) => (
              <Flex alignItems={"center"} gap=".2rem" key={state}>
                <input
                  type="checkbox"
                  id={state}
                  value={state}
                  checked={buyingPreferences.includes(state)}
                  onChange={handleStateChange}
                />
                <label style={{whiteSpace: "nowrap"}} htmlFor={state}>{state}</label>
              </Flex>
            ))}
          </Flex>
        </FormControl> */}


        <Flex mb=".8rem" gap=".5rem">
          {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
          <ImageUploader
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingi}
            loading={loadingi}
            max={1}
            src="camera"
          />
        </Flex>
       

        <CustomSelect
          required
          options={clientOptions}
          placeholder={"Client"}
          onChange={e => setClient(e.target.value)}
          mb=".8rem"
        />
        <CustomSelect
          required
          options={teamOptions}
          placeholder={"Team"}
          onChange={e => setTeam(e.target.value)}
        />
      </Box>
      <PrimaryButton mt="1rem" type="submit" text="Add outlet" icon="add" func={() => { }} loading={loading} />
    </form>
  )
}

export default CreateStore;