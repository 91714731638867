import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userActionTypes } from "../../../redux/constants/userActionTypes";
import axiosInstance from "../../../services/api";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import GoBack from "../../../components/goBack";
import CustomInput from "../../../components/customInput/customInput";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const {state} = useLocation()

    const toast = useToast()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const datax = {
        email: email,
        password: password
    }

    const login = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const {data} = await axiosInstance.post("auth/login", datax)
            localStorage.setItem("PRV1TK", data?.data?.token);
            dispatch({type: userActionTypes.SIGNIN_SUCCESS, payload: data?.data?.token})
            window.location.href = '/'
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }


    return (
        <Box>
            <GoBack />
            <Box padding="1rem">
                <Heading mt={6} fontWeight={500} fontSize="1.3rem" color="#030303" fontFamily='Roboto'>Login to your account</Heading>
                <Text mt={2} mb="2rem" color={"#667085"} fontSize={".9rem"}>Enter your details to log in</Text>
                <form onSubmit={login}>
                    <CustomInput 
                    type={"email"}
                    required
                    placeholder={"Email Address"}
                    mb={".8rem"}
                    onChange={e => setEmail(e.target.value)}
                    />
                    <CustomInput
                    type={"password"}
                    required
                    placeholder={"Password"}
                    onChange={e => setPassword(e.target.value)}
                    />
                    <Flex mb="4rem" mt=".5rem" justifyContent={"space-between"} alignItems={"center"}>
                        <Text></Text>
                        <Text onClick={() => navigate("/forgot-password", {state: {userType: state?.userType}})} fontSize={".9rem"} fontWeight={"500"} color="#485467" cursor={"pointer"} transition={"250ms ease"} _hover={{color: "#7F56D9"}}>Forgot Password</Text>
                    </Flex>
                    <PrimaryButton type="submit" text="Sign In" icon="next" loading={loading} />
                    {/* <Text mt="2rem" textAlign={"center"} fontSize={".9rem"} color="#475467">Don't have an account? <span onClick={() => navigate("/signup", {state: {userType: state?.userType}})} style={{fontWeight: "600", color: "#7F56D9", cursor: "pointer", transition: "250ms ease"}}>Sign up here</span></Text> */}
                </form>
            </Box>
        </Box>
    )
}

export default Login;

