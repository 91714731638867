import { Box, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import WheelComponent from 'react-wheel-of-prizes'
// import 'react-wheel-of-prizes/dist/index.css'


const SpinTheWheel = ({data, claimPrize, loading, handleOpen}) => {
  const prizes = data?.gamePrizes?.filter(x => x?.quantityAvailable !== 0);

  const [finished, setFinished] = useState(false);
  const [prize, setPrize] = useState("")


  // Function to calculate weights based on rank
  const calculateWeights = (data) => {
    const maxRank = Math.max(...data.map(item => item.rank));
    return data.map(item => ({
      ...item,
      weight: maxRank - item.rank + 1,
    }));
  };

  // Function to create a weighted array
  const createWeightedArray = (items) => {
    const weightedArray = [];
    items.forEach(item => {
      for (let i = 0; i < item.weight; i++) {
        weightedArray.push(item.name); // Add the item name multiple times
      }
    });
    return weightedArray;
  };

  // Calculate weights and generate weighted array
  const weightedData = calculateWeights(prizes);
  const weightedArray = createWeightedArray(weightedData);

  // Get unique prize names for the visual wheel
  const uniquePrizes = prizes?.map(item => item.name);

  // Handle the spin result
  const [winner, setWinner] = useState(null);

  const handleSpinFinish = (winnerName, visualWinner) => {
    setWinner(winnerName);
    setPrize(winnerName)
    setFinished(true)
  };

  const segColors = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
    '#EC3F3F',
    '#FF9000'
  ]





  return (
    <Box>
        <Box ml="-5.55rem" mt="-5rem">
        <WheelComponent
        segments={uniquePrizes}
        segColors={segColors}
        onFinished={(visualWinner) => {
          // Match the visual winner to the weighted logic
          const weightedWinner = weightedArray.find(
            (item) => item === visualWinner
          );
          
          handleSpinFinish(weightedWinner, visualWinner);
        }}
        primaryColor='black'
        contrastColor='white'
        buttonText='Spin'
        isOnlyOnce={true}
        size={180}
        upDuration={100}
        downDuration={1000}
        fontFamily="Inter"
        />

        </Box>

    {finished === true &&
    <Box mt="-17rem" textAlign={"center"}>
      {/* <Text>You win <strong style={{textTransform: "capitalize"}}>{}</strong>!!</Text> */}
      <PrimaryButton mt="2rem" onClick={() => handleOpen(prize)} text="Continue to claim" loading={loading} />
    </Box>
    }
    </Box>
  )
}

export default SpinTheWheel;