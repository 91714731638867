import { Flex, Heading } from '@chakra-ui/react';
import React from 'react'
import GoBack from './goBack';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const FixedTitleBar = ({text}) => {
  const navigate = useNavigate()
  return (
    <Flex gap="1rem" mb=".5rem" pt="1.3rem" pb="1rem" width={"100%"} alignItems={"center"} zIndex={"6"} bg="#FFF" position={"fixed"}>
        <HiOutlineArrowNarrowLeft onClick={() => navigate(-1)} style={{transform: "scale(1.3)", cursor: "pointer"}} />
        <Heading fontWeight={500} fontSize="1.3rem" color="#475467" fontFamily='Roboto'>{text}</Heading>
    </Flex>
  )
}

export default FixedTitleBar;