import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { userActionTypes } from "../../../../redux/constants/userActionTypes";
import axiosInstance from "../../../../services/api";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import GoBack from "../../../../components/goBack";
import CustomTextArea from "../../../../components/customTextArea/customTextArea";
import { findByTestId } from "@testing-library/react";

const ClockIn = () => {

    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    const {id} = useParams()

    const toast = useToast()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const datax = {
        reason
    }

    const login = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const {data} = await axiosInstance.post("auth/login", datax)
            console.log("test",data)
            localStorage.setItem("PRV1TK", data?.data?.token);
            dispatch({type: userActionTypes.SIGNIN_SUCCESS, payload: data?.data?.token})
            navigate("/")
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }

    const handleClockIn = () => {
        navigate(`/${id}/dashboard`)
        dispatch({type: userActionTypes.SHOW_MENU, payload: true})
    }


    return (
        <Box>
            <GoBack />
            <Box padding="1rem">
                <Heading mt={6} fontWeight={500} fontSize="1.3rem" color="#030303" fontFamily='Roboto'>Clock in via email</Heading>
                <Text mt={2} mb="2rem" color={"#667085"} fontSize={".9rem"}>Enter your details to log in</Text>
                <form onSubmit={login}>
                    <CustomTextArea 
                        type={"text"}
                        placeholder={"Reason for request"}
                        mb={".8rem"}
                        onChange={e => setReason(e.target.value)}
                    />
                    <br /><br /><br /><br /><br />
                    <PrimaryButton onClick={handleClockIn} text="Sign In" icon="next" loading={loading} />
                </form>
            </Box>
        </Box>
    )
}

export default ClockIn;

