import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/customInput/customInput';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../services/api';
import { Box, Flex, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import CustomSelect from '../../../components/customSelect/customSelect';
import statesLgaJson from '../../../utils/stateLGAs';
import ImageUploader from '../../../components/imageUploader/imageUploader';
import CustomTextArea from '../../../components/customTextArea/customTextArea';


const storeLevels = [
  {
    title: "Retailer",
    value: "retailer"
  },
  {
    title: "Semi Wholesaler",
    value: "semi wholesaler"
  },
  {
    title: "Large Wholesaler",
    value: "large wholesaler"
  },
  {
    title: "Distributor",
    value: "distributor"
  }
]

const places = statesLgaJson?.map(i => ({
  title: i?.state,
  value: i?.state,
  listValue: i?.lgas?.map(i => ({
    title: i,
    value: i,
  }))
}))

const EditStore = ({setTriggerClose, storeData, setRefetch}) => {
  console.log(storeData)


  const [address, setAddress] = useState(storeData?.address);
  const [storeName, setStoreName] = useState(storeData?.name);
  const [ownersName, setOwnersName] = useState(storeData?.ownerName);
  const [storeType, setStoreType] = useState(storeData?.type);
  const [storeLevel, setStoreLevel] = useState(storeData?.level);
  const [phone, setPhone] = useState(storeData?.ownerPhoneNumber);
  const [email, setEmail] = useState(storeData?.ownerEmail);
  const [coordinates, setCoordinates] = useState(null);
  const [comment, setComment] = useState(storeData?.comment);
  const [category, setCategory] = useState(storeData?.category);
  const [imageURL, setImageURL] = useState(storeData?.imageURL);
  const [state, setState] = useState(storeData?.state);
  const [area, setArea] = useState(storeData?.area);
  const [town, setTown] = useState(storeData?.town);
  const [country, setCountry] = useState("");
  const [loadingi, setLoadingi] = useState(false);
  const [loadinge, setLoadinge] = useState(false);
  const [loading, setLoading] = useState(true);

  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [loadingx, setLoadingx] = useState(false);

  const [categories, setCategories] = useState([]);

  const toast = useToast();

  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({latitude, longitude})
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  const getCategories = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(`store-categories`)
        setCategories(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getCategories()
}, [])

const categoriesx = categories?.map(i => ({
  title: i?.name,
  value: i?.name,
  listValue: i?.types?.map(v => ({
    title: v?.name,
    value: v?.name,
    listValue: v?.levels?.map(k => ({
      title: k,
      value: k
    }))
  }))
}))

  const getClients = async() => {
    setLoadingx(true)
    try {
        const { data } = await axiosInstance.get('clients')
        setClients(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoadingx(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const editStore = async(e) => {
    e.preventDefault()
    setLoadinge(true)

    const myObj = {}

    const datax = {
      name: storeName,
      address,
      state,
      area,
      town,
      ownerName: ownersName,
      ownerPhoneNumber: phone,
      ownerEmail: email,
      type: storeType,
      level: storeLevel,
      category,
      imageURL,
      comment
    }

    for (const key in datax) {
      const value = datax[key];
      if (value !== "" && value !== undefined && value !== null) {
        myObj[key] = value;
      }
    }

    try {
      const {data} = await axiosInstance.patch(`/stores/${storeData?._id}`, myObj)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      if(error?.response?.data?.message?.length > 3) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
        });
      }
    } finally {
      setLoadinge(false)
    }
  }

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };

  const clientOptions = clients?.map(i => ({
    title: i?.companyName,
    value: i?.id
  }))

  const countries = [
    {
      title: "Nigeria",
      value: "nigeria"
    }
  ]

  const newPlaces = [places?.find(i => i.value.toLowerCase() === state.toLowerCase()), ...places?.filter(i => i.value.toLowerCase() !== state.toLowerCase())]
  const newSubPlaces = newPlaces?.find(i => i.value.toLowerCase() === state?.toLowerCase()).listValue
  const finalNewSubPlaces = [newSubPlaces?.find(i => i.value.toLowerCase() === area?.toLowerCase()), ...newSubPlaces?.filter(i => i.value.toLowerCase() !== area.toLowerCase())]

   const newCategories = loading ? [{
    title: "",
    value: "",
    listValue: [
      {
        title: "",
        value: ""
      }
    ]
   }] : [categoriesx?.find(i => i.value.toLowerCase() === category.toLowerCase()), ...categoriesx?.filter(i => i.value.toLowerCase() !== category.toLowerCase())]

  const newSubCategories = loading ? [{
    title: "",
    value: ""
   }] : newCategories?.find(i => i.value.toLowerCase() === category?.toLowerCase()).listValue

  // const finalNewSubCategories = loading ? [{
  //   title: "",
  //   value: ""
  //  }] :  [newSubCategories?.find(i => i.value.toLowerCase() === storeType?.toLowerCase()), ...newSubCategories?.filter(i => i.value.toLowerCase() !== storeType.toLowerCase())]


  //  const newLevels = [storeLevels?.find(i => i.value.toLowerCase() === storeLevel.toLowerCase()), ...newSubCategories?.filter(i => i.value.toLowerCase() !== storeLevel.toLowerCase())]


  const arr1 = categoriesx?.find(i => i?.value === category)
  const arr2 = arr1?.listValue?.find(i => i?.value === storeType)


  // console.log(storeData, newSubCategories)


  return (
    <form onSubmit={editStore}>
        <Box height={"58vh"} overflowY={"scroll"}>
        <CustomInput
        type={"text"}
        placeholder={"Store Name"}
        onChange={e => setStoreName(e.target.value)}
        value={storeName}
        label="Store Name"
        mb=".4rem"
        />
        <CustomInput
        type={"text"}
        placeholder={"Address"}
        onChange={e => setAddress(e.target.value)}
        value={address}
        label="Address"
        mb=".4rem"
        />

        <Flex mb=".4rem" gap={".8rem"} width={"100%"}>
          <CustomSelect
          options={places}
          placeholder={state}
          onChange={e => setState(e.target.value)}
          label="State"
          />
          <CustomSelect
          options={places?.filter(i => i.value === state)[0]?.listValue}
          placeholder={state === storeData?.state.toLowerCase() ? area : "Area"}
          onChange={e => setArea(e.target.value)}
          label="Area"
          />
        </Flex>
        <Flex mb=".4rem" gap={".8rem"}>
           <CustomInput
            type={"text"}
            placeholder={"Town"}
            onChange={e => setTown(e.target.value)}
            value={town}
            label="Town"
           />
            <CustomSelect
            required
            options={countries}
            // placeholder={"Country"}
            onChange={e => setCountry(e.target.value)}
            label="Country"
            />
        </Flex>
        
         <CustomInput
        type={"text"}
        placeholder={"Contact's Name"}
        onChange={e => setOwnersName(e.target.value)}
        mb=".4rem"
        value={ownersName}
        label="Contact's Name"
        />
        <CustomInput
        type={"tel"}
        placeholder={"Contact's Phone Number"}
        minLength={11}
        maxLength={11}
        onChange={e => setPhone(e.target.value)}
        mb=".4rem"
        value={phone}
        label="Contact's Phone"
        />

        <CustomInput
        type={"text"}
        placeholder={"Contact's Email Address"}
        onChange={e => setEmail(e.target.value)}
        mb=".4rem"
        value={email}
        label="Contact's Email"
        />
        <CustomSelect
        options={categoriesx}
        placeholder={category}
        onChange={e => setCategory(e.target.value)}
        _placeholder={{textTransform: "capitalize !important"}}
        mb=".4rem"
        label="Category"
        />
        <CustomSelect
        options={categoriesx?.filter(i => i.value === category)[0]?.listValue}
        placeholder={category === storeData?.category.toLowerCase() ? storeType : "Type of store"}
        onChange={e => setStoreType(e.target.value)}
        _placeholder={{textTransform: "capitalize !important"}}
        mb=".4rem"
        label="Type"
        />
        <CustomSelect
        options={arr2?.listValue}
        placeholder={storeType === storeData?.type.toLowerCase() ? storeLevel : "Level of store"}
        onChange={e => setStoreLevel(e.target.value)}
        _placeholder={{textTransform: "capitalize !important"}}
        mb="1rem"
        label="Level"
        />
        <Flex mb="1rem" gap=".5rem">
            {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingi}
            loading={loadingi}
            max={1}
            src="camera"
            />
        </Flex>
        <CustomTextArea
        rows="1"
        placeholder={""}
        onChange={e => setComment(e.target.value)}
        mb=".4rem"
        value={comment}
        label="Comment"
        />
        
        </Box>
        <PrimaryButton mt="1rem" type="submit" text="Update store" func={() => {}} loading={loadinge} />
    </form>
  )
}

export default EditStore;