import CustomTable from "../../../components/customTable/customTable"

const Prizes = ({initiativeData}) => {
    // console.log(initiativeData)
    const tHeaders = ['Name', '',  '', 'Qty Available']

  const tData = initiativeData?.gamePrizes?.map(i => ({
    one: i?.name,
    two: '',
    three: '',
    four: i?.quantityAvailable,
    id: i?._id,
  }))
  return (
    <div>
        <CustomTable
        headers={tHeaders}
        data={tData}
        />
    </div>
  )
}

export default Prizes