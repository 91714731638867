import { Box, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { VscCircleFilled } from 'react-icons/vsc';
import moment from 'moment';
import axiosInstance from '../services/api';
import Navbar from '../components/navbar/navbar';
import Overview from '../components/overView/overView';
import CustomDrawer from '../components/customDrawer/customDrawer';
import SpinLoader from '../components/spinLoader/spinLoader';
import EmptyState from '../components/emptyState/emptyState';
import OnboardedStores from './stores/onboardedStores/onboardedStores';
import FixedTitleBar from '../components/fixedTitleBar';
import CustomInput from '../components/customInput/customInput';
import { formatDate } from '../utils/helper';
import { useSelector } from 'react-redux';
import CustomTabs from '../components/customTabs/customTabs';
import Surveys from './surveys/surveys';

const Promoter = () => {
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAllDates, setShowAllDates] = useState(false);

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  // Get the previous day
  const previousDay = new Date(selectedDate);
  previousDay.setDate(selectedDate.getDate() - 1);
  const previousDayFormatted = formatDate(previousDay);

  // Get the next day
  const nextDay = new Date(selectedDate);
  nextDay.setDate(selectedDate.getDate() + 1);
  const nextDayFormatted = formatDate(nextDay);

  const [loadingx, setLoadingx] = useState(true)
  const [promoter, setPromoter] = useState(null)

  const getPromoter = async() => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(`employees/${currentUser?._id}/team-members?_id=${id}`)
      setPromoter(data?.data[0])
    } catch(error) {
        console.log(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getPromoter()
  }, [])

const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    setShowAllDates(false)
  };


  const tabData = [
    {
        title: "Onboarded Outlets",
        render: <OnboardedStores src="supervisor" date1={previousDayFormatted} date2={nextDayFormatted} id={id} showAllDates={showAllDates} />
    },
    {
        title: "Surveys",
        render: <Surveys userId={id} />
    },
  ]


  
  
  return (
    <>
    {loading ?
    <SpinLoader />
    :
    <>
    {/* <Navbar title={"Promoter Activities"} showIcon={false} /> */}
    <Box px=".8rem">
        <FixedTitleBar text="Promoter Activities" />
    </Box>
    
    <Box overflowY={"scroll"}>
        
        <Box pt={"3.5rem"} px={"1rem"} pb="7rem">

            <Flex alignItems={"center"} mt="1rem" mb="2rem" gap=".4rem">
                <Box 
                height={"40px"}
                width={"40px"}
                borderRadius={"4px"}
                bg="lightgray"
                backgroundImage={promoter?.profilePicture}
                backgroundSize={"cover"}
                />
                <Box>
                    <Text>{promoter?.firstName + " " + promoter?.lastName}</Text>
                    <Text fontSize={".8rem"} opacity={".6"}>{promoter?.email}</Text>
                </Box>
            </Flex>

            <Flex mb="1.2rem" alignItems={"center"} justifyContent={"flex-end"}>
            <Box gap=".5rem" alignItems={"center"}>
            {showAllDates === false && <Text onClick={() => setShowAllDates(true)} transform={"translateY(-.3rem)"} textAlign={"center"} fontSize={".75rem"} cursor={"pointer"} fontWeight={"500"} color="#7F56D9">Show all dates</Text>}
            <CustomInput
                type={"date"}
                required
                onChange={handleDateChange}
                value={showAllDates === true ? '' : formatDate(selectedDate)}
                mt="0"
                py="1.19rem"
                width="150px"
                cursor="pointer"
                />
            </Box>
            </Flex>

            <CustomTabs data={tabData} />
        </Box>
    </Box>
    </>}
    </>
  )
}

export default Promoter;