import { useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/customTable";
import axiosInstance from "../../../services/api";
import { useParams } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";
import { buildResourceURL, buildResourceURL2 } from "../../../utils/helper";
import CustomInput from "../../../components/customInput/customInput";
import ServerExport from "../../../components/serverExport/serverExport";

const Winners = ({ initiativeData }) => {
  const [loading, setLoading] = useState(true);
  const [winners, setWinners] = useState([]);
  const [total, setTotal] = useState('')
  const { id } = useParams();

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [page, setPage] = useState(1)
  const limit = 100

  const queryParams = [
    {
      key: "initiativeStore",
      value: id,
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "limit",
      value: limit,
    },
    {
      key: "createdAt[gte]",
      value: startDate,
    },
    {
      key: "createdAt[lte]",
      value: endDate,
    },
    // {
    //   key: "search",
    //   value: searchVal,
    // },
  ];
  const queryParamsx = [
    {
      key: "initiativeStore",
      value: id,
    },
    {
      key: "createdAt[gte]",
      value: startDate,
    },
    {
      key: "createdAt[lte]",
      value: endDate,
    },
    // {
    //   key: "search",
    //   value: searchVal,
    // },
  ];

  const getWinners = async () => {
    try {
      const { data } = await axiosInstance.get(buildResourceURL('game-winners', queryParams));
      setTotal(data?.totalCount)
      setWinners(data?.data);
      console.log(data?.data)
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWinners();
  }, [page, startDate, endDate]);

  const tHeaders = ["Date", "Name", "Phone", "Game", "Prize"];

  const tData = winners?.map((i) => ({
    one: i?.createdAt?.slice(0,10),
    two: i?.winnerDetails?.name,
    three: i?.winnerDetails?.phoneNumber,
    four: i?.gameTitle,
    five: i?.prizeWon,
    id: i?._id,
  }));
  return (
    <div>
        <Flex alignItems={"center"} mb="1rem" justifyContent={"space-between"}>
        <Text fontSize={".85rem"} fontFamily={"500"}><strong>{total}</strong> winners found</Text>
        <ServerExport
            entity={"game-winners"}
            queries={buildResourceURL2("", queryParamsx)}
          />
        </Flex>
        
        <Text textAlign={"right"} fontSize={".87rem"} fontWeight={500}>Select Date Period</Text>
        <Flex gap="1rem" alignItems={"center"} justifyContent={"flex-end"} mb="1rem">
          <Flex alignItems={'center'} gap=".5rem">
                      <CustomInput
                      width="150px"
                      type={"date"}
                      mt="0 !important"
                      py=".5rem !important"
                      required
                      placeholder={"Choose Date"}
                      onChange={e => setStartDate(e.target.value)}
                      />
                      -
                      <CustomInput
                      width="150px"
                      type={"date"}
                      mt="0 !important"
                      py=".5rem !important"
                      required
                      placeholder={"Choose Date"}
                      onChange={e => setEndDate(e.target.value)}
                      />
                    </Flex>
          
        
        </Flex>
      <CustomTable headers={tHeaders} data={tData} />
    </div>
  );
};

export default Winners;
