import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react'

const Mechanics = ({data}) => {
  return (
    <Box>
        <Flex alignItems={"center"} gap=".5rem">
            <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>{data?.initiative?.name}</Heading>
            <Text fontSize={"12px"} fontWeight={"500"} textTransform={"capitalize"} borderRadius={"20px"} padding={".2rem 1rem"} bg={data?.initiative?.status === "pending" ? "#FFE3B0" : "#E5FFE4"} color={data?.initiative?.status === "pending" ? "#EC913C" : "#44A234"}>{data?.initiative?.status}</Text>
        </Flex>
        <Text mt={3} mb="1.5rem" color={"#667085"} fontSize={".9rem"}>{data?.initiative?.description}</Text>
        <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Brands</Heading>
        <Flex mt=".7rem" gap="1rem" overflowX={"scroll"}sx={
            { 
            '::-webkit-scrollbar':{
                display:'none'
            }
            }
        }>
            {data?.initiative?.brands?.map(({_id: id, name, sku, image}) => (
                <Box key={id}>
                    <Box bg="lightgray" backgroundImage={image} backgroundSize={"cover"} height={"66px"} width={"66px"} borderRadius={"4px"} />
                    <Text whiteSpace={"nowrap"} mt={2} color={"#667085"} fontWeight={"500"} fontSize={".8rem"}>{name}</Text>
                    <Text mt=".2rem" color={"#667085"} fontSize={".8rem"}>{sku}</Text>
                </Box>
            ))}
        </Flex>
        <Heading mt={"1.5rem"} fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>What to do</Heading>
        <Text mt={2} color={"#667085"} fontSize={".9rem"}>{data?.initiative?.instructions}</Text>

    </Box>
  )
}

export default Mechanics;