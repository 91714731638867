import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/navbar'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import Overview from '../../components/overView/overView'
import axiosInstance from '../../services/api'
import SpinLoader from '../../components/spinLoader/spinLoader'
import { useDispatch } from 'react-redux'
import { userActionTypes } from '../../redux/constants/userActionTypes'
import Tasks from './tasks/tasks'
import CustomSelect from '../../components/customSelect/customSelect'





const StoreDashboard = ({setInitiative}) => {
  const [loadingx, setLoadingx] = useState(false);
  const [loadingy, setLoadingy] = useState(false);
  const [details, setDetails] = useState('')
  const [analyticsData, setAnalyticsData] = useState(null);



const [brand, setBrand] = useState(details?.initiative?.brands[0]?._id);

  const {id} = useParams()
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClockOut = () => {
    navigate('/')
    dispatch({type: userActionTypes.SHOW_MENU, payload: false})
}

  
//   const [sku, setSku] = useState("");

useEffect(() => {
    if(details === null) {
        handleClockOut()
    }
}, [details])

  const getInitiativeStoreDetails = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.get(`/initiative-stores/${id}`)
            setDetails(data?.data)
            setInitiative(data?.data)
            setBrand(data?.data?.initiative?.brands[0]?._id)
            dispatch({type: userActionTypes.SAVE_INITIATIVE_ID, payload: data?.data?.initiative?._id})
        } catch(error) {
            console.error(error?.response?.data?.message)
            setDetails(null)
        } finally {
            setLoadingx(false)
        }
    }

    useEffect(() => {
        getInitiativeStoreDetails()
    }, [])

    

    const options1 = details?.initiative?.brands?.map(i => ({
        title: `${i?.name}-${i?.sku}`,
        value: i?._id
      }))

    // const options1 = details?.initiative?.brands?.map(i => ({
    //     title: i?.name,
    //     value: i?.name
    // }))
    
    //   const options2 = details?.initiative?.brands?.map(i => ({
    //     title: i?.sku,
    //     value: i?.sku
    //   }))

  const getSalesAnalytics = async() => {
        setLoadingy(true)
        try {
            const { data } = await axiosInstance.get(`/sales/analytics/initiative-store?initiativeStoreId=${id}&brandName=${details?.initiative?.brands?.filter(i => i._id === brand)[0]?.name}&sku=${details?.initiative?.brands?.filter(i => i._id === brand)[0]?.sku}&target=${details?.initiative?.brands?.filter(i => i._id === brand)[0]?.target}&durationInDays=${details?.initiative?.brands?.filter(i => i._id === brand)[0]?.durationInDays}`)
            // const { data } = await axiosInstance.get(`/sales/analytics/initiative?initiativeId=${id}&brandName=${JSON.parse(brand)?.name ?? details?.initiative?.brands[0]?.name}&sku=${JSON.parse(brand)?.sku ?? details?.initiative?.brands[0]?.sku}&target=${JSON.parse(brand)?.target ?? details?.initiative?.brands[0]?.name}`)
            setAnalyticsData(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoadingy(false)
        }
    }

    useEffect(() => {
        getSalesAnalytics()
    }, [brand])


    const data = [
        {
            title: "Sales achieved",
            value: analyticsData?.totalUnitsSold || 0,
        },
        {
            title: "Sales target",
            value: analyticsData?.target || 0,
            // value: details?.initiative?.brands?.filter(i => i?._id === brand)[0]?.target,
        },
        {
            title: 'Target met',
            value: `${analyticsData?.targetMetPercentage || 0}%`,
        },
        {
            title: "Time gone",
            value: `${analyticsData?.daysGone || 0}/${analyticsData?.durationInDays || 0} (${analyticsData?.timeGonePercentage || 0}%)`,
            // value: details?.initiative?.brands?.filter(i => i?._id === brand)[0]?.durationInDays,
        }
    ]

  return (
    <>
    {loadingx ?
    <SpinLoader />
    :
    <>
        <Box position={"relative"}>
    <Navbar title={"Outlet overview"} showIcon={true} />
    <Box height={"100vh"} overflowY={"hidden"} pb="6rem">
        <Box position={"fixed"} maxWidth={"480px"} p="3rem 1rem 1rem 1rem" bg="#FFF">
            <Flex mt="1.3rem" mb="1.1rem" onClick={() => navigate(`initiative`, {state: {title: details?.initiative?.name, index: 0}})} justifyContent={"space-between"} cursor={"pointer"} alignItems={"center"} p=".8rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                <Flex alignItems={"center"} gap=".8rem">
                    <Box height={"50px"} width={"60px"} borderRadius={"4px"} bg="lightgrey" backgroundImage={details?.initiative?.imageURL} backgroundSize={"cover"} />
                    <Box>
                        <Text mb=".3rem" fontSize={"1rem"} color="#475467" fontWeight={"500"}>{details?.initiative?.name}</Text>
                        <Text fontFamily={"Helvetica Neue"} fontSize={".85rem"} color="#667085" fontWeight={"500"} textTransform={"capitalize"}>{details?.store?.name}</Text>
                    </Box>
                </Flex>
                <HiOutlineArrowNarrowRight color='#667085' style={{transform: "scale(1.3)"}} />
            </Flex>
            <Flex gap={".7rem"} alignItems={"center"} mb=".8rem">
                <CustomSelect
                  selection={brand}
                  setSelection={setBrand}
                  options={options1}
                  height="37px"
                  fontSize=".8rem"
                  width="150px"
                />
            </Flex>
            <Overview data={data} loading={loadingy} />
        </Box>
        <Flex pt="25.5rem" px="1rem" mt="1.3rem" mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
            <Text color="#667085" fontWeight={"600"} letterSpacing={"-1px"} fontSize={"1.25rem"}>Your tasks</Text>
            <Text cursor={"pointer"} onClick={() => navigate(`/${id}/tasks`)} color="#5B68DD" fontWeight={"500"} letterSpacing={"0px"} fontSize={".9rem"}>See all</Text>
        </Flex>
        <Box height={"400px"} px="1rem" overflowY={"scroll"}>
            <Tasks source={'dashboard'} />
        </Box>
    </Box>
    </Box>
    </>
    }
    </>
  )
}

export default StoreDashboard