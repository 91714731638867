import { Box, Flex, IconButton, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { AddIcon } from '@chakra-ui/icons'
import { useSelector } from 'react-redux'
import axiosInstance from '../../services/api'
import CustomDrawer from '../../components/customDrawer/customDrawer'
import EmptyState from '../../components/emptyState/emptyState'
import SkeletonLoader from '../../components/skeletonLoader/skeletonLoader'
import PrimaryButton from '../../components/primaryButton/primarybutton'
import CreateSurvey from './createSurveys'
import ViewSurvey from './viewSurvey'
import Navbar from '../../components/navbar/navbar'
import { useLocation } from 'react-router-dom'
import { truncateText } from '../../utils/helper'
import SurveyResponses from './surveyResponses'



const Surveys = ({src, id, date1, date2, initiativeId, userId}) => {
  const [surveys, setSurveys] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingc, setLoadingc] = useState(false)
  const [view, setView] = useState(1)
  const [sid, setSid] = useState('')
  const [questions, setQuestions] = useState([])

  const [refetch, setRefetch] = useState("")

  const [triggerClose, setTriggerClose] = useState("")

  const state = useLocation()
  const idx = state?.pathname?.split('/')[1]

  

  const getSurveys = async() => {
    try {
      const {data} = await axiosInstance.get(src === 'in' ? `surveys/assigned?isActive=true&initiative=${initiativeId}` : 'surveys/assigned?isActive=true')
      setSurveys(data?.data)
    } catch(error) {
      // toast({
      //   title: "An Error Occurred",
      //   description: error?.response?.data?.message,
      //   status: "error",
      // });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSurveys()
  }, [loadingc, refetch, date1, triggerClose])

  const handleViewResponses = (id, q) => {
    setSid(id)
    setQuestions(q)
    setView(2)
  }

  return (
    <>
    
    {view === 1 ?
    <>
    {src === "in" &&
    <>
    <Navbar title={"Surveys"} showIcon={false} />
    <Text pt="3.5rem" px="1rem" color={"#667085"} fontSize={".9rem"}>Create campaign surveys</Text>
    </>}
    
    <Box height={"81dvh"} overflowY={"scroll"}
    p={src === "in" ? "3.5rem 1rem" : ""}
    sx={
        { 
       '::-webkit-scrollbar':{
              display:'none'
          }
       }
     }>
        
        {loading ?
        <SkeletonLoader />
        :
        surveys?.length < 1 && src !== "supervisor" ?
        <Flex justifyContent={"center"}>
         <Box>
            <EmptyState text={"No surveys added"} />
         </Box>
        </Flex>
        :
        surveys?.map(((i, idx) => {
            return (
                <Flex key={idx} onClick={() => handleViewResponses(i?._id, i?.questions)} cursor={"pointer"} mb=".7rem" alignItems={"center"} gap=".5rem" p=".7rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                  <Box height={"60px"} width={"70px"} borderRadius={"8px"} bg="lightgray" backgroundImage={i?.imageURL} backgroundSize={"cover"} />
                  <Box>
                      <Text mb=".1rem" fontSize={"1rem"} color="#475467" fontWeight={"600"} textTransform={"capitalize"}>{i?.name}</Text>
                      <Text fontSize={".85rem"} color="#667085" fontWeight={"500"} textTransform={"capitalize"}>{truncateText(i?.description, 25)}</Text>
                  </Box>
                </Flex>
            )
        }))
        }
    </Box>
    </>
    :
    <SurveyResponses src={initiativeId ? "in" : "out"} sid={sid} setView={setView} questions={questions} userId={userId}  />
    }
    </>
  )
}

export default Surveys















