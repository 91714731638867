import { useParams } from "react-router-dom";
import Navbar from "../../../../components/navbar/navbar";
import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import TrueOrFalse from "../trueOfFalse/trueOrFalse";
import { useState } from "react";
import axiosInstance from "../../../../services/api";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import SpinTheWheel from "../spinTheWheel/spinTheWheel";
import ImageUploader from "../../../../components/imageUploader/imageUploader";

const GameView = ({
  game,
  data,
  player,
  prizeImage,
  comment,
  gameTitle,
  setGameView,
  setImage,
  formattedDate,
}) => {
  const [loading, setLoading] = useState();
  const { id } = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageURL2, setImageURL2] = useState([]);

  const [prize, setPrize] = useState("");

  const [loadingu2, setLoadingu2] = useState(false);

  const handleImagesUpload2 = (images) => {
    setImageURL2(images);
  };

  const claimPrize = async (game, prize) => {
    const prevRecord = JSON.parse(localStorage.getItem("winsTracker"));
    setLoading(true);
    const datax = comment
      ? {
          gameName: game,
          gameTitle: gameTitle || game,
          prizeWon: prize,
          winnerDetails: player,
          prizeImageURL: imageURL2[0],
          comment: comment || "Not provided",
        }
      : {
          gameName: game,
          gameTitle: gameTitle || game,
          prizeWon: prize,
          winnerDetails: player,
          prizeImageURL: imageURL2[0],
          comment: comment || "Not provided",
        };
    try {
      const { data } = await axiosInstance.post(
        `/initiative-stores/${id}/game-winners`,
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });

      const newRecord = {
        phoneNumber: datax?.winnerDetails?.phoneNumber,
        date: formattedDate,
      };
      localStorage.setItem(
        "winsTracker",
        JSON.stringify([...prevRecord, newRecord])
      );

      setImage([]);
      setGameView(false);
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (prize) => {
    setPrize(prize);
    onOpen();
  };

  return (
    <>
      <Box>
        <Text
          color="#475467"
          textTransform={"capitalize"}
          fontWeight={"600"}
          letterSpacing={"-1px"}
          fontSize={"1.25rem"}
        >
          {game}
        </Text>
        {game === "true or false" && (
          <TrueOrFalse data={data} loading={loading} claimPrize={claimPrize} />
        )}
        {game === "spin the wheel" && (
          <SpinTheWheel
            data={data}
            loading={loading}
            claimPrize={claimPrize}
            handleOpen={handleOpen}
          />
        )}
      </Box>

      <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay backdropFilter={"blur(10px)"} bg="blackAlpha.600" />
        <ModalContent mx=".5rem">
          <ModalHeader
            fontSize={"1rem"}
            textTransform={"capitalize"}
          ></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              mb=".3rem"
              textAlign={"center"}
              fontSize={"1.2rem"}
              fontWeight={"700"}
            >
              You have won a prize!!
            </Text>
            <Image src={prizeImage} width={"auto"} mx="auto" my="1rem" />
            <Text
              mb=".3rem"
              textAlign={"center"}
              fontSize={"1.2rem"}
              fontWeight={"700"}
            >
              Congratulations 🎉
            </Text>
            <Text
              mb="1rem"
              textAlign={"center"}
              fontSize={".9rem"}
              color="#475467"
              fontWeight={"500"}
            >
              You have successfully won a prize, provide the image below to
              successfully claim your prize. You will be given at point of play
              or be reached out to by a representative.
            </Text>

            <Text fontSize={".7rem"} transform="translateY(7px)">
              Prize Image
            </Text>
            <Flex gap=".5rem" mt=".7rem" mb="2rem" alignItems={"center"}>
              {imageURL2?.map((imageUrl, index) => (
                <Box
                  backgroundImage={imageUrl}
                  backgroundSize={"contain"}
                  height={"70px"}
                  width={"100px"}
                  borderRadius={"4px"}
                  backgroundColor={"lightgray"}
                />
              ))}
              <ImageUploader
                onImagesUpload={handleImagesUpload2}
                setLoading={setLoadingu2}
                loading={loadingu2}
                max={1}
                src={"camera"}
              />
            </Flex>
            <PrimaryButton
              onClick={() => claimPrize(game, prize)}
              loading={loading}
              mb="1rem"
              py="1.35rem"
              text="Claim Prize"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GameView;
