import React, { useState } from 'react'
import GoBack from '../../components/goBack';
import { Avatar, Box, Divider, Flex, Input, Text, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { AiOutlineLogout, AiOutlineVerified } from 'react-icons/ai';
import ChangePassword from '../settings/components/changePassword';
import { MdEdit, MdEditSquare, MdVerified } from 'react-icons/md';
import axiosInstance from '../../services/api';
import axios from 'axios';

const Profile = () => {
  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const logout = () => {
    localStorage.removeItem("PRV1TK");
    window.location.href = '/'
  }

  const [loading, setLoading] = useState(false)
  const [dp, setDp] = useState(currentUser?.profilePicture)
  const toast = useToast()

  const updateProfile = async(file) => {
    
    const datax = {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      email: currentUser?.email,
      phoneNumber: currentUser?.phoneNumber,
      role: currentUser?.role,
      profilePicture: file,
      address: currentUser?.address || "temp"
  }
    try {
      const res = await axiosInstance.patch(
        `employees/${currentUser?._id}`,
        datax
      );
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top",
      });
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
  }
}

  const handleUpload = async(file) => {
    setLoading(true)
    const formData = new FormData();
    formData.append('upload_preset', 'jmrkiyu0');
    formData.append('file', file);

    try {
        const response = await axios.post(
        `https://api.cloudinary.com/v1_1/declutters/image/upload`,
        formData
        );
        if (response.data && response.data.secure_url) {
        setDp(response.data.secure_url);
        }
        updateProfile(response.data.secure_url)
    } catch (error) {
        console.error('Error uploading image: ', error);
    } finally {
        setLoading(false)
    }
  }

  return (
    <Box p="1rem">
     <GoBack />
     <Flex justifyContent={"center"} mt="2rem">
        <Box textAlign={"center"}>
            <Box cursor={"pointer"} position={"relative"} width={"100px"} mx="auto">
              <Avatar cursor={"pointer"} transform={"scale(1.4)"} size={"md"} name={currentUser?.firstName + " " + currentUser?.lastName} src={dp} />
              <MdEditSquare cursor={"pointer"} color="#000" style={{position: "absolute", zIndex: "2", bottom: "0", right: "18%", transform: "scale(1.5)"}} />
              <Input type='file' onChange={e => handleUpload(e.target.files[0])} position={"absolute"} left={"0"} bottom={"-20%"} opacity={"0"} cursor={"pointer"} zIndex={3} />
            </Box>
            <Text mt=".5rem" fontWeight={"500"} textTransform={"capitalize"}>{currentUser?.firstName + " " + currentUser?.lastName}</Text>
            <Text fontSize={".85rem"}>{currentUser?.email}</Text>
            <Text fontSize={".78rem"}>{currentUser?.role} {currentUser?.isApproved && <AiOutlineVerified style={{display: "inline", transform: "translateY(1.5px)"}} />}</Text>
        </Box>
     </Flex>
     <br />
     <Divider />
     <ChangePassword />
     <Divider />

     <Box mt="3rem" onClick={logout} cursor={"pointer"}>
        <Divider />
        <Text my="1rem" fontSize={".85rem"} fontWeight={"500"}><AiOutlineLogout style={{display: "inline", transform: "translateY(1.5px) rotate(180deg)"}} /> Log out</Text>
        <Divider />
     </Box>

    </Box>
  )
}

export default Profile;