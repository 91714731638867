import { Box, Flex, IconButton, Text, filter, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { HiOutlineArrowNarrowLeft, HiOutlineLocationMarker } from 'react-icons/hi'
import { AddIcon } from '@chakra-ui/icons'
import { useSelector } from 'react-redux'
import axiosInstance from '../../services/api'
import CustomDrawer from '../../components/customDrawer/customDrawer'
import EmptyState from '../../components/emptyState/emptyState'
import SkeletonLoader from '../../components/skeletonLoader/skeletonLoader'
import PrimaryButton from '../../components/primaryButton/primarybutton'
import CreateSurvey from './createSurveys'
import ViewSurvey from './viewSurvey'
import Navbar from '../../components/navbar/navbar'
import { useLocation } from 'react-router-dom'
import { formatDate, rerunOffline, truncateText } from '../../utils/helper'
import ViewResponse from './viewResponse/viewResponse'
import GoBack from '../../components/goBack'
import CreateResponse from './createResponse/createResponse'
import CustomInput from '../../components/customInput/customInput'
import moment from 'moment'



const SurveyResponses = ({src, sid, setView, questions, userId}) => {
  const [surveys, setSurveys] = useState([])
  const [total, setTotal] = useState("")
  const [loading, setLoading] = useState(true)
  const [loadingc, setLoadingc] = useState(false)

  const [refetch, setRefetch] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAllDates, setShowAllDates] = useState(false);

  const prevData = JSON.parse(localStorage.getItem('survey'))

  useEffect(() => {
    if(prevData?.length > 0) {
      rerunOffline('survey')
    }
  }, [])

  // Get the previous day
  const previousDay = new Date(selectedDate);
  previousDay.setDate(selectedDate.getDate() - 1);
  const previousDayFormatted = formatDate(previousDay);

  // Get the previous day
  const currentDay = new Date(selectedDate);
  currentDay.setDate(selectedDate.getDate());
  const currentDayFormatted = formatDate(currentDay);

  // Get the next day
  const nextDay = new Date(selectedDate);
  nextDay.setDate(selectedDate.getDate() + 1);
  const nextDayFormatted = formatDate(nextDay);

  const [triggerClose, setTriggerClose] = useState("")

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const state = useLocation()
  const idx = state?.pathname?.split('/')[1]

  const getSurveys = async() => {
    setLoading(true)
    try {
      const {data} = await axiosInstance.get(showAllDates === true ? `survey-responses?survey=${sid}&user=${currentUser?.role === "Supervisor" ? userId : currentUser?._id}` : `survey-responses?survey=${sid}&user=${currentUser?.role === "Supervisor" ? userId : currentUser?._id}&createdAt[gte]=${currentDayFormatted}&createdAt[lt]=${nextDayFormatted}`)
      setSurveys(data?.data)
      setTotal(data?.totalCount)
    } catch(error) {
      // toast({
      //   title: "An Error Occurred",
      //   description: error?.response?.data?.message,
      //   status: "error",
      // });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSurveys()
  }, [loadingc, refetch, triggerClose, selectedDate, showAllDates])

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
    setShowAllDates(false)
  };

  return (
    <>
    {src === "in" &&
    <>
    <Navbar title={"Surveys"} showIcon={false} />
    <Text pt="3.5rem" px="1rem" color={"#667085"} fontSize={".9rem"}>Record survey responses</Text>
    </>}

    {/* {src === "out" && */}
      <Box p={src === "in" ? "0 1rem" : ""}>
      <HiOutlineArrowNarrowLeft onClick={() => setView(1)} style={{transform: "scale(1.3)", marginBottom: ".8rem", cursor: "pointer", opacity: ".8", marginTop: src === "in" ? "1.5rem" : ""}} />
      <Flex mb="1.2rem" alignItems={"center"} justifyContent={"space-between"}>

        <Flex gap=".4rem" alignItems={"center"}>
          <Text fontWeight={"500"} color={"#333"} opacity={".8"} fontSize={"1.05rem"}>Responses <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{total}</span></Text>
        </Flex>

        <Box gap=".5rem" alignItems={"center"}>
          {showAllDates === false && <Text onClick={() => setShowAllDates(true)} transform={"translateY(-.3rem)"} textAlign={"center"} fontSize={".75rem"} cursor={"pointer"} fontWeight={"500"} color="#7F56D9">Show all dates</Text>}
          <CustomInput
            type={"date"}
            required
            onChange={handleDateChange}
            value={showAllDates === true ? '' : formatDate(selectedDate)}
            mt="0"
            py="1.19rem"
            width="150px"
            cursor="pointer"
            />
        </Box>
      </Flex>
      </Box>
    {/* } */}

    
    
    <Box height={"81dvh"} overflowY={"scroll"}
    p={src === "in" ? "1.5rem 1rem" : ""}
    sx={
        { 
       '::-webkit-scrollbar':{
              display:'none'
          }
       }
     }>
        
        {loading ?
        <SkeletonLoader />
        :
        surveys?.length < 1 && currentUser?.role !== "supervisor" ?
        <Flex justifyContent={"center"} alignItems={"center"} direction={"column"}>
         <Box>
            <EmptyState text={"No surveys responses recorded for this day yet"} />
         </Box>
         <CustomDrawer 
              position={"bottom"}
              title={"Add new response"}
              triggerClose={triggerClose}
              toggleElement={
                <PrimaryButton mt="1rem" width="" text="Create new" icon="add" func={() => {}} loading={loading} />
              }
              content={
                <CreateResponse questions={questions} setTriggerClose={setTriggerClose} sid={sid} />
              }
            />
        </Flex>
        :
        surveys?.map(((i) => {
            return (
              <CustomDrawer  
                key={i?.name}
                position={"bottom"}
                title={"Survey details"}
                triggerClose={triggerClose}
                toggleElement={
                  <Flex cursor={"pointer"} mb=".7rem" alignItems={"center"} gap=".5rem" p=".4rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                    {i?.entries?.filter(i => i?.answer?.includes('cloudinary')?.length > 0) && <Box height={"50px"} width={"55px"} borderRadius={"8px"} bg="lightgray" backgroundImage={i?.entries?.find(i => i?.answer?.includes('cloudinary'))?.answer} backgroundSize={"cover"} />}
                    <Box>
                        <Text mb=".1rem" fontSize={".9rem"} color="#475467" fontWeight={"600"} textTransform={"capitalize"}>{truncateText(i?.entries[0]?.answer, 25)}</Text>
                        <Text fontSize={".75rem"} color="#667085" fontWeight={"500"} textTransform={"capitalize"}>{truncateText(i?.entries[1]?.answer, 25)}</Text>
                        <Text fontSize={".75rem"} color="#667085" fontWeight={"500"} textTransform={"capitalize"}>{truncateText(i?.entries[2]?.answer, 25)}</Text>
                    </Box>
                  </Flex>
                }
                content={
                  <Box height={"65vh"} overflowY={"scroll"}>
                    <ViewResponse data={i} />
                  </Box>
                }
              />
            )
        }))
        }

        {surveys?.length > 0 && currentUser?.role !== "supervisor" &&
        <CustomDrawer 
          position={"bottom"}
          title={"Add new response"}
          triggerClose={triggerClose}
          toggleElement={
            <IconButton
            position={"absolute"} 
            bottom={"13%"}
            right={"5%"}
            zIndex={"5"}
            bg="#000000" 
            boxShadow="0px 4px 24px 0px #0000000D" 
            color="#FFF" borderRadius={"50%"} 
            boxSizing='border-box' 
            padding={"1.5rem 1rem"}
            _hover={{backgroundColor: "#000", color: "#FFF"}} 
            aria-label='Add new response' 
            icon={<AddIcon />} 
            />
          }
          content={
            <CreateResponse questions={questions} setTriggerClose={setTriggerClose} sid={sid} />
          }
        />
        }
    </Box>
    </>
  )
}

export default SurveyResponses;















