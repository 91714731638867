import React from "react";
import { withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";

const Map = ({coordinates, height, defaultZoom}) => {

  const circleOptions = {
    strokeColor: "green",  // Circle border color
    strokeOpacity: 0.8,      // Border opacity
    strokeWeight: 2,         // Border thickness
    fillColor: "#FF0000",    // Fill color
    fillOpacity: 0.0,        // Fill opacity (transparent)
    radius: 150,            // Circle radius in meters
   };

  const MyMapComponent = withGoogleMap((props) => (
    <GoogleMap defaultZoom={defaultZoom || 16} defaultCenter={{ lat: coordinates[1], lng: coordinates[0]  }}>
      <Marker 
        key={Math.random()} 
        labelStyle={{color: "red !important"}} 
        defaultTitle="hgchgc" 
        position={{  lat: coordinates[1], lng: coordinates[0]  }} 
      />
      <Circle
          center={{ lat: coordinates[1], lng: coordinates[0] }}
          options={circleOptions}
        />
    </GoogleMap>
  ));


  return (
    <div className="live-tracking" style={{flex: 3}}>
        <div style={{position: "relative"}} className="map-area">
            <MyMapComponent isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: height }} />}
            mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
    </div>
  );
};

export default Map;