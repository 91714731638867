import { Box, Flex, Grid, Image, Spinner, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import ImageUploader from '../../../../../components/imageUploader/imageUploader';
import UploadIcon from '../../../../../components/imageUploader/upload.svg';
import UploadIcon2 from '../uploadIcon.svg';
import CustomDrawer from '../../../../../components/customDrawer/customDrawer';
import CustomTextArea from '../../../../../components/customTextArea/customTextArea';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../../utils/helper';
import CustomInput from '../../../../../components/customInput/customInput';

const Posm = ({data, setRefetch, setSelectedDate, selectedDate, storeState }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [loadingd, setLoadingd] = useState(false);
  const [comment, setComment] = useState("");
  const state = useLocation();
  const id = state?.pathname?.split("/")[1];

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const [triggerClose, setTriggerClose] = useState("");

  const toast = useToast();

  const handleImagesUpload = (images) => {
    setUploadedImages(images);
  };

  const uploadImages = async () => {
    // console.log(uploadedImages)
    setTriggerClose("");
    setLoadings(true);
    const datax = {
      type: "posm",
      imageURL: uploadedImages[0],
      comment,
      state: storeState
    };
    try {
      const { data } = await axiosInstance.post(
        `initiative-stores/${id}/shelf-and-posm`,
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
      // navigate(`/initiatives/create/3?iid=${search.slice(5)}`)
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadings(false);
    }
  };

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setSelectedDate(newDate);
  };

  const deleteDoc = async(id) => {
    setLoadingd(true)
    try {
      const {data} = await axiosInstance.delete(`/shelf-and-posm/${id}`)
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingd(false)
    }
  }

  return (
    <Box position={"relative"}>
      <Flex justifyContent="flex-end" mb=".7rem">
          <Flex>
          {currentUser?.role === "Promoter" && 
          <CustomDrawer
            position={"bottom"}
            title={"Upload shelf image"}
            triggerClose={triggerClose}
            toggleElement={
              <Flex justifyContent={"flex-end"}>
                <Image
                  transform={"scale(.8)"}
                  cursor={"pointer"}
                  // right={"0"}
                  // top="-20%"
                  // position={"absolute"}
                  src={UploadIcon2}
                />
              </Flex>
            }
            content={
              <>
                <CustomTextArea
                  type={"text"}
                  placeholder={"Comment"}
                  mb={"1rem"}
                  rows="1"
                  onChange={(e) => setComment(e.target.value)}
                />
                <ImageUploader
                  onImagesUpload={handleImagesUpload}
                  setLoading={setLoading}
                  loading={loading}
                  max={1}
                />
                <Flex gap=".5rem" mt=".7rem">
                  {uploadedImages.map((imageUrl, index) => (
                    <Box
                      backgroundImage={imageUrl}
                      backgroundSize={"contain"}
                      height={"37px"}
                      width={"40px"}
                      borderRadius={"4px"}
                      backgroundColor={"lightgray"}
                    />
                  ))}
                </Flex>
                <PrimaryButton
                  mt="2rem"
                  onClick={uploadImages}
                  text="Update details"
                  loading={loadings}
                />
              </>
            }
          />}
         
          <Box
          right={"0"}
          >
            <CustomInput
            type={"date"}
            required
            onChange={handleDateChange}
            value={formatDate(selectedDate)}
            mt="0"
            py="1.19rem"
            width="150px"
            cursor="pointer"
            />
          </Box>

          </Flex>

          </Flex>


      {data?.length < 1 ? (
        currentUser?.role === "Promoter" && 
        <CustomDrawer
          position={"bottom"}
          title={"Upload shelf image"}
          triggerClose={triggerClose}
          toggleElement={
            <Flex
              mt="1.5rem"
              position={"relative"}
              height={"100px"}
              alignItems={"center"}
              borderRadius={"8px"}
              border="1px dashed #D0D5DD"
              cursor={"pointer"}
              boxSizing="border-box"
              padding={"1rem"}
            >
              <Image src={UploadIcon} />
              <Text
                ml="1rem"
                color={"#344054"}
                fontSize={".9rem"}
                whiteSpace={"nowrap"}
              >
                Upload images here
              </Text>
            </Flex>
          }
          content={
            <>
              <CustomTextArea
                type={"text"}
                placeholder={"Comment"}
                mb={"1rem"}
                rows="1"
                onChange={(e) => setComment(e.target.value)}
              />
              <ImageUploader
                onImagesUpload={handleImagesUpload}
                setLoading={setLoading}
                loading={loading}
                max={1}
                src={"camera"}
              />
              <Flex gap=".5rem" mt=".7rem">
                {uploadedImages.map((imageUrl, index) => (
                  <Box
                    backgroundImage={imageUrl}
                    backgroundSize={"contain"}
                    height={"37px"}
                    width={"40px"}
                    borderRadius={"4px"}
                    backgroundColor={"lightgray"}
                  />
                ))}
              </Flex>
              <PrimaryButton
                mt="2rem"
                onClick={uploadImages}
                text="Update details"
                loading={loadings}
              />
            </>
          }
        />
      ) : (
        <Flex position={"relative"}>
         

          <Grid
            width={"100%"}
            templateColumns={[
              "repeat(auto-fill, minmax(150px, 1fr))",
              "repeat(auto-fill, minmax(150px, 1fr))",
            ]}
            gap={"1rem"}
          >
            {data?.map((i) => (
              <CustomDrawer
              position={"bottom"}
              title={"Shelf image"}
              triggerClose={triggerClose}
              toggleElement={
                <Box key={i?._id} cursor={"pointer"}>
                  <Box
                    bg="lightgray"
                    backgroundImage={i?.imageURL}
                    backgroundSize={"cover"}
                    height={"114px"}
                    borderRadius={"4px"}
                  />
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap="2rem"
                  >
                    <Text mt=".2rem" color={"#000"} fontSize={".8rem"}>
                      {i?.comment}
                    </Text>
                    <Text
                      mt=".2rem"
                      color={"#000"}
                      opacity={".7"}
                      fontSize={".7rem"}
                    >
                      {moment(i?.updatedAt).calendar()}
                    </Text>
                  </Flex>
                </Box>
              }
              content={
                <>
                  <Box cursor={"pointer"}>
                    <Flex mb=".5rem" justifyContent={"flex-end"}>
                      {loadingd ?
                      <Spinner size="xs" />
                      :
                       <Text onClick={() => deleteDoc(i?._id)} fontSize={".9rem"} cursor={"pointer"} textDecoration={"underline"} fontWeight={"500"} color={"crimson"}>Delete</Text>}
                    </Flex>
                    <Box
                      bg="lightgray"
                      backgroundImage={i?.imageURL}
                      backgroundSize={"cover"}
                      height={"200px"}
                      borderRadius={"4px"}
                    />
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      gap="2rem"
                    >
                      <Text mt=".2rem" color={"#000"} fontSize={".9rem"}>
                        {i?.comment}
                      </Text>
                      <Text
                        mt=".2rem"
                        color={"#000"}
                        opacity={".7"}
                        fontSize={".8rem"}
                      >
                        {moment(i?.updatedAt).calendar()}
                      </Text>
                    </Flex>
                  </Box>
                </>
              }
            />
            ))}
          </Grid>
        </Flex>
      )}
    </Box>
  );
}

export default Posm;