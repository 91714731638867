import { Box, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'

const TrueOrFalse = ({data, claimPrize, loading}) => {
  const questions = data?.games?.find(i => i?.name === "true or false")?.questions ?? []
  const prizes = data?.gamePrizes?.filter(x => x?.quantityAvailable !== 0);

  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [finished, setFinished] = useState(false)
  const [loadingx, setLoadingx] = useState(false)

  const handleChange = (questionId, selectedAnswer) => {
    setAnswers({ ...answers, [questionId]: selectedAnswer });
  };

  const handleSubmit = () => {
    setLoadingx(true)
    let newScore = 0;
    questions.forEach((question) => {
      if (answers[question._id] === question.answer) {
        newScore++;
      }
    });
    setScore(newScore);
    setFinished(true)
    setLoadingx(false)
  };


  return (
    <Box mt="2rem">
    {questions?.map(({text, answer, _id}) => {
      return (
        <Box position={"relative"}>
          {finished === true && <Box position={"absolute"} top="0" left={"0"} right={"0"} bottom={"0"} zIndex={"6"} bg="#000" opacity={".4"} borderRadius={"6px"} />}
          <Box key={_id} mb=".7rem" p=".8rem" borderRadius={"6px"} border="1px solid lightgray">
            <Text fontSize={".95rem"} mb=".5rem" fontWeight={"500"} opacity={".8"}>{text}</Text>
            <label style={{fontSize: ".85rem"}}>
              <input
                type="radio"
                value="true"
                checked={answers[_id] === 'true'}
                onChange={() => handleChange(_id, 'true')}
                style={{transform: "translateY(1.5px)", marginRight: ".2rem"}}
              />
              True
            </label>
            <label style={{fontSize: ".85rem", marginLeft: ".7rem"}}>
              <input
                type="radio"
                value="false"
                checked={answers[_id] === 'false'}
                onChange={() => handleChange(_id, 'false')}
                style={{transform: "translateY(1.5px)", marginRight: ".2rem"}}
              />
              False
            </label>
          </Box>
        </Box>
        
      )
    })}

    {finished === false ? 
    <PrimaryButton mt="6rem" onClick={handleSubmit} text="Finish Quiz" loading={loadingx} isDisabled={Object.keys(answers)?.length < questions?.length} />
    :
    <Box mt="3rem" textAlign={"center"}>
      <Text>You got <strong>{score}</strong> out of <strong>{questions?.length}</strong> questions</Text>
      <Text>You win <strong style={{textTransform: "capitalize"}}>{prizes?.find(i => i?.rank === score)?.name}</strong>!!</Text>

      <PrimaryButton mt="2rem" onClick={() => claimPrize('true or false', prizes?.find(i => i?.rank === score)?.name)} text="Claim Prize" loading={loading} />
    </Box>
    }
    </Box>
  )
}

export default TrueOrFalse;