import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import CustomInput from '../../../components/customInput/customInput';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../services/api';
import Navbar from '../../../components/navbar/navbar';

const ChangePassword = () => {

  const [oPassword, setOPassword] = useState();
  const [nPassword, setNPassword] = useState();
  const [cPassword, setCPassword] = useState();
  const [loading, setLoading] = useState(false);

  const toast = useToast()

  const update = async(e) => {
    e.preventDefault()

    if(nPassword === cPassword) {
        setLoading(true)
        const datax =  {
        currentPassword: oPassword,
        newPassword: nPassword,
        }

        try {
        const {data} = await axiosInstance.patch('/employees/change-password', datax)
        setOPassword("")
        setNPassword("")
        setCPassword("")
        toast({
            description: data?.message,
            status: "success",
            position: 'top'
        });
        } catch(error) {
        toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
        });
        } finally {
        setLoading(false)
        }
    } else {
        toast({
            title: "An Error Occurred",
            description: "Passwords mismatch, please check and try again!",
            status: "error",
        });
    }
  }

  return (
    <Box py="1rem">
     <Text fontWeight={"500"} mb=".7rem" fontSize={".95rem"}>Protect your account.</Text>
     <Box bg="#FFF" mt="" borderRadius={"8px"}>
        <Box maxW={"600px"} margin="0 auto">
            <form onSubmit={update}>
                <CustomInput
                placeholder={"Old Password"}
                type="password"
                value={oPassword}
                onChange={(e) => setOPassword(e.target.value)}
                mb="1rem"
                />
                <CustomInput
                placeholder={"New Password"}
                type="password"
                value={nPassword}
                minLength={8}
                onChange={(e) => setNPassword(e.target.value)}
                mb="1rem"
                />
                <CustomInput
                placeholder={"Confirm Password"}
                type="password"
                minLength={8}
                value={cPassword}
                onChange={(e) => setCPassword(e.target.value)}
                />
                <PrimaryButton mt="2rem" type="submit" text="Change Password" loading={loading} />
            </form>
        </Box>
     </Box>
    </Box>
    
  )
}

export default ChangePassword;