import { Box, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import CustomSelect from '../../../components/customSelect/customSelect';
import Navbar from '../../../components/navbar/navbar';
import Overview from '../../../components/overView/overView';
import axiosInstance from '../../../services/api';
import { useParams } from 'react-router-dom';
import { VscCircleFilled } from 'react-icons/vsc';
import EmptyState from '../../../components/emptyState/emptyState';
import SpinLoader from '../../../components/spinLoader/spinLoader';
import CustomDrawer from '../../../components/customDrawer/customDrawer';
import moment from 'moment';

const Attendance = ({source}) => {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState('');
  const [records, setRecords] = useState([]);
  const [dayRecords, setDayRecords] = useState([]);
  const [loadings, setLoadings] = useState(false);

  const today = new Date()
  const day = today.getDate()
  const monthx = today.getMonth() + 1
  const year = today.getFullYear()

  const [dateToView, setDateToView] = useState("")

  const months = [
    {
      title: `January ${year}`,
      value: '01'
    },
    {
      title: `February ${year}`,
      value: '02'
    },
    {
      title: `March ${year}`,
      value: '03'
    },
    {
      title: `April ${year}`,
      value: '04'
    },
    {
      title: `May ${year}`,
      value: '05'
    },
    {
      title: `June ${year}`,
      value: '06'
    },
    {
      title: `July ${year}`,
      value: '07'
    },
    {
      title: `August ${year}`,
      value: '08'
    },
    {
      title: `September ${year}`,
      value: '09'
    },
    {
      title: `October ${year}`,
      value: '10'
    },
    {
      title: `November ${year}`,
      value: '11'
    },
    {
      title: `December ${year}`,
      value: '12'
    }
  ]

  const [month, setMonth] = useState(monthx);

  const arr = [...Array(31).keys()]

  const {id} = useParams()

  const getAttendanceRecords = async() => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(`/initiative-stores/${id}/attendances`)
      setRecords(data?.data)
    } catch (error) {
      console.log(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAttendanceRecords()
  }, [])

  const getSingleAttendance = async() => {
    setLoadings(true)
    try {
      const { data } = await axiosInstance.get(`/initiative-stores/${id}/attendances?clockInDate=${dateToView}`)
      setDayRecords(data?.data)
      // console.log(`single data for ${dateToView}`, data?.data)
    } catch (error) {
      console.log(error?.response?.data?.message)
    } finally {
      setLoadings(false)
    }
  }

  useEffect(() => {
    getSingleAttendance()
  }, [dateToView])


  const data = [
    {
        title: "Days worked",
        value: records?.filter(i => i?.hoursWorked >= 8)?.length,
    },
    {
        title: "Hours worked",
        value: records?.reduce((acc, curr) => acc + (curr?.hoursWorked < 1 ? (curr?.hoursWorked * (-1)) : curr?.hoursWorked), 0)?.toFixed(2),
    },
    {
        title: "% Attendance",
        value: ((records?.reduce((acc, curr) => acc + (curr?.hoursWorked < 1 ? (curr?.hoursWorked * (-1)) : curr?.hoursWorked), 0) / 240) * 100)?.toFixed(2),
    },
    {
        title: "Avg daily hours",
        value: ((records?.reduce((acc, curr) => acc + (curr?.hoursWorked < 1 ? (curr?.hoursWorked * (-1)) : curr?.hoursWorked), 0)) / day)?.toFixed(2),
    }
]

  const options2 = [
    // {
    //   title: "Sku 1",
    //   value: "Sku 1"
    // },
    // {
    //   title: "Sku 2",
    //   value: "Sku 2"
    // }
  ]
  return (
    <Box overflowY={"scroll"}>
        {source === "main" && <Navbar title={"Attendance"} showIcon={false} />}
        <Box pt={source === "main" ? "3.5rem" : ""} px={source === "main" ? "1rem" : ""} pb="7rem">
            <Flex alignItems={"center"} gap="1rem" mt="1rem">
                {/* <CustomSelect 
                placeholder={"Store"}
                selection={store}
                setSelection={setStore}
                options={options1}
                mb=".8rem"
                height="38px"
                width="90px"
                fontSize=".9rem"
                borderColor="#667085 !important"
                /> */}

                {/* <CustomSelect 
                selection={month}
                setSelection={setMonth}
                options={[
                  months?.filter(i => i.value === (monthx?.toString()))[0],
                  ...months?.filter(i => i.value !== (monthx?.toString()))
                ]}
                mb=".8rem"
                height="38px"
                width="170px"
                fontSize=".9rem"
                /> */}
            </Flex>
            <Overview data={data} />
            <Box mt="1.5rem">
                <Heading fontWeight={500} mt="1.5rem" fontSize="1.1rem" mb="1rem" color="#475467" fontFamily='Roboto'>Attendance records</Heading>
                <Flex justifyContent={"flex-end"} mb=".5rem">
                  <Flex gap="1rem">
                    <Text fontSize={"10px"} color="#667085"><VscCircleFilled style={{display: "inline", transform: "translateY(1.5px) scale(1.4)"}} color="#348E3D" /> Days Attended</Text>
                    <Text fontSize={"10px"} color="#667085"><VscCircleFilled style={{display: "inline", transform: "translateY(1.5px) scale(1.4)"}} color="#FF9089" /> Days Missed</Text>
                    <Text fontSize={"10px"} color="#667085"><VscCircleFilled style={{display: "inline", transform: "translateY(1.5px) scale(1.4)"}} color="#6941C6" /> Day in progress</Text>
                  </Flex>
                </Flex>
                

                <Grid
                  width={"100%"}
                  templateColumns={[
                    "repeat(auto-fill, minmax(50px, 1fr))",
                    "repeat(auto-fill, minmax(50px, 1fr))",
                  ]}
                  gap={".7rem"}
                >
                  {arr?.map((i) => {
                    return (
                      <CustomDrawer
                      key={i}
                      position={"bottom"}
                      title={`Attendance for ${i + 1}/${month || monthx}/${year}`}
                      triggerClose={null}
                      setTriggerClose={() => {}}
                      toggleElement={
                        <Flex onClick={() => setDateToView(`${year}-${(month?.toString() || monthx?.toString())?.length === 1 ? `0${month || monthx}` : month || monthx}-${((i + 1)?.toString())?.length === 1 ? `0${(i + 1)}` : (i + 1)}`)} cursor={"pointer"} alignItems={"center"} justifyContent={"center"} height={"50px"} width={"50px"} borderRadius={"4px"}
                        bg={day === (i + 1) ? "#6941C6" : (i + 1) > day && monthx <= parseInt(month) ? "#D0D5DD" : records?.filter(v => parseInt(v?.clockInDate?.slice(-2)) === (i + 1))?.reduce((acc, curr) => acc + curr?.hoursWorked, 0) >= 8 ? "#348E3D" : "#FF9089"}
                        >
                          <Text color="#FCFCFD" fontSize={".9rem"} fontWeight={"600"}>{i + 1}</Text>
                        </Flex>
                      }
                      content={
                          <>
                          {loadings ?
                          <SpinLoader />
                          :
                          dayRecords?.length < 1 ?
                          <EmptyState mt="2rem" text="No Clock-In records found for this day" />
                          :
                          <Box>
                            <Flex justifyContent="flex-end" mt="1rem" mb=".3rem">
                              <Text fontWeight={"500"} color={"#98A2B3"} fontSize={".95rem"}>Total Hours Worked: <span style={{color: "#667085"}}>{dayRecords?.reduce((acc, curr) => acc + curr?.hoursWorked, 0)}</span></Text>
                            </Flex>
                            {dayRecords?.map(({_id: id, clockInTime, clockOutTime, hoursWorked}) => (
                              <Box key={id} p=".7rem 1rem" mb=".8rem" borderRadius={"10px"} border=".5px solid #D0D5DD">
                                <Flex width={"90%"} alignItems={"center"} justifyContent={"space-between"}>
                                  <Box>
                                    <Text mb=".2rem" fontWeight={"500"} color={"#667085"} fontSize={".9rem"}>Clock In</Text>
                                    <Text fontWeight={"500"} color={"#98A2B3"} fontSize={".83rem"}>{moment(clockInTime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')}</Text>
                                  </Box>
                                  <Box>
                                    <Text mb=".2rem" fontWeight={"500"} color={"#667085"} fontSize={".9rem"}>Clock Out</Text>
                                    <Text fontWeight={"500"} color={"#98A2B3"} fontSize={".83rem"}>{moment(clockOutTime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A') || '--'}</Text>
                                  </Box>
                                  <Box>
                                    <Text mb=".2rem" fontWeight={"500"} color={"#667085"} fontSize={".9rem"}>Hours Worked</Text>
                                    <Text fontWeight={"500"} color={"#98A2B3"} fontSize={".83rem"}>{hoursWorked || '--'}</Text>
                                  </Box>
                                </Flex>
                                <Text mt="1rem" width={"fit-content"} fontSize={"12px"} fontWeight={"500"} borderRadius={"20px"} padding={".2rem .8rem .3rem .8rem"} bg={clockOutTime === null ? "#FFF2DB" : "#E0FFDB"} color={clockOutTime === null ? "#EC913C" : "#248C41"} textTransform={"capitalize"}>{clockOutTime === null ? "Pending" : "Done"}</Text>
                              </Box>
                            ))}
                          </Box>
                          }
                          </>
                      }
                      />
                    )
                  })}
                </Grid>
                
            </Box>
        </Box>
    </Box>
  )
}

export default Attendance;