/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import classes from './quickResponse.module.css';
import { IoPersonCircleOutline, IoSend } from "react-icons/io5";
import io from 'socket.io-client';
import { timeFormatter } from '../../helpers/timeFormatter';
import { groupMessagesByDate } from '../../helpers/groupMessagesByDate';
import { formatDateHeader } from '../../helpers/formatDateHeader';
import { useSelector } from 'react-redux';
import { HiOutlineChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { MdClose, MdOutlineCall, MdOutlineCallEnd } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useToast } from '@chakra-ui/react';
import { TiImage } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import styles from './meeting.module.css';
import './meeting.css';
import { ImPhoneHangUp } from "react-icons/im";
import { LuMinimize2 } from "react-icons/lu";
import { BsRobot } from "react-icons/bs";


const backend_chat_url = process.env.REACT_APP_BACKEND_CHAT_URL || '';
const socket = io(backend_chat_url);
const adminId = process.env.REACT_APP_ADMIN_CHAT_ID;
const chat_name = 'chat message';
const closureMessage = process.env.REACT_APP_CLOSURE_MESSAGE;

export default function QuickResponse() {
    const toast = useToast();
    const currentUser = useSelector(({ userData }) => userData?.currentUser);
    const { firstName, lastName, _id, email, phoneNumber } = currentUser || {};
    const fullName = `${firstName} ${lastName}`;
    const userId = _id;

    const [status, setStatus] = useState('');
    const [timer, setTimer] = useState(0);
    const navigate = useNavigate();

    const [messages, setMessages] = useState(() => {
        const savedMessages = localStorage.getItem(`chatMessages_${userId}`);
        return savedMessages ? JSON.parse(savedMessages) : [];
    });
    const [input, setInput] = useState('');
    const [unreadCount, setUnreadCount] = useState(() => {
        const savedUnreadCount = localStorage.getItem(`unreadCount_${userId}`);
        return savedUnreadCount ? parseInt(savedUnreadCount, 10) : 0;
    });

    const [isChatBoxVisible, setIsChatBoxVisible] = useState(false);
    const messagesEndRef = useRef(null);
    const containerRef = useRef(null);

    const notificationSound = useRef(new Audio('/assets/sounds/simple-notification-152054.mp3'));

    const [allowMessages, setAllowMessages] = useState(null)

    const hasMessages = localStorage.getItem(`chatMessages_${userId}`);

    useEffect(() => {

        setAllowMessages(hasMessages ? 'chat with tech support' : '')

        socket.emit('register', userId);
        socket.on(chat_name, (msg) => {
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, msg];
                if (msg.msg === closureMessage && msg.from === adminId) {
                    localStorage.removeItem(`chatMessages_${userId}`);

                    setMessages([msg])
                } else {
                    localStorage.setItem(`chatMessages_${userId}`, JSON.stringify(updatedMessages));
                }
                return updatedMessages;
            });



            notificationSound.current.play().catch((error) => {
                console.error("Failed to play notification sound:", error);
            });

            if (!isChatBoxVisible) {
                setUnreadCount((prevCount) => {
                    const newCount = prevCount + 1;
                    localStorage.setItem(`unreadCount_${userId}`, newCount.toString());
                    return newCount;
                });
            }
        });

        socket.on('hey', (data) => {
            setStatus(data.msg);
            if (data.msg === 'Call ended') {
                setTimeout(() => {
                    navigate(0);
                }, 1000);
            }
            if (data.msg === "Call ended") {
                setTimer(0);
            }
        });

        return () => {
            socket.off(chat_name);
            socket.off('hey');
            socket.off('callUser');
        };
    }, [userId, isChatBoxVisible]);

    useEffect(() => {
        let interval;
        if (status === 'In call') {
            interval = setInterval(() => {
                setTimer((prev) => prev + 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [status]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);



    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        if (input) {
            socket.emit(chat_name, fullName, input, userId, adminId, new Date().toISOString(), email, phoneNumber, 'text', hasMessages ? false : true);
            setInput('');
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const groupedMessages = groupMessagesByDate(messages);
    const sortedDates = Object.keys(groupedMessages).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const [image, setImage] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);

    const setImageToStorage = (file) => {
        if (file[0].type !== 'image/jpeg' && file[0].type !== 'image/png') {
            return toast({
                description: 'only jpg/png images supported',
                status: "error",
            });
        }

        const fileURL = URL.createObjectURL(file[0]);
        setImage({
            url: fileURL,
            file: file[0],
        });
    };

    const sendImage = async () => {
        const formData = new FormData();
        formData.append('image', image.file);
        formData.append('userId', userId);

        const config = {
            headers: {
                "Content-Type": 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setUploadProgress(percentage);
            },
        };

        try {
            const res = await axios.post(`${backend_chat_url}/upload`, formData, config);
            const data = res.data.msg;

            socket.emit(chat_name, fullName, data, userId, adminId, new Date().toISOString(), email, phoneNumber, 'image');
            setImage({});
        } catch (error) {
            console.error(error);
            setImage({});
        } finally {
            setUploadProgress(0);
        }
    };

    const [callActive, setCallActive] = useState('ended');

    useEffect(() => {
        function handleClickOutside(event) {
            if (callActive === 'fullScreen' && containerRef.current && !containerRef.current.contains(event.target)) {
                setCallActive('minimized');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callActive]);

    const frameRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.metered.ca/sdk/frame/1.4.3/sdk-frame.min.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (window.MeteredFrame) {
                const frame = new window.MeteredFrame();
                frameRef.current = frame;
                frame.init({
                    roomURL: process.env.REACT_APP_ROOM_URL,
                    autoJoin: true,
                }, document.getElementById('metered-frame'));

                frame.on('participantLeft', (participantInfo) => {
                    console.log('Participant left:', participantInfo);
                });
            } else {
                console.error('MeteredFrame is not available on window');
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const joinCall = () => {
        if (frameRef.current) {
            setIsChatBoxVisible(false);
            setCallActive('fullScreen');
            frameRef.current.join({ name: 'James Bond' });
            frameRef.current.init({
                roomURL: 'tobbey.metered.live/tobbeytest',
                autoJoin: true,
            }, document.getElementById('metered-frame'));
        } else {
            console.error('MeteredFrame instance is not available');
        }
    };

    const end = () => {
        if (frameRef.current) {
            frameRef.current.leave();
            setIsChatBoxVisible(false);
            setCallActive('ended');
        } else {
            console.error('MeteredFrame instance is not available');
        }
    };

    const subjects = ['Attendance issue', 'POSM issues', 'suggestions', 'chat with tech support']



    const activateChat = (name) => {
        console.log(name);
        setAllowMessages(name)
    }

    return (
        < div ref={containerRef}>
            <div className={classes.container}>
                <div className={callActive === 'fullScreen' ? styles.container : styles.container_inactive}>
                    <div className={classes.met} id="metered-frame" />

                    <div className={styles.leave}>
                        <p className={styles.call_status}>Call from Tech Support</p>

                        <LuMinimize2 onClick={() => { setCallActive('minimized'); }} className={styles.minimize_icon} />

                        <div className={styles.hang_up_div}>
                            <ImPhoneHangUp className={styles.end_call_icon} />
                            <p onClick={end}>Hang up </p>
                        </div>
                    </div>
                </div>

                <div className={isChatBoxVisible ? classes.chat_arena : classes.chat_arena_bland}>
                    <audio id="remoteAudio" autoPlay></audio>

                    <div className={classes.instruction_area}>
                        <p className={classes.chat_with_title}>Tech Support</p>
                        <div className={classes.grouped_online}>
                            <div className={classes.online_indicator} />
                            <p>Online</p>
                        </div>
                    </div>
                    {
                        allowMessages ?

                            <>
                                <div id="messages" className={classes.chat_side}>
                                    {
                                        allowMessages && <div className={classes.admin_side_chat_container}>
                                            <BsRobot />
                                            <p className={classes.chatMsg}>Kindly type your {allowMessages.startsWith('chat with') ? 'message' : allowMessages}</p>
                                        </div>
                                    }

                                    {sortedDates.map((date) => (
                                        <div key={uuidv4()} className={classes.main_chat}>
                                            <div className={classes.date_header}>{formatDateHeader(date)}</div>
                                            {groupedMessages[date].map((item) => (
                                                <div key={uuidv4()} className={
                                                    item.from !== userId ? classes.admin_side_chat_container : classes.client_side_chat_container
                                                }>

                                                    {(item.from !== userId && item.fullName !== 'bot') && <IoPersonCircleOutline />}

                                                    {
                                                        item.fullName === 'bot' && <BsRobot />
                                                    }

                                                    {item.inputType === 'call' ?
                                                        <p style={{ cursor: 'pointer' }} onClick={joinCall}>{item.msg}</p> :
                                                        item.inputType === 'image' ?
                                                            <img src={item.msg} alt='' /> :
                                                            <p className={classes.chatMsg}>{item.msg}</p>
                                                    }
                                                    <p style={item.from === adminId ? { color: 'rgb(222, 222, 222)' } : {}} className={classes.time_stamp}>{timeFormatter(new Date(item.timeStamp))}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    <div ref={messagesEndRef} />
                                </div>

                                <form onSubmit={handleSubmit} className={classes.input_container}>
                                    <input
                                        value={input}
                                        type='text'
                                        className={classes.input}
                                        onChange={(e) => setInput(e.target.value)}
                                        placeholder='Type a message...'
                                    />
                                    <Image image={image} setImageToStorage={setImageToStorage} setImage={setImage} sendImage={sendImage} uploadProgress={uploadProgress} />
                                    <div onClick={() => handleSubmit(undefined)} className={input ? classes.send_icon_bg : classes.send_icon_bg_opa}>
                                        <IoSend className={classes.send_icon} />
                                    </div>
                                </form>
                            </>
                            :
                            <div className={classes.virtualAssistantContainer}>
                                <BsRobot />
                                <p>
                                    I am The Promorama's assistant. I will try to find an answer for you and if I cannot, I will lead you to the right team.
                                </p>

                                <p className={classes.subjectTitle}>
                                    Please select a subject from below:
                                </p>
                                <div className={classes.groupedSubjects}>
                                    {
                                        subjects?.map((item, index) => (
                                            <div
                                                key={index}
                                                onClick={() => (item === 'chat with tech support' || item === 'suggestions') && activateChat(item)}
                                                className={classes.subjectsContainer}
                                            >
                                                <p>{item}</p>
                                            </div>
                                        ))
                                    }

                                </div>

                            </div>
                    }


                </div>

                {callActive === 'minimized' && <p className={styles.call_visibility_btn} onClick={() => setCallActive('fullScreen')}>Ongoing call with Tech Support</p>}
                <div ref={containerRef} className={classes.icon_container} onClick={() => { setIsChatBoxVisible(!isChatBoxVisible); localStorage.setItem(`unreadCount_${userId}`, '0'); setUnreadCount(0); }}>
                    {isChatBoxVisible ? <MdClose className={classes.chat_icon} /> : (
                        <>
                            <HiOutlineChatBubbleOvalLeftEllipsis className={classes.chat_icon} />
                            {unreadCount > 0 && <div className={classes.unread_count}>
                                <p>{unreadCount}</p>
                            </div>}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

function Image({ image, setImageToStorage, setImage, sendImage, uploadProgress }) {
    return (
        <>
            <div className={Object.keys(image).length > 0 ? classes.image_previewer_container : classes.image_previewer_container_inactive}>
                <div style={image?.url ? { backgroundImage: `url(${image?.url})` } : {}} className={classes.image_container}>
                    {uploadProgress > 0 && (
                        <div className={classes.progress_bar_container}>
                            <CircularProgressbar
                                value={uploadProgress}
                                text={`${uploadProgress}%`}
                                styles={buildStyles({
                                    textColor: '#fff',
                                    pathColor: '#F97066',
                                    trailColor: '#eee',
                                })}
                            />
                        </div>
                    )}
                </div>

                <div className={classes.upload_btn_grouped}>
                    <p style={{ background: '#626262' }} className={classes.send} onClick={() => setImage({})}>cancel</p>
                    <p className={classes.send} onClick={() => { !uploadProgress && sendImage() }}>send</p>
                </div>
            </div>

            <Dropzone onDrop={acceptedFiles => {
                setImageToStorage(acceptedFiles);
            }}>
                {({ getRootProps, getInputProps }) => (
                    <section className={classes.send_icon_bg}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <TiImage className={classes.image_upload_icon} />
                        </div>
                    </section>
                )}
            </Dropzone>
        </>
    );
}
