import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import React from 'react'

const CustomSelect = ({placeholder, options, setSelection, selection, label, type, required, ...props}) => {
  return (
    <FormControl>
          {label && <FormLabel fontSize={"13px"} bg="#FFF" width={"fit-content"} transform={"translateY(5px)"}>{label}</FormLabel>}
    <Select
    height={["50px", "59.59px"]}
    // px="1rem"
    zIndex={"6"}
    // onMouseEnter={handleFocus}
    // onClick={handleFocus}
    // onSelect={handleFocus}
    borderColor={"#667085"}
    _focus={{ borderColor: "#F9F5FF" }}
    // _placeholder={{ opacity: .6 }}
    placeholder={placeholder}
    onChange={e => setSelection(e.target.value)}
    required={required}
    {...props}
    >
      {options?.map(({title, value}, index) => {
        return (
            <option style={{color: "#000", textTransform: "capitalize"}} key={index} value={value}>{title}</option>
        )
      })}
      {type === "pc" && <option style={{color: "#000"}} value="other">other</option>}
    </Select>
    </FormControl>
  )
}

export default CustomSelect