import { useEffect, useState } from "react";
import QrScanner from "./qrScanner/QrScanner";
import axiosInstance from "../../services/api";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import CustomDrawer from "../../components/customDrawer/customDrawer";
import PrimaryButton from "../../components/primaryButton/primarybutton";
import CustomTable from "../../components/customTable/customTable";
import EmptyState from "../../components/emptyState/emptyState";
import LineSkeleton from "../../components/lineSkeleton";
import CustomInput from "../../components/customInput/customInput";
import { formatDate } from "../../utils/helper";

const Assets = () => {
  const [loading, setLoading] = useState(true);
  const [triggerClose, setTriggerClose] = useState('');
  const [date, setDate] = useState(formatDate(new Date()))
  const [refetch, setRefetch] = useState("");
  const [assets, setAssets] = useState([]);
  const [idx, setIdx] = useState('');

  // const currentDay = new Date(date);
  // currentDay.setDate(date.getDate());
  // const currentDayFormatted = formatDate(currentDay);

  const getAssets = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(date ? `/assets/entry/users?date=${date}` : '/assets/entry/users');
      setAssets(data?.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssets();
  }, [refetch, date]);

  const tHeaders = ['Name', 'Inventory', 'MSL', 'Condition', 'Comment']

  const tData = assets?.map(i => ({
    one: `${i?.asset?.brand} ${i?.asset?.category} ${i?.asset?.type}`,
    two: i?.inventoryLevel,
    three: i?.mslLevel,
    four: i?.condition,
    five: i?.comment,
    id: i?.asset?._id,
  }))
  
  
  return (
    <div>

    <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex gap="1rem" alignItems={"center"}>
            <Box width={"150px"}>
                <CustomInput
                type={"date"}
                required
                placeholder={"Select Date"}
                value={date}
                onChange={e => setDate(e.target.value)}
                mt="0"
                py="1rem"
                mb="0"
                borderColor="lightgray"
                fontSize=".8rem"
                />
            </Box>
        </Flex>
        <CustomDrawer
        position={"bottom"}
        title={"Scan Asset"}
        triggerClose={triggerClose}
        toggleElement={
        <Flex justifyContent={'center'}>
            <PrimaryButton width="" text="Scan Asset" py="1rem" borderRadius="6px" icon="camera" func={() => {}} loading={false} />
        </Flex>
        }
        content={
        <>
        <QrScanner setRefetch={setRefetch} idx={''} setIdx={setIdx} refetch={refetch} setTriggerClose={setTriggerClose} />
        </>
        }
    />
        </Flex>
    {loading ?
        <LineSkeleton num={8} />
        :
        assets?.length < 1 ? <EmptyState text={"No entries found for this day."} />
        :
        <Box marginTop={"1.5rem"}>
        <CustomTable
        headers={tHeaders}
        data={tData}
        setIdx2={setIdx}
        setIdx={() => {}}
        triggerClose={triggerClose}
        manage={<QrScanner setRefetch={setRefetch} idx={idx} setIdx={setIdx} refetch={refetch} />}
        />
        </Box>}
    </div>
  );
};

export default Assets;