import { format } from 'date-fns';

export const groupMessagesByDate = (messages) => {
    try {
        const groupedMessages = {};

        messages.forEach(message => {
            const dateObj = new Date(message.timeStamp);
            // Check if the date object is valid
            if (isNaN(dateObj.getTime())) {
                console.warn('Invalid date:', message.timeStamp);
                return; // Skip invalid dates
            }
            const date = format(dateObj, 'yyyy-MM-dd');
            if (!groupedMessages[date]) {
                groupedMessages[date] = [];
            }
            groupedMessages[date].push(message);
        });

        return groupedMessages;
    } catch (error) {
        console.error('Error grouping messages by date:', error);
        return {};
    }
}
