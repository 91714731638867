import { Box, Heading, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userActionTypes } from "../../../redux/constants/userActionTypes";
import axiosInstance from "../../../services/api";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import GoBack from "../../../components/goBack";
import CustomInput from "../../../components/customInput/customInput";

const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const {state} = useLocation()

    const toast = useToast()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const datax = {
        email: email,
    }

    const forgotPassword = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const {data} = await axiosInstance.post("auth/forgot-password", datax)
            navigate("/forgot-password/reset", {state: {email: email}})
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }


    return (
        <Box>
            <GoBack />
            <Box padding="1rem">
                <Heading mt={6} fontWeight={500} fontSize="1.3rem" color="#030303" fontFamily='Roboto'>Forgot password?</Heading>
                <Text mt={2} mb="2rem" color={"#667085"} fontSize={".9rem"}>Enter email adress to get reset instructions</Text>
                {/* <form> */}
                    <CustomInput 
                    type={"email"}
                    // required
                    placeholder={"Email Address"}
                    mb={".8rem"}
                    onChange={e => setEmail(e.target.value)}
                    />
                    <br />
                    <PrimaryButton type="submit" text="Send reset instructions" icon="next" func={forgotPassword} loading={loading} />
                    <Text mt="2rem" textAlign={"center"} fontSize={".9rem"} color="#475467">Already have an account? <span onClick={() => navigate("login", {state: {userType: state?.userType}})} style={{fontWeight: "600", color: "#7F56D9", cursor: "pointer", transition: "250ms ease"}}>Login here</span></Text>
                {/* </form> */}
            </Box>
        </Box>
    )
}

export default ForgotPassword;

