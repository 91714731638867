import { Box, Flex, Heading, Spinner, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons';
import CustomTable from '../../../../components/customTable/customTable';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import CustomInput from '../../../../components/customInput/customInput';
import CustomSelect from '../../../../components/customSelect/customSelect';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../../services/api';
import EmptyState from '../../../../components/emptyState/emptyState';
import ImageUploader from '../../../../components/imageUploader/imageUploader';
import { useSelector } from 'react-redux';

const Sos = ({data}) => {
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingu, setLoadingu] = useState(false);
  const [loadingd, setLoadingd] = useState(false);
  const [brand, setBrand] = useState('');
  const [sku, setSku] = useState('');
  const [value, setValue] = useState('');

  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  
  const [comment, setComment] = useState('');
  const [date, setDate] = useState('');
  const [filterDate, setFilterDate] = useState('');

  const [imageURL, setImageURL] = useState("");

  const handleImagesUpload = (images) => {
    setImageURL(images[0]);
  };

  const [idx, setIdx] = useState({
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    six: '',
    seven: '',
    id: ''
  })

  const [brandx, setBrandx] = useState(idx?.two);
  const [isPromoOnx, setIsPromoOnx] = useState(idx?.five);
  const [skux, setSkux] = useState(idx?.three);

  const [triggerClose, setTriggerClose] = useState("");

  const state = useLocation()
  const id = state?.pathname?.split('/')[1]
  const [docs, setDocs] = useState([])
  const [refetch, setRefetch] = useState('')
  const toast = useToast()

    const getDocs = async() => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(filterDate ? `/sos?initiativeStore=${id}&date=${filterDate}` : `/sos?initiativeStore=${id}`)
            setDocs(res?.data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDocs()
    }, [refetch, filterDate])


  const tHeaders = ['Date', 'Brand', 'SKU', 'Value', 'Status']

  const tData = docs?.map(i => ({
    one: i?.date?.slice(0, 10),
    two: i?.brandName,
    three: i?.sku,
    four: i?.value,
    five: `${i?.comment?.slice(0, 5)}..`,
    id: i?._id,
  }))

  const options1 = data?.initiative?.mslBrands?.map(i => ({
    title: i?.name,
    value: i?.name
  }))

  const options2 = data?.initiative?.mslBrands?.filter(i => i?.name === brand)?.map(i => ({
    title: i?.sku,
    value: i?.sku
  }))


  const addOrder = async () => {
    setTriggerClose("");
    setLoadingx(true);
    const datax = {
      date: date?.slice(0, 10),
      brandName: brand,
      sku: sku,
      value: value,
      comment,
      imageURL,
      state: data?.store?.state
    };
    try {
      const res = await axiosInstance.post(
        `initiative-stores/${id}/sos`,
        datax
      );
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
      setBrand("")
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const editOrder = async () => {
    setTriggerClose("");
    setLoadingx(true);
    const datax = {
      date: idx?.one?.slice(0, 10),
      brandName: idx?.two,
      sku: idx?.three,
      value: parseInt(idx?.four),
      comment: idx?.six,
      state: data?.store?.state
    };
    try {
      const res = await axiosInstance.patch(
        `sos/${idx?.id}`,
        datax
      );
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const uniqueBrandsList = options1?.filter((item, index, array) => {
    return (
      index === array.findIndex(obj => obj.title === item.title && obj.value === item.value)
    );
  });

  const deleteDoc = async(id) => {
    setLoadingd(true)
    try {
      const {data} = await axiosInstance.delete(`/sos/${id}`)
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingd(false)
    }
  }

  // console.log([...uniqueBrandsList, {title: "other", value: "other"}])


  return (
    <Box>
        <Flex mt="1rem" mb="1.2rem" alignItems={"center"} justifyContent={"space-between"}>
            <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>SOS</Heading>
            {currentUser?.role === "Promoter" && <CustomDrawer 
              position={"bottom"} 
              title={"Add SOS"} 
              triggerClose={triggerClose}
              toggleElement={
                <Text fontSize={"12px"} padding={".4rem .5rem"} cursor={"pointer"} color={"#667085"} fontWeight={"600"} border=".8px solid #D0D5DD" borderRadius={"8px"}>Add SOS<AddIcon transform={"translateY(-1px) scale(.9)"} ml=".3rem" /></Text>
              }
              content={
                <>
                  <CustomInput
                  type={"date"}
                  required
                  placeholder={"Date"}
                  mb={".8rem"}
                  onChange={e => setDate(e.target.value)}
                  />

                  
                  
                  <CustomSelect
                  placeholder={"Brand"}
                  selection={brand}
                  setSelection={setBrand}
                  options={uniqueBrandsList}
                  mb=".8rem"
                  type={"pc"}
                  />
                  {(brand === "" || uniqueBrandsList?.map(i => i.value).includes(brand)) &&
                    <CustomSelect 
                  placeholder={"SKU"}
                  selection={sku}
                  setSelection={setSku}
                  options={options2}
                  mb=".8rem"
                  />}

                  {(brand !== "" && !uniqueBrandsList?.map(i => i.value).includes(brand)) &&
                  <>
                  <CustomInput
                  type={"text"}
                  required
                  placeholder={"Brand"}
                  mb={".8rem"}
                  onChange={e => setBrand(e.target.value)}
                  />
                  <CustomInput
                  type={"text"}
                  required
                  placeholder={"SKU"}
                  mb={".8rem"}
                  onChange={e => setSku(e.target.value)}
                  />
                  </>
                  }
                  
                  <CustomInput
                  type={"tel"}
                  required
                  placeholder={"Value"}
                  mb={".8rem"}
                  onChange={e => setValue(parseInt(e.target.value))}
                  />

                <Flex gap=".5rem" mb=".8rem" alignItems={"center"}>
                  
                  {imageURL !== '' &&
                   <Box
                     backgroundImage={imageURL}
                     backgroundSize={"contain"}
                     height={"70px"}
                     width={"100px"}
                     borderRadius={"4px"}
                     backgroundColor={"lightgray"}
                   />}
                 
                 <ImageUploader
                 onImagesUpload={handleImagesUpload}
                 setLoading={setLoadingu}
                 loading={loadingu}
                 max={1}
               />
               </Flex>

                  <CustomInput
                  type={"text"}
                  required
                  placeholder={"Comment"}
                  mb={".8rem"}
                  onChange={e => setComment(e.target.value)}
                  />
                  <PrimaryButton mt="2rem" onClick={addOrder} text="Add SOS" loading={loadingx} />
                </>
              }
            />}
        </Flex>

        <Flex mb="1.2rem" alignItems={"center"} justifyContent={"flex-end"}>
        <Flex gap="1rem" alignItems={"center"}>
            {filterDate !== "" && <Text onClick={() => setFilterDate("")} fontSize={".9rem"} cursor={"pointer"} fontWeight={"500"} color="#7F56D9">Show all dates</Text>}
            <Box width={"150px"}>
                <CustomInput
                type={"date"}
                required
                placeholder={"Select Date"}
                value={filterDate}
                onChange={e => setFilterDate(e.target.value)}
                mt="0"
                py="1rem"
                borderColor="lightgray"
                fontSize=".8rem"
                />
            </Box>
        </Flex>
        </Flex>
        
        <CustomTable 
        headers={tHeaders}
        data={tData}
        setIdx={setIdx}
        triggerClose={triggerClose}
        manage={
          <>
            <Flex mb=".5rem" justifyContent={"flex-end"}>
              {loadingd ?
              <Spinner size="xs" />
              :
                <Text onClick={() => deleteDoc(idx?.id)} fontSize={".9rem"} cursor={"pointer"} textDecoration={"underline"} fontWeight={"500"} color={"crimson"}>Delete</Text>}
            </Flex>
            <CustomInput
            type={"date"}
            required
            placeholder={"Date"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, one: e.target.value})}
            value={idx?.one}
            />
            <CustomSelect 
            placeholder={"Brand"}
            selection={brandx}
            setSelection={setBrandx}
            options={options1}
            mb=".8rem"
            value={idx?.two}
            />
            <CustomInput
            type={"text"}
            required
            placeholder={"SKU"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, three: e.target.value})}
            value={idx?.three}
            />
            <CustomInput
            type={"tel"}
            required
            placeholder={"Value"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, four: e.target.value})}
            value={idx?.four}
            />
           
            <CustomInput
            type={"text"}
            required
            placeholder={"Comment"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, five: e.target.value})}
            value={idx?.five}
            />
            <PrimaryButton mt="2rem" onClick={editOrder} text="Update item" loading={loadingx} />
          </>
        }
        />
        {docs?.length < 1 && <EmptyState text={"No sos data recorded yet."} />}
        {/* <Box mt="1.2rem" borderRadius={"8px"} height={"385px"} backgroundSize={"cover"} backgroundImage={Temp} borderBottom=".6px solid #98A2B3" /> */}
    </Box>
  )
}

export default Sos;