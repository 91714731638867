import { Box, Flex, Heading, Spinner, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons';
import CustomTable from '../../../../components/customTable/customTable';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import CustomInput from '../../../../components/customInput/customInput';
import CustomSelect from '../../../../components/customSelect/customSelect';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../../services/api';
import EmptyState from '../../../../components/emptyState/emptyState';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import LineSkeleton from '../../../../components/lineSkeleton';
import { useSelector } from 'react-redux';
import { rerunOffline, saveOffline } from '../../../../utils/helper';

const Sales = ({data}) => {
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingd, setLoadingd] = useState(false);
  const [brand, setBrand] = useState('');
  const [sku, setSku] = useState('');
  const [units, setUnits] = useState('');
  const [cases, setCases] = useState('');
  const [date, setDate] = useState('');
  const [filterDate, setFilterDate] = useState('');

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const [idx, setIdx] = useState({
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    id: ''
  })

  const [brandx, setBrandx] = useState(idx?.two);
  const [skux, setSkux] = useState(idx?.three);

  const [triggerClose, setTriggerClose] = useState("");

  const prevData = JSON.parse(localStorage.getItem('sales'))
  
    useEffect(() => {
      if(prevData?.length > 0) {
        rerunOffline('sales')
      }
    }, [])

  const state = useLocation()
  const id = state?.pathname?.split('/')[1]
  const [docs, setDocs] = useState([])
  const [refetch, setRefetch] = useState('')
  const toast = useToast()

    const getDocs = async() => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(filterDate ? `/sales?initiativeStore=${id}&date=${filterDate}` : `/sales?initiativeStore=${id}`)
            setDocs(res?.data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDocs()
    }, [refetch, filterDate])

  const tHeaders = ['Date', 'Brand', 'SKU', 'Units', 'Cases']

  const tData = docs?.map(i => ({
    one: i?.date?.slice(0, 10),
    two: i?.brandName,
    three: i?.sku,
    four: i?.unitsSold,
    five: i?.totalCase,
    id: i?._id,
  }))

  const options1 = data?.initiative?.brands?.map(i => ({
    title: i?.name,
    value: i?.name
  }))

  const options2 = data?.initiative?.brands?.filter(i => i?.name === brand)?.map(i => ({
    title: i?.sku,
    value: i?.sku
  }))

  const selectedBrand = data?.initiative?.brands?.find(i => i?.name === brand)

  const addSale = async () => {
    setTriggerClose("");
    setLoadingx(true);
    const datax = {
      date: date?.slice(0, 10),
      brandName: brand,
      sku: sku,
      units: parseInt(units) || 0,
      cases: parseInt(cases) || 0,
      state: data?.store?.state,
      // caseUnitsNumber: selectedBrand?.caseUnitsNumber,
      // pricePerCase: selectedBrand?.pricePerCase
    };
    try {
      const res = await axiosInstance.post(
        `initiative-stores/${id}/sales`,
        datax
      );
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      const prevData = JSON.parse(localStorage.getItem('sales'))
            if (!error.response) {
              toast({
                title: "Network Timeout",
                description: "Your internet may be unconnected or very slow, this entry has been saved offline and would be auto-submitted when your internet comes back on.",
                status: "error",
                position: "top"
              });
              saveOffline('sales', JSON.stringify([...prevData, {uid: Math.random().toString(36), ...datax}]))
            } else {
              toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
              });
            }
    } finally {
      setLoadingx(false);
    }
  };

  const editInventory = async () => {
    setTriggerClose("");
    setLoadingx(true);
    const datax = {
      date: idx?.one?.slice(0, 10),
      brandName: idx?.two,
      sku: idx?.three,
      unitsSold: parseInt(idx?.four),
      cases: parseInt(idx?.five),
      state: data?.store?.state,
      // caseUnitsNumber: data?.initiative?.brands?.find(i => i?.name === idx?.two)?.caseUnitsNumber,
      // pricePerCase: data?.initiative?.brands?.find(i => i?.name === idx?.two)?.pricePerCase
    };
    try {
      const res = await axiosInstance.patch(
        `sales/${idx?.id}`,
        datax
      );
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const uniqueBrandsList = options1?.filter((item, index, array) => {
    return (
      index === array.findIndex(obj => obj.title === item.title && obj.value === item.value)
    );
  });

  const deleteDoc = async(id) => {
    setLoadingd(true)
    try {
      const {data} = await axiosInstance.delete(`/sales/${id}`)
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingd(false)
    }
  }

  return (
    <Box>
        <Flex mt="1rem" mb="1.2rem" alignItems={"center"} justifyContent={"space-between"}>
            <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Sales</Heading>
            {currentUser?.role === "Promoter" && <CustomDrawer 
              position={"bottom"} 
              title={"Add Sale"} 
              triggerClose={triggerClose}
              toggleElement={
                <Text fontSize={"12px"} padding={".4rem .5rem"} cursor={"pointer"} color={"#667085"} fontWeight={"600"} border=".8px solid #D0D5DD" borderRadius={"8px"}>Add Sale<AddIcon transform={"translateY(-1px) scale(.9)"} ml=".3rem" /></Text>
              }
              content={
                <>
                  <CustomSelect 
                  placeholder={"Brand"}
                  selection={brand}
                  setSelection={setBrand}
                  options={uniqueBrandsList}
                  mb=".8rem"
                  />
                  <CustomSelect 
                  placeholder={"SKU"}
                  selection={sku}
                  setSelection={setSku}
                  options={options2}
                  mb=".8rem"
                  />
                  <Flex gap="1rem" alignItems={"center"}>
                  <CustomInput
                  type={"tel"}
                  required
                  placeholder={"Units sold"}
                  mb={".8rem"}
                  onChange={e => setUnits(parseInt(e.target.value))}
                  />
                  <CustomInput
                  type={"tel"}
                  required
                  placeholder={"Cases sold"}
                  mb={".8rem"}
                  onChange={e => setCases(parseInt(e.target.value))}
                  />
                  </Flex>
                  <Box padding=".4rem" borderRadius={"4px"} bg={"#E0E0E0"} mb=".8rem">
                    <Text fontWeight={"700"} fontSize={".9rem"}>Note</Text>
                    <Text fontWeight={"500"} fontSize={".85rem"}>1. If you sold only units, add in under UNITS only</Text>
                    <Text fontWeight={"500"} fontSize={".85rem"}>2. If you sold only cases/cartons, add under CASES only</Text>
                    <Text fontWeight={"500"} fontSize={".85rem"}>3. If you sold both cases/carton and some extra units (add the cases amount and units amount under cases and units respectively)</Text>
                  </Box>
                  
                  <CustomInput
                  type={"date"}
                  required
                  placeholder={"Date"}
                  mb={".8rem"}
                  onChange={e => setDate(e.target.value)}
                  />
                  <PrimaryButton mt="2rem" onClick={addSale} text="Save item" loading={loadingx} />
                </>
              }
            />}
        </Flex>

        <Flex mb="1.2rem" alignItems={"center"} justifyContent={"flex-end"}>
        <Flex gap="1rem" alignItems={"center"}>
            {filterDate !== "" && <Text onClick={() => setFilterDate("")} fontSize={".9rem"} cursor={"pointer"} fontWeight={"500"} color="#7F56D9">Show all dates</Text>}
            <Box width={"150px"}>
                <CustomInput
                type={"date"}
                required
                placeholder={"Select Date"}
                value={filterDate}
                onChange={e => setFilterDate(e.target.value)}
                mt="0"
                py="1rem"
                borderColor="lightgray"
                fontSize=".8rem"
                />
            </Box>
        </Flex>
        </Flex>

        {loading ?
        <LineSkeleton num={8} />
        :
        docs?.length < 1 ? <EmptyState text={"No sales recorded yet."} />
        :
        <>
        <CustomTable 
        headers={tHeaders}
        data={tData}
        setIdx={setIdx}
        triggerClose={triggerClose}
        manage={
          <>
            <Flex mb=".5rem" justifyContent={"flex-end"}>
              {loadingd ?
              <Spinner size="xs" />
              :
                <Text onClick={() => deleteDoc(idx?.id)} fontSize={".9rem"} cursor={"pointer"} textDecoration={"underline"} fontWeight={"500"} color={"crimson"}>Delete</Text>}
            </Flex>
            <CustomSelect 
            placeholder={"Brand"}
            selection={brand}
            setSelection={setBrandx}
            options={uniqueBrandsList}
            mb=".8rem"
            value={idx?.two}
            />
            <CustomSelect 
            placeholder={"SKU"}
            selection={sku}
            setSelection={setSkux}
            options={options2}
            mb=".8rem"
            value={idx?.three}
            />
            <Flex gap="1rem" alignItems={"center"}>
            <CustomInput
            type={"tel"}
            required
            placeholder={"Units sold"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, four: e.target.value})}
            value={idx?.four}
            />
            <CustomInput
            type={"tel"}
            required
            placeholder={"Cases"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, five: e.target.value})}
            value={idx?.five}
            />
            </Flex>
            <CustomInput
            type={"date"}
            required
            placeholder={"Date"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, one: e.target.value})}
            value={idx?.one}
            />
            {/* <PrimaryButton mt="2rem" onClick={editInventory} text="Update item" loading={loadingx} /> */}
          </>
        }
        />
        </>}
    </Box>
  )
}

export default Sales;