import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import Home from './icons/home.png'
import Home2 from './icons/homeFilled.png'
import Attendance from './icons/ranking.png'
import Attendance2 from './icons/rankingFilled.png'
import Sales from './icons/money-send.png'
import Sales2 from './icons/money-send-filled.png'
import Tasks from './icons/note.png'
import Tasks2 from './icons/note-text.png'
import Messages from './icons/message.png'
import Messages2 from './icons/messageFilled.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const MenuBar = () => {
    const state = useLocation()
    const navigate = useNavigate()
    const id = state?.pathname?.split('/')[1]


    const menuData = [
        {
            title: "Home",
            activeIcon: Home2,
            icon: Home,
            link: `/${id}/dashboard`,
            tabIndex: 1
        },
        {
            title: "Attendance",
            activeIcon: Attendance2,
            icon: Attendance,
            link: `/${id}/attendance`,
            tabIndex: 2
        },
        {
            title: "Sales",
            activeIcon: Sales2,
            icon: Sales,
            link: `${id}/dashboard/initiative`,
            tabIndex: 3
        },
        {
            title: "Tasks",
            activeIcon: Tasks2,
            icon: Tasks,
            link: `${id}/tasks`,
            tabIndex: 4
        },
        {
            title: "Messages",
            activeIcon: Messages2,
            icon: Messages,
            link: `${id}/messages`,
            tabIndex: 5
        }
        // {
        //     title: "Messages",
        //     activeIcon: Messages2,
        //     icon: Messages,
        //     link: `${id}/messages`,
        //     tabIndex: 5
        // }
    ]

  return (
    <Flex height={"90px"} borderTop={"1px solid #D0D5DD"} bg="#FFF" position={"fixed"} bottom={"0"} zIndex={"20"} padding={"1rem .5rem"} width={['100%', '480px']} justifyContent={"space-between"} alignItems={"center"}>
        {menuData?.map(({title, activeIcon, icon, link, tabIndex}) => {
            return (
                <Box onClick={() => navigate(link, {state: {index: tabIndex}})} key={title} cursor={"pointer"} textAlign={"center"}>
                    {state?.pathname?.split('/')?.slice(-1)[0] === link?.split('/')?.slice(-1)[0] ?
                    <Image src={activeIcon} width={"30%"} mx="auto" />
                    :
                    <Image src={icon} width={"30%"} mx="auto" />}
                    <Text mt=".3rem" fontSize={"12px"} color={state?.pathname?.split('/')?.slice(-1)[0] === link?.split('/')?.slice(-1)[0] ? "#344054" : "#D0D5DD"} textAlign={"center"}>{title}</Text>
                </Box>
                )
            })}
    </Flex>
  )
}

export default MenuBar