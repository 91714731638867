import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import CustomTextArea from '../../../../components/customTextArea/customTextArea'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import { AddIcon } from '@chakra-ui/icons'
import ImageUploader from '../../../../components/imageUploader/imageUploader'
import SpinLoader from '../../../../components/spinLoader/spinLoader'
import moment from 'moment'
import CustomInput from '../../../../components/customInput/customInput'
import axiosInstance from '../../../../services/api'

const AllTasks = ({tasks, loading, setLoading, sid, loadingu, setLoadingu, setRefetch}) => {
    const [triggerClose, setTriggerClose] = useState('')
    const [uploadedImages, setUploadedImages] = useState([]);
    const [comment, setComment] = useState("")
    const [loadingc, setLoadingc] = useState(false)
    const [idx, setIdx] = useState('')

    const toast = useToast()

    const handleImagesUpload = (images) => {
        setUploadedImages(images);
      };

      const completeTask = async() => {
        setLoadingc(true)
        const datax = {
            initiativeStoreId: sid,
            comment,
            uploadURLs: uploadedImages,
          }
        try {
            const {data} = await axiosInstance.patch(`/tasks/${idx}/complete`, datax)
            toast({
                description: data?.message,
                status: "success",
              });
            setTriggerClose('close')
            setRefetch(Math.random())
        } catch (error) {
            console.error(error?.response?.data?.message)
            // toast({
            // title: "An Error Occurred",
            // description: error?.response?.data?.message,
            // status: "error",
            // });
        } finally {
            setLoadingc(false)
        }
      }

  return (
    <>
    
        <Box mt="1.2rem">
            {tasks?.map(({assignedInitiativeStores, comment, name, dueDate, dueTime, _id: id}) => {
                const reply = assignedInitiativeStores?.filter(i => i?.initiativeStore === sid)[0]?.reply
                return (
                    <>
                    {reply !== null ?
                    <Box key={id} onClick={() => setIdx(id)} cursor={"pointer"} mb=".7rem" border="1px solid #D0D5DD" p="1rem" borderRadius={"8px"}>
                        <Text fontWeight={"500"} color="#667085" textTransform={"capitalize"}>{name}</Text>
                        <Text opacity={".7"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{comment}</Text>

                        {reply !== null && 
                        <Flex my="1rem" alignItems={"start"} border="1px solid #D0D5DD" gap="1rem" p="1rem" borderRadius={"8px"}>
                            <Flex alignItems={"center"} gap={".7rem"}>
                                {reply?.uploadURLs?.map(i => (
                                    <a key={i} href={i} target="_blank" rel="noopener noreferrer">
                                    <Box height={"40px"} cursor={"pointer"} width={"50px"} borderRadius={"4px"} bg="lightgray" backgroundImage={i} backgroundSize={"cover"}>

                                    </Box></a>
                                ))}
                            </Flex>
                            <Text opacity={".7"} transform={"translateY(-6px)"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{reply?.comment}</Text>
                        </Flex>}

                        <Flex alignItems={"center"} justifyContent={"space-between"} mt={reply === null ? "1rem" : ""}>
                            <Text fontSize={"12px"} width={"90px"} textAlign={"center"} whiteSpace={"nowrap"} fontWeight={"500"} textTransform={"capitalize"} borderRadius={"20px"} padding={".2rem 1rem"} bg={reply === null ? "#FFE3B0" : "#E5FFE4"} color={reply === null ? "#EC913C" : "#44A234"}>{reply === null ? "Pending" : "Completed"}</Text>
                            {reply !== null ? 
                            <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>{moment(reply?.date).calendar()}</Text>
                            :
                            <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>Due: {dueDate} by {dueTime}</Text>
                            }
                        </Flex>
                    </Box>
                    :
                    <CustomDrawer 
                    position={"bottom"}
                    title={""}
                    triggerClose={triggerClose}
                    toggleElement={
                        <Box onClick={() => setIdx(id)} cursor={"pointer"} mb=".7rem" border="1px solid #D0D5DD" p="1rem" borderRadius={"8px"}>
                            <Text fontWeight={"500"} color="#667085" textTransform={"capitalize"}>{name}</Text>
                            <Text opacity={".7"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{comment}</Text>

                            {reply !== null && 
                            <Flex my="1rem" alignItems={"start"} border="1px solid #D0D5DD" gap="1rem" p="1rem" borderRadius={"8px"}>
                                <Flex alignItems={"center"} gap={".7rem"}>
                                    {reply?.uploadURLs?.map(i => (
                                        <a key={i} href={i} target="_blank" rel="noopener noreferrer">
                                        <Box height={"40px"} cursor={"pointer"} width={"50px"} borderRadius={"4px"} bg="lightgray" backgroundImage={i} backgroundSize={"cover"}>

                                        </Box></a>
                                    ))}
                                </Flex>
                                <Text opacity={".7"} transform={"translateY(-6px)"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{reply?.comment}</Text>
                            </Flex>}

                            <Flex alignItems={"center"} justifyContent={"space-between"} mt={reply === null ? "1rem" : ""}>
                                <Text fontSize={"12px"} width={"90px"} textAlign={"center"} whiteSpace={"nowrap"} fontWeight={"500"} textTransform={"capitalize"} borderRadius={"20px"} padding={".2rem 1rem"} bg={reply === null ? "#FFE3B0" : "#E5FFE4"} color={reply === null ? "#EC913C" : "#44A234"}>{reply === null ? "Pending" : "Completed"}</Text>
                                {reply !== null ? 
                                <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>{moment(reply?.date).calendar()}</Text>
                                :
                                <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>Due: {dueDate} by {dueTime}</Text>
                                }
                            </Flex>
                        </Box>
                    }
                    content={
                        <>
                            <Box cursor={"pointer"} mb=".7rem" border="1px solid #D0D5DD" p="1rem" borderRadius={"8px"}>
                                <Text fontWeight={"500"} color="#667085" textTransform={"capitalize"}>{name}</Text>
                                <Text opacity={".7"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{comment}</Text>

                                {reply !== null && 
                                <Flex my="1rem" alignItems={"start"} border="1px solid #D0D5DD" gap="1rem" p="1rem" borderRadius={"8px"}>
                                    <Flex alignItems={"center"} gap={".7rem"}>
                                        {reply?.uploadURLs?.map(i => (
                                            <a key={i} href={i} target="_blank" rel="noopener noreferrer">
                                            <Box height={"40px"} cursor={"pointer"} width={"50px"} borderRadius={"4px"} bg="lightgray" backgroundImage={i} backgroundSize={"cover"}>

                                            </Box></a>
                                        ))}
                                    </Flex>
                                    <Text opacity={".7"} transform={"translateY(-6px)"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{reply?.comment}</Text>
                                </Flex>}

                                <Flex alignItems={"center"} justifyContent={"space-between"} mt={reply === null ? "1rem" : ""}>
                                    <Text fontSize={"12px"} width={"90px"} textAlign={"center"} whiteSpace={"nowrap"} fontWeight={"500"} textTransform={"capitalize"} borderRadius={"20px"} padding={".2rem 1rem"} bg={reply === null ? "#FFE3B0" : "#E5FFE4"} color={reply === null ? "#EC913C" : "#44A234"}>{reply === null ? "Pending" : "Completed"}</Text>
                                    {reply !== null ? 
                                    <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>{moment(reply?.date).calendar()}</Text>
                                    :
                                    <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>Due: {dueDate} by {dueTime}</Text>
                                    }
                                </Flex>
                            </Box>

                            <CustomInput
                            type={"text"}
                            required
                            placeholder={"Comment"}
                            mb={".8rem"}
                            onChange={e => setComment(e.target.value)}
                            />
                            <Box mb=".8rem">
                            <ImageUploader
                                onImagesUpload={handleImagesUpload}
                                setLoading={setLoadingu}
                                loading={loadingu}
                                max={3}
                            />
                            <Flex gap=".5rem" mt=".7rem">
                                {uploadedImages.map((imageUrl, index) => (
                                <Box
                                    backgroundImage={imageUrl}
                                    backgroundSize={"contain"}
                                    height={"37px"}
                                    width={"40px"}
                                    borderRadius={"4px"}
                                    backgroundColor={"lightgray"}
                                />
                                ))}
                            </Flex>
                            </Box>
                            <PrimaryButton mt="1rem" onClick={completeTask} text="Complete task" loading={loadingc} />
                        </>
                        
                    }
                    />}
                    </>
            )})}
        </Box>
    </>
  )
}

export default AllTasks