import { Box, Flex, Heading, Spinner, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons';
import CustomTable from '../../../../components/customTable/customTable';
import CustomDrawer from '../../../../components/customDrawer/customDrawer';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import CustomInput from '../../../../components/customInput/customInput';
import CustomSelect from '../../../../components/customSelect/customSelect';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../../services/api';
import EmptyState from '../../../../components/emptyState/emptyState';
import { BiDotsVerticalRounded } from 'react-icons/bi'
import ImageUploader from '../../../../components/imageUploader/imageUploader';
import { useSelector } from 'react-redux';

const Competitors = ({data}) => {
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingu, setLoadingu] = useState(false);
  const [loadingd, setLoadingd] = useState(false);
  const [brand, setBrand] = useState('');
  const [sku, setSku] = useState('');
  const [price, setPrice] = useState('');
  const [isPromoOn, setIsPromoOn] = useState('');
  const [imageURL, setImageURL] = useState([]);

  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  
  const [comment, setComment] = useState('');
  const [date, setDate] = useState('');
  const [filterDate, setFilterDate] = useState('');

  const [idx, setIdx] = useState({
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    six: '',
    seven: '',
    id: ''
  })

  const [brandx, setBrandx] = useState(idx?.two);
  const [isPromoOnx, setIsPromoOnx] = useState(idx?.five);
  const [skux, setSkux] = useState(idx?.three);
  const [imageURLx, setImageURLx] = useState(idx?.seven);

  const [triggerClose, setTriggerClose] = useState("");

  const state = useLocation()
  const id = state?.pathname?.split('/')[1]
  const [docs, setDocs] = useState([])
  const [refetch, setRefetch] = useState('')
  const toast = useToast()

    const getDocs = async() => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(filterDate ? `/competitors?initiativeStore=${id}&date=${filterDate}` : `/competitors?initiativeStore=${id}`)
            setDocs(res?.data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDocs()
    }, [refetch, filterDate])

    const handleImagesUpload = (images) => {
      setImageURL(images);
    };

  const tHeaders = ['Date', 'Brand', 'SKU', 'Price', 'Status']

  const tData = docs?.map(i => ({
    one: i?.date?.slice(0, 10),
    two: i?.brandName,
    three: i?.sku,
    four: i?.price,
    five: i?.isPromoOn ? "In Promo" : "None",
    six: i?.comment,
    seven: i?.imageURL,
    id: i?._id,
  }))

  const options1 = data?.initiative?.competitors?.map(i => ({
    title: i?.brandName,
    value: i?.brandName
  }))

  const options2 = data?.initiative?.competitors?.filter(i => i?.brandName === brand)?.map(i => ({
    title: i?.sku,
    value: i?.sku
  }))

  const addCompetitor = async () => {
    setTriggerClose("");
    setLoadingx(true);
    const datax = {
      date: date?.slice(0, 10),
      brandName: brand,
      sku: sku,
      price: price,
      isPromoOn: isPromoOn === "true" ? true : false,
      imageURL: imageURL[0],
      comment,
      state: data?.store?.state
    };
    try {
      const res = await axiosInstance.post(
        `initiative-stores/${id}/competitors`,
        datax
      );
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const editCompetitor = async () => {
    setTriggerClose("");
    setLoadingx(true);
    const datax = {
      date: idx?.one?.slice(0, 10),
      brandName: idx?.two,
      sku: idx?.three,
      price: parseInt(idx?.four),
      isPromoOn: idx?.five === "In Promo" ? true : false,
      imageURL: imageURL[0],
      comment: idx?.six,
      state: data?.store?.state
    };
    try {
      const res = await axiosInstance.patch(
        `competitors/${idx?.id}`,
        datax
      );
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const uniqueBrandsList = options1?.filter((item, index, array) => {
    return (
      index === array.findIndex(obj => obj.title === item.title && obj.value === item.value)
    );
  });

  const deleteDoc = async(id) => {
    setLoadingd(true)
    try {
      const {data} = await axiosInstance.delete(`/competitors/${id}`)
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingd(false)
    }
  }

  return (
    <Box>
        <Flex mt="1rem" mb="1.2rem" alignItems={"center"} justifyContent={"space-between"}>
            <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Competitors</Heading>
            {currentUser?.role === "Promoter" && <CustomDrawer 
              position={"bottom"} 
              title={"Add Competitor"} 
              triggerClose={triggerClose}
              toggleElement={
                <Text fontSize={"12px"} padding={".4rem .5rem"} cursor={"pointer"} color={"#667085"} fontWeight={"600"} border=".8px solid #D0D5DD" borderRadius={"8px"}>Add Competitor<AddIcon transform={"translateY(-1px) scale(.9)"} ml=".3rem" /></Text>
              }
              content={
                <>
                  <CustomInput
                  type={"date"}
                  required
                  placeholder={"Date"}
                  mb={".8rem"}
                  onChange={e => setDate(e.target.value)}
                  />
                  

                  <CustomSelect
                  placeholder={"Brand"}
                  selection={brand}
                  setSelection={setBrand}
                  options={uniqueBrandsList}
                  mb=".8rem"
                  type={"pc"}
                  />
                  {(brand === "" || uniqueBrandsList?.map(i => i.value).includes(brand)) &&
                    <CustomSelect 
                  placeholder={"SKU"}
                  selection={sku}
                  setSelection={setSku}
                  options={options2}
                  mb=".8rem"
                  />}

                  {(brand !== "" && !uniqueBrandsList?.map(i => i.value).includes(brand)) &&
                  <>
                  <CustomInput
                  type={"text"}
                  required
                  placeholder={"Brand"}
                  mb={".8rem"}
                  onChange={e => setBrand(e.target.value)}
                  />
                  <CustomInput
                  type={"text"}
                  required
                  placeholder={"SKU"}
                  mb={".8rem"}
                  onChange={e => setSku(e.target.value)}
                  />
                  </>
                  }




                  <CustomInput
                  type={"tel"}
                  required
                  placeholder={"Price"}
                  mb={".8rem"}
                  onChange={e => setPrice(parseInt(e.target.value))}
                  />
                  <CustomSelect 
                  placeholder={"In Promo?"}
                  selection={isPromoOn}
                  setSelection={setIsPromoOn}
                  options={[{title: 'Yes', value: true}, {title: 'No', value: false}]}
                  mb=".8rem"
                  />
                  <Flex gap=".5rem" mt=".7rem" mb=".8rem" alignItems={"center"}>
                  
                    {imageURL?.map((imageUrl, index) => (
                      <Box
                        backgroundImage={imageUrl}
                        backgroundSize={"contain"}
                        height={"70px"}
                        width={"100px"}
                        borderRadius={"4px"}
                        backgroundColor={"lightgray"}
                      />
                    
                    ))}
                    <ImageUploader
                    onImagesUpload={handleImagesUpload}
                    setLoading={setLoadingu}
                    loading={loadingu}
                    max={1}
                  />
                  </Flex>
                  <CustomInput
                  type={"text"}
                  required
                  placeholder={"Comment"}
                  mb={".8rem"}
                  onChange={e => setComment(e.target.value)}
                  />
                  <PrimaryButton mt="2rem" onClick={addCompetitor} text="Add Competitor" loading={loadingx} />
                </>
              }
            />}
        </Flex>

        <Flex mb="1.2rem" alignItems={"center"} justifyContent={"flex-end"}>
        <Flex gap="1rem" alignItems={"center"}>
            {filterDate !== "" && <Text onClick={() => setFilterDate("")} fontSize={".9rem"} cursor={"pointer"} fontWeight={"500"} color="#7F56D9">Show all dates</Text>}
            <Box width={"150px"}>
                <CustomInput
                type={"date"}
                required
                placeholder={"Select Date"}
                value={filterDate}
                onChange={e => setFilterDate(e.target.value)}
                mt="0"
                py="1rem"
                borderColor="lightgray"
                fontSize=".8rem"
                />
            </Box>
        </Flex>
        </Flex>
        
        <CustomTable 
        headers={tHeaders}
        data={tData}
        setIdx={setIdx}
        triggerClose={triggerClose}
        manage={
          <>
            <Flex mb=".5rem" justifyContent={"flex-end"}>
              {loadingd ?
              <Spinner size="xs" />
              :
                <Text onClick={() => deleteDoc(idx?.id)} fontSize={".9rem"} cursor={"pointer"} textDecoration={"underline"} fontWeight={"500"} color={"crimson"}>Delete</Text>}
            </Flex>
            <CustomInput
            type={"date"}
            required
            placeholder={"Date"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, one: e.target.value})}
            value={idx?.one}
            />
            <CustomSelect 
            placeholder={"Brand"}
            selection={brand}
            setSelection={setBrandx}
            options={options1}
            mb=".8rem"
            value={idx?.two}
            />
            <CustomInput
            type={"text"}
            required
            placeholder={"SKU"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, three: e.target.value})}
            value={idx?.three}
            />
            <CustomInput
            type={"tel"}
            required
            placeholder={"Price"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, four: e.target.value})}
            value={idx?.four}
            />
            <CustomSelect 
            placeholder={"In Promo"}
            selection={isPromoOn}
            setSelection={setIsPromoOnx}
            options={[{title: 'Yes', value: true}, {title: 'No', value: false}]}
            mb=".8rem"
            value={idx?.five}
            />
            <Flex gap=".5rem" mt=".7rem" mb=".8rem" alignItems={"center"}>
            
                <Box
                  backgroundImage={imageURL[0] || idx?.seven}
                  backgroundSize={"contain"}
                  height={"70px"}
                  width={"100px"}
                  borderRadius={"4px"}
                />
              <ImageUploader
              onImagesUpload={handleImagesUpload}
              setLoading={setLoadingu}
              loading={loadingu}
              max={1}
            />
            </Flex>
            <CustomInput
            type={"text"}
            required
            placeholder={"Comment"}
            mb={".8rem"}
            onChange={e => setIdx({...idx, six: e.target.value})}
            value={idx?.six}
            />
            <PrimaryButton mt="2rem" onClick={editCompetitor} text="Update item" loading={loadingx} />
          </>
        }
        />
        {docs?.length < 1 && <EmptyState text={"No competitors recorded yet."} />}
        {/* <Box mt="1.2rem" borderRadius={"8px"} height={"385px"} backgroundSize={"cover"} backgroundImage={Temp} borderBottom=".6px solid #98A2B3" /> */}
    </Box>
  )
}

export default Competitors;