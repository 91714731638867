/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import { Box, Flex, Spinner, useToast } from '@chakra-ui/react';
import CustomInput from '../../../components/customInput/customInput';
import CustomSelect from '../../../components/customSelect/customSelect';
import statesLgaJson from '../../../utils/stateLGAs';
import axiosInstance from '../../../services/api';
import CustomTextArea from '../../../components/customTextArea/customTextArea';
import ImageUploaderRaw from '../../../components/imageUploader/imageUploaderRaw';
import axios from 'axios';
import classes from './createdAssets.module.css'

const places = statesLgaJson?.map(i => ({
  title: i?.state,
  value: i?.state,
  listValue: i?.lgas?.map(i => ({
    title: i,
    value: i,
  }))
}))

const url = process.env.REACT_APP_BASE_URL;

const CreateAssets = ({ setTriggerClose, setRefetch, isEdit, idx, idData }) => {
  console.log(idx, idData)
  const [streetName, setStreetName] = useState(idData?.addressDetails?.streetName);
  const [storeType, setStoreType] = useState(idData?.category);
  const [storeLevel, setStoreLevel] = useState(idData?.type);
  const [streetNumber, setStreetNumber] = useState(idData?.addressDetails?.buildingNo);
  const [coordinates, setCoordinates] = useState(null);
  const [category, setCategory] = useState(idData?.brand);
  const [imageURL, setImageURL] = useState(idData?.image);
  const [images, setImages] = useState("");
  const [state, setState] = useState(idData?.addressDetails?.state);
  const [area, setArea] = useState(idData?.addressDetails?.LGA);
  const [town, setTown] = useState(idData?.addressDetails?.city);
  const [country, setCountry] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState(idData?.additionalInfo);

  const [client, setClient] = useState(idData?.client);
  const [clients, setClients] = useState([]);
  const [loadingx, setLoadingx] = useState(false);
  const [loading, setLoading] = useState('');
  const [loadingu, setLoadingu] = useState('');
  const [loadingv, setLoadingv] = useState('');

  const [names, setNames] = useState([]);


  // const [purchaseWillingness, setPurchaseWillingness] = useState("")
  // const [buyingPreferences, setBuyingPreferences] = useState([])

  const toast = useToast();

  const defaultState = { client: '', brand: '', category: '', type: '', image: null, additionalInfo: '', buildingNo: '', streetName: '', city: '', LGA: '', state: '' };

  const [values, setValues] = useState(defaultState);
  const [creatingAsset, setCreatingAsset] = useState(false);



  const fetchUserLocation = async () => {
    if ('geolocation' in navigator) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          (error) => {
            console.error('Error fetching user location:', error);
            reject(error);
          }
        );
      });
    } else {
      console.error('Geolocation is not available in this browser.');
      throw new Error('Geolocation is not available');
    }
  };



  const getCategories = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(`/assets/fetch/pre-name?client=${values?.client}`)
      setNames(data?.data?.names)
      console.log(data?.data?.names)
    } catch (error) {
      console.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (values?.client) {
      getCategories()
    }
  }, [values?.client])



  const getClients = async () => {
    setLoadingx(true)
    try {
      const { data } = await axiosInstance.get('/assets/field/retrieve')
      setClients(data?.data?.organizations)
    } catch (error) {
      console.error(error?.response?.data?.message)
    } finally {
      setLoadingx(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const handleImagesUpload = (images) => {
    setImageURL(images?.urls[0])
    setImages(images?.raw[0])
    console.log(images)
  };

  // const createStore = async (e) => {
  //   e.preventDefault();
  //   setLoadingv(true)

  //   const formData = new FormData();


  //   formData.append('brand', category)
  //   formData.append('category', storeType)
  //   formData.append('type', storeLevel)
  //   formData.append('additionalInfo', additionalInfo)
  //   formData.append('buildingNo', streetNumber)
  //   formData.append('streetName', streetName)
  //   formData.append('city', town)
  //   formData.append('LGA', area)
  //   formData.append('state', state)


  //   if (!isEdit) {
  //     formData.append('longitude', coordinates?.longitude)
  //     formData.append('latitude', coordinates?.latitude)
  //     formData.append('client', client)
  //     formData.append('image', images)
  //   }




  //   try {
  //     const { data } = isEdit ? await axiosInstance.patch(`/assets/edit/${idx}`, formData) : await axiosInstance.post('/assets/field/register', formData)
  //     toast({
  //       description: data?.message,
  //       status: "success",
  //       position: 'top'
  //     });
  //     setTriggerClose('close' + (Math.random()))
  //     setRefetch(Math.random());
  //   } catch (error) {
  //     toast({
  //       title: "An Error Occurred",
  //       description: error?.response?.data?.message,
  //       status: "error",
  //     });
  //   } finally {
  //     setLoadingv(false)
  //   }
  // }



  const clientOptions = clients?.map(i => ({
    title: i?.client?.companyName,
    value: i?.client?._id
  }))

  const countries = [
    {
      title: "Nigeria",
      value: "nigeria"
    }
  ]

  const teamOptions = [];

  for (let i = 0; i < 100; i++) {
    const newObj = {
      title: `Team ${i + 1}`,
      value: i + 1,
    };
    teamOptions.push(newObj);
  }

  // const arr1 = categoriesx?.find(i => i?.value === category)
  // const arr2 = arr1?.listValue?.find(i => i?.value === storeType)

  // const sourceOpts = [
  //   {
  //     title: "Open Market",
  //     value: "open market"
  //   },
  //   {
  //     title: "Wholesaler near me",
  //     value: "wholesaler near me"
  //   },
  //   {
  //     title: "Direct from the distributor",
  //     value: "direct from the distributor"
  //   },
  //   {
  //     title: "Company supplies me directly in my outlet",
  //     value: "company supplies me directly in my outlet"
  //   },
  //   {
  //     title: "Not applicable",
  //     value: "not applicable"
  //   },
  // ]

  // const products = [
  //   'Raw Foodstuff',
  //   'Packaged Company Products',
  //   'Fruits & Vegetables',
  //   'Meat & Frozen Food'
  // ]

  // const handleStateChange = (e) => {
  //   const stateName = e.target.value;
  //   const isChecked = e.target.checked;
  //   if (isChecked) {
  //     setBuyingPreferences([...buyingPreferences, stateName])
  //   } else {

  //   }
  // };



  const fileInputRefs = useRef({});
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);

  const [showCamera, setShowCamera] = useState(false);


  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setValues((prev) => ({
  //       ...prev,
  //       [e.target.name]: { localUrl: URL.createObjectURL(file), file },
  //     }));
  //   }
  // };



  const create = async () => {
    setCreatingAsset(true);

    const location = await fetchUserLocation()

    const { latitude, longitude } = location

    const config = {
      headers: {
        authorization: `Bearer ${localStorage.getItem('PRV1TK')}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('image', values.image);
    formData.append('brand', values.brand);
    formData.append('category', values.category);
    formData.append('type', values.type);
    if (!isEdit) {
      formData.append('longitude', longitude)
      formData.append('latitude', latitude)
    }
    formData.append('client', values.client)
    formData.append('buildingNo', values.buildingNo)
    formData.append('streetName', values.streetName)
    formData.append('city', values.city)
    formData.append('LGA', values.LGA)
    formData.append('state', values.state)


    try {
      if (isEdit) {
        await axios.patch(`${url}/assets/edit/${idx}`, formData, config);
      } else {
        await axios.post(`${url}/assets/field/register`, formData, config);
      }

      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random());
      setValues(defaultState);
      toast({
        description: 'Registration submitted for review',
        status: "success",
        position: 'top'
      });
    } catch (error) {
      const message = error?.response?.data?.message || 'Something went wrong';
      toast({
        title: "An Error Occurred",
        description: message,
        status: "error",
      });
    } finally {
      setCreatingAsset(false);
    }
  };


  useEffect(() => {
    const getCameraStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
        await videoRef.current.play();
      } catch (err) {
        console.error('Error accessing camera: ', err);
      }
    };

    if (showCamera) {
      getCameraStream();
    } else {
      stopStream();
    }

    return () => stopStream();
  }, [showCamera]);


  const pickLiveImageFile = (name, file) => {
    setValues((prev) => ({
      ...prev,
      [name]: file
    }));
    setShowCamera(false);
    stopStream(); // Stop the stream after taking the picture
  };

  const stopStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  };


  const takePicture = (name) => {
    const width = videoRef.current.videoWidth;
    const height = videoRef.current.videoHeight;

    canvasRef.current.width = width;
    canvasRef.current.height = height;

    const ctx = canvasRef.current.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0, width, height);

    setHasPhoto(true);

    canvasRef.current.toBlob(blob => {
      const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
      pickLiveImageFile(name, file);
    }, 'image/jpeg');
  };

  const clearPhoto = () => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setHasPhoto(false);
  };

  useEffect(() => {
    if (showCamera) {
      const constraints = {
        video: {
          facingMode: { exact: "environment" } // "environment" for back camera
        }
      };

      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        })
        .catch(err => {
          console.error("Error accessing camera: ", err);
          // Handle errors, e.g., fallback to front camera or show an error message
        });
    }
  }, [showCamera]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value })
    setValues((prev) => ({
      ...prev,
      [name]: value
    }));
  }

  const selectedBrandObject = (values?.brand && names?.find((item) => (
    item.brand === values?.brand
  ))) || null

  const selectedCategoryObject = (values?.category && selectedBrandObject?.categories?.find(item => (
    item.category === values?.category
  ))) || null





  return (
    <div className={classes.bigContainer}>
      {
        loadingx ?
          <div className={classes.loader_con}>
            <Spinner />
          </div>
          :
          <div className={classes.formContainer}>

            <div className={classes.inputContainerCon}>
              <label className={classes.label}>Select a client</label>
              <div className={classes.inputContainer}>
                <select className={classes.select} name='client' onChange={handleChange} >
                  <option value={''}>
                    Organization*
                  </option>

                  {
                    clientOptions?.map((item, index) => (
                      <option key={index} value={item.value}>{item?.title}</option>
                    ))
                  }

                </select>
              </div>
            </div>


            {
              names &&
              <div className={classes.inputContainerCon}>
                <label className={classes.label}>Select a brand</label>
                <div className={classes.inputContainer}>
                  <select className={classes.select} name="brand" value={values.brand} onChange={handleChange} >
                    <option value={''}>
                      Brand*
                    </option>
                    {
                      names?.map((item, index) => (
                        <option key={index}>{item?.brand}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            }



            {
              selectedBrandObject &&

              <div className={classes.inputContainerCon}>
                <label className={classes.label}>Select a category</label>
                <div className={classes.inputContainer}>
                  <select className={classes.select} name="category" value={values.category} onChange={handleChange} >
                    <option value={''}>
                      Category*
                    </option>
                    {
                      selectedBrandObject?.categories?.sort((a, b) => a.category.localeCompare(b.category))?.map((item, index) => (
                        <option key={index}>{item?.category}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

            }


            {
              selectedCategoryObject &&
              <div className={classes.inputContainerCon}>
                <label className={classes.label}>Select a type</label>
                <div className={classes.inputContainer}>
                  <select className={classes.select} name="type" value={values.type} onChange={handleChange} >
                    <option value={''}>
                      Type (optional)
                    </option>
                    {
                      selectedCategoryObject?.types?.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

            }

            <div className={classes.inputContainerCon}>
              <label className={classes.label}>Building Number </label>
              <div className={classes.inputContainer}>
                <input
                  type='number'
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className={classes.select}
                  name='buildingNo'
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={classes.inputContainerCon}>
              <label className={classes.label}>Street Name </label>
              <div className={classes.inputContainer}>
                <input
                  type='text'
                  className={classes.select}
                  name='streetName'
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={classes.inputContainerCon}>
              <label className={classes.label}>City </label>
              <div className={classes.inputContainer}>
                <input
                  type='text'
                  className={classes.select}
                  name='city'
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={classes.inputContainerCon}>
              <label className={classes.label}>LGA </label>
              <div className={classes.inputContainer}>
                <input
                  type='text'
                  className={classes.select}
                  name='LGA'
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={classes.inputContainerCon}>
              <label className={classes.label}>State </label>
              <div className={classes.inputContainer}>
                <input
                  type='text'
                  className={classes.select}
                  name='state'
                  onChange={handleChange}
                />
              </div>
            </div>


            <div className={classes.inputContainerCon}>
              <label className={classes.label}>Image </label>
              <p onClick={() => setShowCamera(true)} className={classes.pick_location}>
                Click To Open Camera
              </p>
              {showCamera && (
                <div className={classes.container}>
                  <div className={classes.video_container}>
                    <video className={classes.video} ref={videoRef} playsInline autoPlay muted />
                    <button className={classes.camera_icon_container} onClick={() => takePicture('image')}>Take Picture</button>
                  </div>
                </div>
              )}
              <canvas className={classes.taken_picture} ref={canvasRef} style={{ display: hasPhoto ? 'block' : 'none' }} />

            </div>

            <button onClick={create} className={classes.submit_response_btn}>Submit {creatingAsset && 'ing...'}</button>

          </div>
      }


    </div>
  )
}

export default CreateAssets