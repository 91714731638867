import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

const CustomTabs = ({ data, defaultIndex }) => {
  return (
    <>
      <Tabs defaultIndex={defaultIndex}>
        <TabList border={"none"} gap="1rem" mb=".3rem" overflowX={"scroll"}
        sx={
          { 
         '::-webkit-scrollbar':{
                display:'none'
            }
         }
       }>
          {data?.map((i, index) => (
            <Tab whiteSpace={"nowrap"} px="auto" color="#475467" fontSize={"14px"} _selected={{color: "#7F56D9", fontWeight: "600", borderBottom: "3px solid #7F56D9"}} key={index}>{i?.title}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {data?.map((i, index) => (
            <TabPanel key={index} px="0">
              <p>{i?.render}</p>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CustomTabs;
