import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import CustomDrawer from '../customDrawer/customDrawer'
import { useSelector } from 'react-redux'
import { truncateText } from '../../utils/helper'

const CustomTable = ({manage, headers, data, triggerClose, setIdx, type, setIdx2}) => {

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const handleManage = (obj) => {
    setIdx(obj)
    if(setIdx2) {
      setIdx2(obj?.id)
    }
    // console.log(obj)
  }

  return (
    <Box overflow={"hidden"} border={"1px solid #475467"} borderRadius={"12px"} boxShadow="0px 1px 2px 0px #1018280D">
      <Flex padding={"1rem"} alignItems={"center"} bg="#E9E9E9" justifyContent={"space-between"}>
        {headers?.map((i) => (
            <Text flex={"1"} whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"10px"}>{i}</Text>
        ))}
        {currentUser?.role === "Promoter" && <Text opacity={'0'}>Hell</Text>}
      </Flex>
      {data?.map((i) => (
        <Flex padding={"1rem"} alignItems={"center"} borderTop={"1px solid #475467"} justifyContent={"space-between"}>
          <Tooltip label={i?.one}><Text flex={"1"} fontWeight={"500"} fontSize={"10px"}>{truncateText(i?.one, 12)}</Text></Tooltip>
          <Tooltip label={i?.two}><Text flex={"1"} fontWeight={"500"} fontSize={"10px"}>{truncateText(i?.two, 12)}</Text></Tooltip>
          <Tooltip label={i?.three}><Text flex={"1"} fontWeight={"500"} fontSize={"10px"}>{truncateText(i?.three, 12)}</Text></Tooltip>
          <Tooltip label={i?.four}><Text flex={"1"} fontWeight={"500"} color={type === "inventory" && i?.four < 20 ? "crimson" : ""} fontSize={"10px"}>{truncateText(i?.four, 12)}</Text></Tooltip>
          {i?.five && <Tooltip label={i?.five}><Text flex={"1"} fontWeight={"500"} fontSize={"10px"}>{truncateText(i?.five, 12)}</Text></Tooltip>}
          {(currentUser?.role === "Promoter" && manage) && 
          <CustomDrawer
              position={"bottom"} 
              title={`Update item`} 
              triggerClose={triggerClose}
              toggleElement={
                <BiDotsVerticalRounded onClick={() => handleManage(i)}
                style={{cursor: "pointer"}} />
              }
              content={manage}
          />}
        </Flex>
      ))}
    </Box>
  )
}

export default CustomTable;