import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/api";
import LineSkeleton from "../../../components/lineSkeleton";
import { Box, Flex, Text } from "@chakra-ui/react";
import EmptyState from "../../../components/emptyState/emptyState";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const Teams = () => {
  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [teams, setTeams] = useState([])

  const getTeams = async() => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(`employees/${currentUser?._id}/team-members`)
      setTeams(data?.data)
    } catch(error) {
        console.log(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getTeams()
  }, [])


  return (
    <>
    {loading ?
    <LineSkeleton num={12} />
    :
    teams?.length < 1 ?
    <Flex justifyContent={"center"}>
        <EmptyState text={"No team members assigned to you yet"} />
    </Flex>
    :
    teams?.map(({_id: id, firstName, lastName, email, profilePicture}) => {
        return (
            <Flex mb=".5rem" key={id} onClick={() => navigate(`/${id}/promoter`)} justifyContent={"space-between"} cursor={"pointer"} alignItems={"center"} p=".8rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                <Flex alignItems={"center"} gap=".8rem">
                <Box height={"50px"} width={"55px"} borderRadius={"6px"} backgroundImage={profilePicture || null} bg="lightgray" backgroundSize={"contain"} backgroundRepeat={"no-repeat"} />
                <Box>
                    <Text mb=".3rem" fontSize={".95rem"} color="#475467" textTransform={"capitalize"} fontWeight={"600"}>{`${firstName} ${lastName}`}</Text>
                    <Text fontSize={".85rem"} color="#667085" fontWeight={"500"}>{email}</Text>
                </Box>
                </Flex>
                <HiOutlineArrowNarrowRight color='#667085' style={{transform: "scale(1.3)"}} />
            </Flex>
        )
    })
    }
    </>
  )
}

export default Teams;