import { Box, Flex, FormControl, FormLabel, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/api'
import CustomInput from '../../../components/customInput/customInput'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import CustomSelect from '../../../components/customSelect/customSelect'
import ImageUploader from '../../../components/imageUploader/imageUploader'
import CustomTextArea from '../../../components/customTextArea/customTextArea'
import SearchableSelect from '../../../components/searchableSelect/searchableSelect'
import { rerunOffline, saveOffline } from '../../../utils/helper'

const CreateResponse = ({questions, setTriggerClose, sid}) => {
  const [loading, setLoading] = useState(false)
  const [loadingi, setLoadingi] = useState(false)
  const [imageURL, setImageURL] = useState("");
  const [answers, setAnswers] = useState([]);
  const [tempArr, setTempArr] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [cidx, setCidx] = useState("")
  const [iidx, setIidx] = useState("")
  const toast = useToast();

  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({latitude, longitude})
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
    setAnswers([...answers, {
        questionIndex: iidx,
        answer: images[0]
    }])
  };

  const submit = async(e) => {
    e.preventDefault()
    setLoading(true)

    const myObj = {}

    const datax = {
        surveyId: sid,
        location: coordinates,
        entries: cidx === "" ? answers : 
        [...answers?.filter(i => i?.questionIndex !== cidx), {
            questionIndex: cidx,
            answer: tempArr
        }]
    }

    const sortedData = {
        requestSourcePlatform: 'web',
        surveyId: sid,
        location: coordinates,
        entries: cidx === "" ? [...answers].sort((a, b) => a.questionIndex - b.questionIndex) :
        [...answers?.filter(i => i?.questionIndex !== cidx), {
            questionIndex: cidx,
            answer: tempArr?.join(', ')
        }].sort((a, b) => a.questionIndex - b.questionIndex)

    }

    for (const key in sortedData) {
      const value = sortedData[key];
      if (value !== "" && value !== undefined && value !== null) {
        myObj[key] = value;
      }
    }

    try {
        const {data} = await axiosInstance.post('/survey-responses', myObj)
        toast({
            description: data?.message,
            status: "success",
            position: "top"
        });
        setTriggerClose('close' + (Math.random()))
    } catch(error) {
      const prevData = JSON.parse(localStorage.getItem('survey'))
      if (!error.response) {
        toast({
          title: "Network Timeout",
          description: "Your internet may be unconnected or very slow, this entry has been saved offline and would be auto-submitted when your internet comes back on.",
          status: "error",
          position: "top"
        });
        saveOffline('survey', JSON.stringify([...prevData, {uid: Math.random().toString(36), ...myObj}]))
      } else {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top"
        });
      }
    } finally {
        setLoading(false)
    }
  }

  const handleFieldChange = (e, index, type) => {
    if(answers?.filter(i => i?.questionIndex === index)?.length > 0) {
        const newArr = answers?.filter(k => k?.questionIndex !== index)
        if(type === "checkbox") {
            setTempArr([...tempArr, e?.target?.value])
            setAnswers([...newArr, {
                questionIndex: index,
                answer: tempArr
            }]) 
        } else {
           setAnswers([...newArr, {
                questionIndex: index,
                answer: e?.target?.value
            }]) 
        }
        
    } else {
        setAnswers([...answers, {
            questionIndex: index,
            answer: e?.target?.value ?? e?.value
        }])
    }
    
  }

  const handleCheckboxChange = (e, index) => {
    setCidx(index)
    const name = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setTempArr([...tempArr, name])
    } else {
      const newArr = tempArr?.filter(i => i !== name)
      setTempArr(newArr)
    }
  };

//   console.log(answers?.filter(i => i?.questionIndex === index)?.length > 0)

console.log(questions)

  return (
    <>
    <form onSubmit={submit}>
    {questions?.map((i, index) => {
        return (
            <>
            {i?.fieldType === "text" && 
            <FormControl>
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <CustomInput
            type={"text"}
            required={i?.isRequired}
            onBlur={e => handleFieldChange(e, index)}
            mb=".5rem"
            />
            </FormControl>
            }
            {(i?.fieldType === "select" && i?.fieldOptions?.length <= 6) && 
            <FormControl>
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <CustomSelect
            required={i?.isRequired}
            placeholder={i?.fieldName}
            options={i?.fieldOptions?.map(k => ({
                title: k,
                value: k
            }))}
            onChange={e => handleFieldChange(e, index)}
            mb=".5rem"
            />
            </FormControl>
            }
            {(i?.fieldType === "select" && i?.fieldOptions?.length > 6) && 
            <FormControl>
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <Box zIndex={"10 !important"}>
              <SearchableSelect
                  options={i?.fieldOptions?.map(i => ({
                      label: i,
                      value: i
                  }))}
                  onChange={e => handleFieldChange(e, index)}
                  placeholder={i?.fieldName}
                  isRequired={i?.isRequired}
              />
            </Box>
            </FormControl>
            }
            {i?.fieldType === "email" && 
            <FormControl>
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <CustomInput
            type={"email"}
            required={i?.isRequired}
            onBlur={e => handleFieldChange(e, index)}
            mb=".5rem"
            />
            </FormControl>
            }
            {i?.fieldType === "tel" && 
            <FormControl>
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <CustomInput
            type={"tel"}
            required={i?.isRequired}
            onBlur={e => handleFieldChange(e, index)}
            mb=".5rem"
            maxlength={11}
            minLength={i?.fieldName?.toLowerCase()?.includes('phone') ? 11 : 1}
            />
            </FormControl>
            }
            {i?.fieldType === "radio" && 
            <FormControl border="1px dashed #D0D5DD" p=".5rem" borderRadius={"6px"} mb=".5rem">
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            {i?.fieldOptions?.map(v => (
                <label style={{fontSize: ".85rem", marginRight: ".5rem"}} key={v}>
                    <input
                    type="radio"
                    value={v}
                    onBlur={e => handleFieldChange(e, index)}
                    style={{transform: "translateY(1.5px)", marginRight: ".2rem"}}
                    required={i?.isRequired}
                    />
                    {v}
                </label>
            ))}
            </FormControl>
            }
            {i?.fieldType === "checkbox" && 
            <FormControl border="1px dashed #D0D5DD" p=".5rem" borderRadius={"6px"} mb=".5rem">
            <FormLabel transform={"translateY(.2rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <Flex flexWrap={"wrap"} columnGap=".6rem">
                {i?.fieldOptions?.map((v) => (
                <Flex alignItems={"center"} gap=".2rem" key={v}>
                    <input
                    type="checkbox"
                    id={v}
                    value={v}
                    checked={answers?.find(k => k?.questionIndex === index)?.answer?.includes(v)}
                    onChange={e => handleCheckboxChange(e, index)}
                    />
                    <label style={{whiteSpace: "nowrap"}} htmlFor={v}>{v}</label>
                </Flex>
                ))}
            </Flex>
            </FormControl>
            }
            {i?.fieldType === "image" && 
            <Flex mb=".5rem" mt=".3rem" gap=".5rem" onClick={() => setIidx(index)}>
                {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                <ImageUploader
                required={i?.isRequired}
                onImagesUpload={handleImagesUpload}
                setLoading={setLoadingi}
                loading={loadingi}
                max={1}
                src="camera"
                />
            </Flex>
            }
            {i?.fieldType === "textarea" && 
            <FormControl>
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <CustomTextArea
            rows="1"
            onBlur={e => handleFieldChange(e, index)}
            required={i?.isRequired}
            mb=".8rem"
            />
            </FormControl>
            }
            {i?.fieldType === "date" && 
            <FormControl>
            <FormLabel transform={"translateY(.3rem)"} fontSize={".75rem"}>{i?.title}</FormLabel>
            <CustomInput
            type={"date"}
            required={i?.isRequired}
            onBlur={e => handleFieldChange(e, index)}
            mb=".8rem"
            />
            </FormControl>
            }
            </>
        )
    })}

    <PrimaryButton mt="1.5rem" type="submit" text="Submit" icon="add" loading={loading} />
    </form>
    </>
  )
}

export default CreateResponse;