import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/primaryButton/primarybutton";
import ActiveBadge from '../../assets/activeUserBadge.svg';
import InactiveBadge from '../../assets/inactiveUserBadge.svg';
import PeopleBadge from '../../assets/peopleBadge.svg';
import AuthBanner from '../../assets/authBanner1.svg';
import axiosInstance from "../../services/api";

const userTypes = [
    {
        type: "Promoter",
        description: "Text describing the header"
    },
    {
        type: "Supervisor",
        description: "Text describing the header"
    },
    // {
    //     type: "Store owner",
    //     description: "Text describing the header"
    // },
]

const Auth = () => {

    const [userType, setUserType] = useState(userTypes[0]?.type);
    const navigate = useNavigate();

    return (
        <Box>
            <Box height={"238px"} bg="lightgray" width={"100%"} backgroundImage={AuthBanner}>
            </Box>
            <Box padding="1rem">
                <Heading mt={6} fontWeight={500} fontSize="1.5rem" color="#030303" fontFamily='Roboto'>Welcome to PromoRama</Heading>
                <Text mb="1.1rem" color={"#667085"} fontSize={".95rem"}>Effectively manage all your store activities</Text>
                <br />
                {userTypes?.map((i) => (
                    <Flex onClick={() => setUserType(i?.type)} key={i?.type} p=".8rem" borderRadius={"12px"} cursor={"pointer"} border="1px solid" bg={userType === i?.type ? "#F9F5FF" : "#FFF"} borderColor={userType === i?.type ? "#7F56D9" : "#D0D5DD"} mb=".8rem" alignItems={"center"} justifyContent={"space-between"}>
                        <Flex alignItems={"center"} gap="1rem">
                            {userType === i?.type ? <Image src={ActiveBadge} /> : <Image src={InactiveBadge} />}
                            <Box>
                                <Text mb=".2rem" color={userType === i?.type ? "#101828" : "#667085"} fontWeight={"600"}>I am a {i?.type}</Text>
                                <Text color={userType === i?.type ? "#101828" : "#667085"} fontSize={".9rem"}>{i?.description}</Text>
                            </Box>
                        </Flex>
                        <Box padding={".2rem"} height={"16px"} width={"16px"} border="1px solid" borderColor={userType === i?.type ? "#7F56D9" : "#D0D5DD"} borderRadius={"50%"} boxSizing="border-box">
                            {userType === i?.type && <Box bg="#7F56D9" borderRadius={"50%"} height={"100%"} width={"100%"}></Box>}
                        </Box>
                    </Flex>
                ))}
                <Image my="2.2rem" src={PeopleBadge} />
                <PrimaryButton text="Sign In" icon="next" disabled={userType?.length < 1} func={() => navigate('login', {state: {userType: userType}})} />
            </Box>
        </Box>
    )
}

export default Auth;

