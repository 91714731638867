import { Box, Flex, Grid, Text, useToast } from "@chakra-ui/react"
import Navbar from "../../../components/navbar/navbar"
import { useEffect, useRef, useState } from "react"
import SpinLoader from "../../../components/spinLoader/spinLoader"
import axiosInstance from "../../../services/api"
import { useParams } from "react-router-dom"
import CustomDrawer from "../../../components/customDrawer/customDrawer"
import PrimaryButton from "../../../components/primaryButton/primarybutton"
import CustomInput from "../../../components/customInput/customInput"
import ImageUploader from "../../../components/imageUploader/imageUploader"
import GameView from "./gameView/gameView"


const Rewards = ({initiativeData}) => {
  const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    const toast = useToast()
  
  const [gameView, setGameView] = useState(false)
  const [triggerClose, setTriggerClose] = useState('')
  const [game, setGame] = useState("")
  const [title, setTitle] = useState("")
  const [active, setActive] = useState(true)

  const [comment, setComment] = useState("")

  const [name, setName] = useState("");
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [imageURL, setImageURL] = useState([])
  const [imageURL2, setImageURL2] = useState([])
  const [loadingu, setLoadingu] = useState(false)
  // const [loadingu2, setLoadingu2] = useState(false)

  const prevRecord = JSON.parse(localStorage.getItem("winsTracker"));
  const clearPrevRecords = () => {
    const newRecord = prevRecord?.filter(x => x?.date === formattedDate )
    localStorage.setItem('winsTracker', JSON.stringify(newRecord || []))
  }

  useEffect(() => {
    clearPrevRecords()
  }, [])

  const player = 
  (imageURL?.length > 0 && email) ?
  {
    name,
    email: email || 'notprovided@gmail.com',
    phoneNumber,
    imageURL: imageURL[0],
    // prizeImageURL: imageURL2[0]
  }
  :
  (imageURL?.length < 1 && email) ?
  {
    name,
    email: email || 'notprovided@gmail.com',
    phoneNumber,
    // prizeImageURL: imageURL2[0]
  }
  :
  (imageURL?.length > 0 && !email) ?
  {
    name,
    phoneNumber,
    imageURL: imageURL[0],
    email: 'notprovided@gmail.com'
  }
  :
  {
    name,
    phoneNumber,
  }

  const handleImagesUpload = (images) => {
    setImageURL(images);
  };
  const handleImagesUpload2 = (images) => {
    setImageURL2(images);
  };

  

  const handleStart = () => {
    setGameView(true)
    setTriggerClose("close");
  }

  const handleClick = (name, title) => {
    setGame(name)
    setTitle(title)
  }

  const handleChange = (e) => {
    const value = e.target.value
    setPhoneNumber(value)
    if((value?.length === 11) && (prevRecord?.filter(x => (x?.phoneNumber === value && x?.date === formattedDate))?.length >= 5)) {
      
      toast({
        title: "Ooops!",
        description: "You have already played the maximum number of times(5) daily today. please come back tomorrow",
        status: "error",
        position: "top",
      });
      setActive(false)
    } else {
      setActive(true)
    }
    
  }

  return (
    <>
     {gameView === true ?
     <GameView game={game} gameTitle={title} data={initiativeData} player={player} prizeImage={imageURL2[0]} comment={comment} setGameView={setGameView} setImage={setImageURL} formattedDate={formattedDate} />
     :
     <Grid
        templateColumns={[
        "repeat(auto-fill, minmax(150px, 1fr))",
        "repeat(auto-fill, minmax(200px, 1fr))",
        ]}
        gap={"1rem"}
     >
        {initiativeData?.initiative?.games?.map(({name, _id, imageURL: img, title}) => {
            return (
                <CustomDrawer
              position={"bottom"} 
              title={name} 
              triggerClose={triggerClose}
              toggleElement={
                <Box key={_id} cursor={"pointer"} onClick={() => handleClick(name, title)}>
                    <Box borderRadius={"6px"} bg="lightgray" backgroundImage={img} backgroundSize={"cover"} backgroundPosition={"center"} height={"160px"}  />
                    <Text mt=".2rem" textTransform={"capitalize"} fontSize={".9rem"} opacity={".7"} fontWeight={"500"} textAlign={"center"}>{title || name}</Text>
                </Box>
              }
              content={
                <form onSubmit={handleStart}>
                  <CustomInput
                  type={"text"}
                  required
                  placeholder={"Name"}
                  mb={".8rem"}
                  onChange={e => setName(e.target.value)}
                  />
                  <CustomInput
                  type={"email"}
                  placeholder={"Email"}
                  mb={".8rem"}
                  onChange={e => setEmail(e.target.value)}
                  />
                  <CustomInput
                  type={"tel"}
                  required
                  placeholder={"Phone Number"}
                  mb={".8rem"}
                  onChange={handleChange}
                  minLength={11}
                  maxLength={11}
                  />
                  {active &&
                  <Box>
                  <Text fontSize={".7rem"} transform="translateY(7px)">Image</Text>
                  <Flex gap=".5rem" mt=".7rem" mb=".8rem" alignItems={"center"}>
                    {imageURL?.map((imageUrl, index) => (
                      <Box
                        backgroundImage={imageUrl}
                        backgroundSize={"contain"}
                        height={"70px"}
                        width={"100px"}
                        borderRadius={"4px"}
                        backgroundColor={"lightgray"}
                      />
                    
                    ))}
                    <ImageUploader
                    onImagesUpload={handleImagesUpload}
                    setLoading={setLoadingu}
                    loading={loadingu}
                    max={1}
                    src={"camera"}
                  />

                  </Flex>
                  {/* <Text fontSize={".7rem"} transform="translateY(7px)">Prize Image</Text>
                  <Flex gap=".5rem" mt=".7rem" mb=".8rem" alignItems={"center"}>
                    {imageURL2?.map((imageUrl, index) => (
                      <Box
                        backgroundImage={imageUrl}
                        backgroundSize={"contain"}
                        height={"70px"}
                        width={"100px"}
                        borderRadius={"4px"}
                        backgroundColor={"lightgray"}
                      />
                    
                    ))}
                    <ImageUploader
                    onImagesUpload={handleImagesUpload2}
                    setLoading={setLoadingu2}
                    loading={loadingu2}
                    max={1}
                  />

                  </Flex> */}
                  <CustomInput
                  type={"text"}
                  placeholder={"Comment"}
                  mb={".8rem"}
                  onChange={e => setComment(e.target.value)}
                  />

                  <PrimaryButton mt="2rem" type="submit" text="Start Game" loading={false} />
                  </Box>}
                  
                </form>
              }
            />
            )
        })}
     </Grid>
     }
    </>
  )
}

export default Rewards;