import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SkeletonLoader from '../../../components/skeletonLoader/skeletonLoader'
import EmptyState from '../../../components/emptyState/emptyState'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import One from '../../../assets/dummyImages/one.svg'
import { HiOutlineArrowNarrowRight, HiOutlineLocationMarker } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axiosInstance from '../../../services/api'
import { MdOutlineSubdirectoryArrowRight } from 'react-icons/md'
import LineSkeleton from '../../../components/lineSkeleton'


const dummy = [
    {
      name: "Store name",
      image: One,
      initiative: "Initiative"
    }
]

const InitiativeStores = () => {
  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const getInitiativeStores = async() => {
      setLoading(true)
      try {
          const { data } = await axiosInstance.get('/initiative-stores/assigned')
          setStores(data?.data)
      } catch(error) {
          console.error(error?.response?.data?.message)
      } finally {
          setLoading(false)
      }
  }

  useEffect(() => {
      getInitiativeStores()
  }, [])

  const initiativeList = stores?.map(i => i?.initiative?.name)
  const uniqueList = [...new Set(initiativeList)]

  return (
    <Box height={"81dvh"} overflowY={"scroll"}
    sx={
        { 
       '::-webkit-scrollbar':{
              display:'none'
          }
       }
     }>
        {/* {loading ?
        <SkeletonLoader />
        :
        stores?.length < 1 ?
        <Flex justifyContent={"center"}>
         <Box>
            <EmptyState text={"No store invites"} />
            <Text mt=".5rem" maxW={"300px"} textAlign={"center"} mx="auto" color={"#667085"}>{currentUser?.role === "supervisor" ? "You can be added to supervise a store by a client/admin" : "You can be added to a store by the store owner or supervisor"}</Text>
         </Box>
        </Flex>
        :
        stores?.map((({store, image,  initiative, _id: id}, index) => {
            return (
                <Flex mb=".5rem" key={id} onClick={currentUser?.role === "supervisor" ? () => navigate(`/${id}/dashboard`) : () => navigate(`/${id}`, {state: {storeName: store?.name, storeLocation: `${store?.area}, ${store?.state}`}})} justifyContent={"space-between"} cursor={"pointer"} alignItems={"center"} p=".8rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                    <Flex alignItems={"center"} gap=".8rem">
                      <Box height={"50px"} width={"60px"} borderRadius={"6px"} backgroundImage={image || null} bg="lightgray" backgroundSize={"contain"} backgroundRepeat={"no-repeat"} />
                      <Box>
                          <Text mb=".3rem" fontSize={"1rem"} color="#475467" fontWeight={"600"}>{store?.name}</Text>
                          <Text fontSize={".85rem"} color="#667085" fontWeight={"500"}>{initiative?.name}</Text>
                      </Box>
                    </Flex>
                    <HiOutlineArrowNarrowRight color='#667085' style={{transform: "scale(1.3)"}} />
                </Flex>
            )
        }))
        } */}

        <>
        {loading ?
        <LineSkeleton num={7} />
        :
        stores?.length < 1 ?
        <Flex justifyContent={"center"}>
         <Box>
            <EmptyState text={"No store invites"} />
            <Text mt=".5rem" maxW={"300px"} textAlign={"center"} mx="auto" color={"#667085"}>{currentUser?.role === "Supervisor" ? "You can be added to supervise a store by a client/admin" : "You can be added to a store by the store owner or supervisor"}</Text>
         </Box>
        </Flex>
        :
        uniqueList?.map((i) => {
            return (
                <Box key={i} mb="1.5rem" position={"relative"}>
                    <Text fontSize={"1rem"} pb=".4rem" borderBottom={".5px solid lightgray"} color="#667085" fontWeight={"600"} textTransform={"capitalize"}>{i}</Text>
                    <MdOutlineSubdirectoryArrowRight style={{position: "absolute", transform: "translateY(.3rem)"}} color="#667085" />

                    <Box mt=".5rem">
                        {stores?.filter(v => v?.initiative?.name === i)?.map((({store, image,  initiative, _id: id}, index) => {
                            return (
                                // <Flex mb=".5rem" key={id} width={"88vw"} ml="5vw" onClick={currentUser?.role === "Supervisor" ? () => navigate(`/${id}/dashboard`) : () => navigate(`/${id}`, {state: {storeName: store?.name, storeLocation: `${store?.area}, ${store?.state}`}})} justifyContent={"space-between"} cursor={"pointer"} alignItems={"center"} p=".8rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                                <Flex mb=".5rem" key={id} width={"88vw"} ml="5vw" onClick={() => navigate(`/${id}`, {state: {store: store, id: id}})} justifyContent={"space-between"} cursor={"pointer"} alignItems={"center"} p=".8rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                                    <Flex alignItems={"center"} gap=".8rem">
                                    <Box height={"50px"} width={"60px"} borderRadius={"6px"} backgroundImage={image || null} bg="lightgray" backgroundSize={"contain"} backgroundRepeat={"no-repeat"} />
                                    <Box>
                                        <Text mb=".3rem" fontSize={".95rem"} color="#475467" textTransform={"capitalize"} fontWeight={"600"}>{store?.name}</Text>
                                        <Text fontSize={".85rem"} color="#667085" fontWeight={"500"}>{store?.area}, <span style={{textTransform: "capitalize"}}>{store?.state}</span></Text>
                                    </Box>
                                    </Flex>
                                    <HiOutlineArrowNarrowRight color='#667085' style={{transform: "scale(1.3)"}} />
                                </Flex>
                            )
                        }))}
                    </Box>
                </Box>
            )
        })}
        </>
    </Box>
  )
}

export default InitiativeStores