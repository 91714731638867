import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/customInput/customInput';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../services/api';
import { Box, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { HiUpload } from 'react-icons/hi';
import ImageUploaderx from '../../../components/imageUploader/imageUploaderx';
import CustomSelect from '../../../components/customSelect/customSelect';
import { useNavigate } from 'react-router-dom';
import { truncateText } from '../../../utils/helper';


const EditReport = ({loading, setLoading, setTriggerClose, source, datay, setRefetch}) => {
  const [iid, setIid] = useState(datay?.initiative);
  const [date, setDate] = useState(datay?.date);
  const [name, setName] = useState(datay?.name);
  const [comment, setComment] = useState(datay?.comment);
  const [uploadedURLs, setUploadURLs] = useState(datay?.uploadURLs);
  const [loadingu, setLoadingu] = useState(false);
  const [loadingd, setLoadingd] = useState(false);
  
  const handleImagesUpload = (images) => {
    setUploadURLs(images);
  };

  const toast = useToast();
  const navigate = useNavigate();

  const [stores, setStores] = useState([])
  const [loadingv, setLoadingv] = useState(false)

  const getInitiativeStores = async() => {
      setLoadingv(true)
      try {
          const { data } = await axiosInstance.get('/initiative-stores/assigned')
          setStores(data?.data)
      } catch(error) {
          console.error(error?.response?.data?.message)
      } finally {
          setLoadingv(false)
      }
  }

  useEffect(() => {
      getInitiativeStores()
  }, [])

  const updateReport = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      initiativeId: iid,
      name,
      date,
      comment,
      uploadURLs: datay?.uploadURLs[0] !== uploadedURLs[0] ? uploadedURLs?.map(i => i?.secure_url) : datay?.uploadURLs,
    }
    try {
      const {data} = await axiosInstance.patch(`/reports/${datay?._id}`, datax)
      toast({
        description: data?.message,
        status: "success",
      });
      setTriggerClose('close')
      setRefetch(Math.random());
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoading(false)
    }
  }

  const assignedStores = stores?.map(i => ({
    title: i?.initiative?.name,
    value: i?.initiative?._id
  }))

  const uniqueInitiativesList = assignedStores?.filter((item, index, array) => {
    return (
      index === array.findIndex(obj => obj.title === item.title && obj.value === item.value)
    );
  });

  const deleteDoc = async() => {
    setLoadingd(true)
    try {
      const {data} = await axiosInstance.delete(`/reports/${datay?._id}`)
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingd(false)
    }
  }
  
  return (
    <form onSubmit={updateReport}>
        <Flex mb=".5rem" justifyContent={"flex-end"}>
          {loadingd ?
          <Spinner size="xs" />
          :
            <Text onClick={deleteDoc} fontSize={".9rem"} cursor={"pointer"} textDecoration={"underline"} fontWeight={"500"} color={"crimson"}>Delete</Text>}
        </Flex>
        {source === 'general' &&
        <CustomSelect
        options={uniqueInitiativesList}
        placeholder={uniqueInitiativesList?.find(i => i.value === datay?.initiative)?.title}
        onChange={e => setIid(e.target.value)}
        mb={".8rem"}
        />}
        <CustomInput
        type={"text"}
        required
        placeholder={"Report title"}
        mb={".8rem"}
        value={name}
        onChange={e => setName(e.target.value)}
        />
        <CustomInput
        type={"date"}
        required
        placeholder={"Date"}
        mb={".8rem"}
        value={date}
        onChange={e => setDate(e.target.value)}
        />
         <CustomInput
        type={"text"}
        required
        placeholder={"Comment"}
        mb={".8rem"}
        value={comment}
        onChange={e => setComment(e.target.value)}
        />
        <Box mb=".8rem">
          <ImageUploaderx
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingu}
            loading={loadingu}
            max={1}
          />
          {uploadedURLs?.map((i, index) => (
            <Flex mt=".7rem" gap=".3rem" alignItems={"center"}>
              <HiUpload opacity={".6"} />
              <Text color="#7F56D9" fontWeight={"500"} fontSize={".8rem"}>{truncateText(i?.original_filename ?? i, 35)}</Text>
            </Flex>
          ))}
        </Box>
        <PrimaryButton mt="1rem" type="submit" text="Update report" func={() => {}} loading={loading} />
    </form>
  )
}

export default EditReport;