import { Box, Flex, IconButton, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SkeletonLoader from '../../../components/skeletonLoader/skeletonLoader'
import EmptyState from '../../../components/emptyState/emptyState'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import One from '../../../assets/dummyImages/one.svg'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { AddIcon, SearchIcon } from '@chakra-ui/icons'
import CustomDrawer from '../../../components/customDrawer/customDrawer'
import CreateStore from './createStore'
import axiosInstance from '../../../services/api'
import { useSelector } from 'react-redux'
import ViewStore from './viewStore'



const OnboardedStores = ({src, id, date1, date2, showAllDates}) => {
  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingc, setLoadingc] = useState(false)

  const [refetch, setRefetch] = useState("")

  const [triggerClose, setTriggerClose] = useState("")

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const toast = useToast()

  const getStores = async() => {
    setLoading(true)
    try {
      // const {data} = await axiosInstance.get(src === "supervisor" ? `/stores?user=${id}&createdAt[gte]=${date1}&createdAt[lt]=${date2}` : `/stores?user=${currentUser?._id}`)
      const {data} = await axiosInstance.get(src === "supervisor" ? showAllDates === true ? `/stores?user=${id}` : `/stores?user=${id}&createdAt[gte]=${date1}&createdAt[lt]=${date2}` : `/stores?user=${currentUser?._id}`)
      setStores(data?.data)
    } catch(error) {
      // toast({
      //   title: "An Error Occurred",
      //   description: error?.response?.data?.message,
      //   status: "error",
      // });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStores()
  }, [loadingc, refetch, date1, date2, showAllDates])

  return (
    <Box height={"81dvh"} overflowY={"scroll"}
    sx={
        { 
       '::-webkit-scrollbar':{
              display:'none'
          }
       }
     }>
        {loading ?
        <SkeletonLoader />
        :
        stores?.length < 1 && src !== "supervisor" ?
        <Flex justifyContent={"center"}>
         <Box>
            <EmptyState text={"No stores added"} />
            <CustomDrawer 
              position={"bottom"}
              title={"Add new outlet"}
              triggerClose={triggerClose}
              toggleElement={
                <PrimaryButton mt="1rem" width="" text="Add new store" icon="add" func={() => {}} loading={loading} />
              }
              content={
                <CreateStore loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
              }
            />
         </Box>
        </Flex>
        :
        stores?.map(((i) => {
            return (
              <CustomDrawer  
                key={i?.name}
                position={"bottom"}
                title={"Outlet details"}
                triggerClose={triggerClose}
                toggleElement={
                  <Flex cursor={"pointer"} mb=".7rem" alignItems={"center"} gap=".5rem" p=".7rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                    <Box height={"50px"} width={"60px"} borderRadius={"8px"} bg="lightgray" backgroundImage={i?.imageURL} backgroundSize={"cover"} />
                    <Box>
                        <Text mb=".3rem" fontSize={"1rem"} color="#475467" fontWeight={"600"} textTransform={"capitalize"}>{i?.name}</Text>
                        <Text fontSize={".85rem"} color="#667085" fontWeight={"500"} textTransform={"capitalize"}><HiOutlineLocationMarker style={{display: "inline", color: "#32D583", transform: "translateY(2px) scale(1.1)"}} /> {`${i?.area}, ${i?.state}`}</Text>
                    </Box>
                  </Flex>
                }
                content={
                  <Box height={"65vh"} overflowY={"scroll"}>
                    <ViewStore data={i} src={src} setRefetch={setRefetch} setTriggerClose={setTriggerClose} />
                  </Box>
                }
              />
            )
        }))
        }
        {stores?.length > 0 && src !== "supervisor" &&
        <CustomDrawer 
          position={"bottom"}
          title={"Add new outlet"}
          triggerClose={triggerClose}
          toggleElement={
            <IconButton
            position={"absolute"} 
            bottom={"13%"}
            right={"5%"}
            zIndex={"5"}
            bg="#000000" 
            boxShadow="0px 4px 24px 0px #0000000D" 
            color="#FFF" borderRadius={"50%"} 
            boxSizing='border-box' 
            padding={"1.5rem 1rem"}
            _hover={{backgroundColor: "#000", color: "#FFF"}} 
            aria-label='Add new store' 
            icon={<AddIcon />} 
            />
          }
          content={
            <CreateStore loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
          }
        />
        }
    </Box>
  )
}

export default OnboardedStores















