import { Avatar, Box, Flex, Heading, Text, useToast } from "@chakra-ui/react"
import CustomTabs from "../../components/customTabs/customTabs";
import OnboardedStores from "./onboardedStores/onboardedStores";
import InitiativeStores from "./initiativeStores/initiativeStores";
import { useDispatch, useSelector } from "react-redux";
import { userActionTypes } from "../../redux/constants/userActionTypes";
import { useLocation, useNavigate } from "react-router-dom";
import Reports from "./reports/reports";
import { HiUserCircle } from "react-icons/hi";
import Surveys from "../surveys/surveys";
import Teams from "./teams/teams";
import Assets from "../assets/assets";
import MainAssets from "../assets";
import { useEffect, useState } from "react";
import axiosInstance from "../../services/api";


const Stores = () => {
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const toast = useToast()

  const data = [{
    title: "Onboarded outlets",
    render: <OnboardedStores />
  },
  {
    title: "Initiative outlets",
    render: <InitiativeStores />
  },
  {
    title: "Surveys",
    render: <Surveys src={"out"} />
  },
  {
    title: "Assets",
    render: <MainAssets />
  }
  ]

  const data2 = [
    {
      title: "Initiative outlets",
      render: <InitiativeStores />
    },
    {
      title: "Onboarded outlets",
      render: <OnboardedStores />
    },
    {
      title: "Reports",
      render: <Reports />
    },
    {
      title: "Team Members",
      render: <Teams />
    },
  ]

  const getCurrentClockIn = async() => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get('/attendances/current-clock-in')
      console.log(data?.data)
      if(data?.data !== null) {
        navigate(`/${data?.data?.initiativeStore}/dashboard`)
      }
      
    } catch (error) {
      toast({
        title: "An error occured",
        description: error?.response?.data?.message,
        status: "success",
      });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCurrentClockIn()
  }, [])


  return (
    <>
      <Flex mt="1rem" px="1rem" alignItems={"center"} justifyContent={"flex-end"}>
        <Box border="1px solid lightgray" borderRadius={"50%"} onClick={() => navigate('/profile')} cursor={"pointer"}>
          <Avatar size={"md"} transform={"scale(.8)"} name={currentUser?.firstName + " " + currentUser?.lastName} src={currentUser?.profilePicture} />
        </Box>
      </Flex>

      <Box padding={"1rem"}>
        <Text mb="1rem" fontSize={"1.2rem"} fontWeight={"600"}>Your stores</Text>
        <CustomTabs data={currentUser?.role === "Supervisor" ? data2 : data} />
      </Box>
    </>
  )
}

export default Stores;