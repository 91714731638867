import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {BiStoreAlt} from 'react-icons/bi'
import {AiTwotoneAppstore} from 'react-icons/ai'
import Bell from '../../assets/icons/bell.svg'
import { HamburgerIcon } from '@chakra-ui/icons'
import CustomDrawer from '../customDrawer/customDrawer'
import PrimaryButton from '../primaryButton/primarybutton'
import { useLocation, useNavigate } from 'react-router-dom'
import Home from '../menuBar/icons/home.png'
import Attendance from '../menuBar/icons/ranking.png'
import Sales from '../menuBar/icons/money-send.png'
import Tasks from '../menuBar/icons/note.png'
import Messages from '../menuBar/icons/message.png'
import { useDispatch, useSelector } from 'react-redux'
import { userActionTypes } from '../../redux/constants/userActionTypes'
import Inventory from '../../pages/store/initiative/inventory/inventory'
import axiosInstance from '../../services/api'
import { io } from 'socket.io-client'
import { baseUrl2 } from '../../config'


const Navbar = ({title, showIcon}) => {
  const state = useLocation()
  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const id = state?.pathname?.split('/')[1]
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const toast = useToast()

  const [triggerClose, setTriggerClose] = useState("")
  const [trigger, setTrigger] = useState(Math.random())
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)

    const socket = io(baseUrl2, {
        transports: ["websocket"]
        })

    useEffect(() => {
        socket.on("connect", () => {
            socket.emit("setSocketId", {userId: currentUser?._id, role: currentUser?.role})
            // console.log(socket.connected);
        });
    }, []);

    useEffect(() => {
        socket.on("newNotification", (msg) => {
            setTrigger(Math.random())
        })
    }, [])

  const getNotifications = async () => {
    setLoading(true)
    try {
        const {data} = await axiosInstance.get("/notifications/in-app")
        // console.log('notifications', data?.data)
        setNotifications(data?.data)
    } catch (error) {
        console.log(error?.response)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getNotifications()
}, [trigger])

  const menuData = [
    {
        title: "Home",
        icon: Home,
        link: `/${id}/dashboard`
    },
    {
        title: "Shelf Inventory",
        icon: Home,
        link: `/${id}/dashboard/initiative?index=3`
    },
    {
        title: "Store Inventory",
        icon: Home,
        link: `/${id}/dashboard/initiative?index=5`
    },
    {
        title: "Attendance",
        icon: Attendance,
        link: `/${id}/attendance`
    },
    {
        title: "Shelf & POSM",
        icon: Sales,
        link: `/${id}/dashboard/initiative?index=2`
    },
    {
        title: "Sales",
        icon: Sales,
        link: `/${id}/dashboard/initiative?index=6`
    },
    {
        title: "Competitors",
        icon: Sales,
        link: `/${id}/dashboard/initiative?index=7`
    },
    {
        title: "Orders",
        icon: Sales,
        link: `/${id}/dashboard/initiative?index=9`
    },
    {
        title: "Payments",
        icon: Sales,
        link: `/${id}/dashboard/initiative?index=10`
    },
    {
      title: "MSL",
      icon: Sales,
      link: `/${id}/dashboard/initiative?index=9`
  },
  {
      title: "SOS",
      icon: Sales,
      link: `/${id}/dashboard/initiative?index=9`
  },
    {
        title: "Shipments",
        icon: Sales,
        link: `/${id}/dashboard/initiative?index=4`
    },
    {
        title: "Price Checks",
        icon: Sales,
        link: `/${id}/dashboard/initiative?index=8`
    },
    // currentUser?.role === "Supervisor" &&
    // {
    //     title: "Promoter Activities",
    //     icon: Sales,
    //     link: `/${id}/promoter`
    // },
    {
        title: "Tasks",
        icon: Tasks,
        link: `/${id}/tasks`
    },
    {
        title: "Surveys",
        icon: Sales,
        link: `/${id}/surveys`
    },
    {
        title: "Message Center",
        icon: Messages,
        link: `/${id}/messages`
    },
    {
        title: "Games & Rewards",
        icon: Messages,
        link: `/${id}/rewards`
    },
    {
        title: "Settings",
        icon: Messages,
        link: `/${id}/settings`
    }
]

  const handleClick = (link) => {
    if(link?.includes('initiative')) {
      navigate(link, {state: {index: 3}})
    } else {
      navigate(link)
    }
    setTriggerClose('close')
  }

  const handleClockOut = async() => {
    setLoadingx(true)
    const datax = {
      clockOutTime: new Date().toISOString()
    }
    try {
      const { data } = await axiosInstance.post('/attendances/clock-out', datax)
      navigate('/')
      dispatch({type: userActionTypes.SHOW_MENU, payload: false})
      toast({
        title: "",
        description: data?.message,
        status: "success",
      });
    } catch(error) {
      if(error?.response?.data?.message === "You are not currently clocked-in") {
        navigate('/')
      } else {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
        });
      }
      
    } finally {
      setLoadingx(false)
    }
}

  return (
    <Flex position={"absolute"} zIndex={"10"} padding={"1rem"} width={"100%"} bg="#FFF" justifyContent={"space-between"} alignItems={"center"}>
        <Text color="#475467" fontWeight={"600"} letterSpacing={"-1px"} fontSize={"1.25rem"}>{title} {showIcon && <BiStoreAlt color='#475467' style={{display: "inline", transform: "translateY(2px)", marginLeft: ".5rem"}} />}</Text>
        <Flex alignItems={"center"} gap="1rem">
            {/* <Image onClick={() => navigate(`/${id}/notifications`)} transform={"scale(.9)"} cursor={"pointer"} src={Bell} /> */}
            <Box position={"relative"} onClick={() => navigate(`/${id}/notifications`)}>
              <Image transform={"scale(.95)"} cursor={"pointer"} src={Bell} />
              {notifications.filter((i) => !i?.isRead).length > 0 && (
                <Box
                  position={"absolute"}
                  top="0"
                  right={"12%"}
                  height={"8px"}
                  width="8px"
                  borderRadius={"50%"}
                  backgroundColor="#FD4207"
                ></Box>
              )}
            </Box>
            <CustomDrawer
                position={"top"} 
                triggerClose={triggerClose}
                title={""} 
                toggleElement={
                  <HamburgerIcon color="#71717A" cursor={"pointer"} fontSize={"1.3rem"} />
                }
                content={
                <>
                    {menuData?.map(({title, icon, link}) => (
                      <Flex key={title} justifyContent={"center"} mb="1rem">
                        <Flex cursor={"pointer"} onClick={() => handleClick(link)} alignItems={"center"}>
                          <AiTwotoneAppstore color='#282828' style={{transform: "rotate(45deg)", marginRight: ".3rem"}} />
                          <Text fontWeight={"500"} color={"#475467"}>{title}</Text>
                        </Flex>
                      </Flex>
                      
                    ))}
                    <PrimaryButton my="1rem" onClick={handleClockOut} text="Clock out" icon="exit" loading={loadingx} />
                    <br /><br /><br /><br /><br /><br /><br />
                </>
                }
            />
        </Flex>
    </Flex>
  )
}

export default Navbar