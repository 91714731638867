import { AiFillPropertySafety } from "react-icons/ai";
import axiosInstance from "../services/api";

export default function LightenDarkenColor(col, amt) {
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  var b = ((num >> 8) & 0x00ff) + amt;
  var g = (num & 0x0000ff) + amt;
  var newColor = g | (b << 8) | (r << 16);
  return newColor.toString(16);
}

export function truncateText(text, length) {
  let newText = text;
  if (text?.length > length) {
    newText = text?.slice(0, length);
    return newText + "...";
  }
  return newText;
}

export function convertToProperObject(data) {
  const formattedData = {};

  for (const key in data) {
    const unescapedKey = JSON.parse(key); // Remove the extra quotes using JSON.parse
    formattedData[unescapedKey] = data[key];
  }
  return formattedData;
}

export function removeQuotes(inputString) {
  return inputString.replace(/"/g, "");
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const teamOptions = [
  {
    title: "",
    value: "",
  },
];

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getDateRelativeToPresetDay = (presetDate, offset) => {
  const presetDay = new Date(presetDate); // Example: Change this to your preset day
  const date = new Date(presetDay);
  date.setDate(presetDay.getDate() + offset);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function buildResourceURL(baseURL, queryParams) {
  const queryString = queryParams
    .filter(
      ({ value }) => value !== "" && value !== null && value !== undefined
    )
    .map(
      ({ key, value }) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  const finalURL = queryString ? `${baseURL}?${queryString}` : baseURL;

  return finalURL;
}

export function buildResourceURL2(baseURL, queryParams) {

  const queryString = queryParams
    .filter(({ value }) => value !== "" && value !== null && value !== undefined)
    .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const finalURL = queryString ? `${baseURL}&${queryString}` : baseURL;

  return finalURL;
}

export const saveOffline = (type, data) => {
  localStorage.setItem(type, data);
};

export const rerunOffline = async (type) => {
  const data = localStorage.getItem(type);
  const finalData = JSON.parse(data);
  // const finalDatax = finalData?.map(x => ({
  //   uid: Math.random().toString(36).substr(2, 9),
  //   ...x
  // }))

  finalData?.forEach(async (item) => {
    try {
      const { data } = await axiosInstance.post("/survey-responses", item);
      const newData = finalData?.filter(x => x.uid !== item.uid);
      localStorage.setItem(type, JSON.stringify(newData));
    } catch (error) {
      console.error(error);
      // saveOffline('survey', JSON.stringify(myObj)\
    }
  });
};
