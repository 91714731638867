import { Box, Heading, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userActionTypes } from "../../../redux/constants/userActionTypes";
import axiosInstance from "../../../services/api";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import GoBack from "../../../components/goBack";
import CustomInput from "../../../components/customInput/customInput";
import OtpInput from "react-otp-input";

const ResetPassword = () => {

    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const {state} = useLocation()

    const toast = useToast();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const datax = {
        email: state?.email,
        password,
        token
    }

    const resetPassword = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const {data} = await axiosInstance.post("auth/reset-password", datax)
            navigate("/verify/success")
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }


    return (
        <Box>
            <GoBack />
            <Box padding="1rem">
                <Heading mt={6} fontWeight={500} fontSize="1.3rem" color="#030303" fontFamily='Roboto'>Create new password</Heading>
                <Text mt={2} mb="2rem" color={"#667085"} fontSize={".9rem"}>Enter and confirm new password</Text>
                    <CustomInput 
                    type={"password"}
                    required
                    placeholder={"Password"}
                    mb={".8rem"}
                    onChange={e => setPassword(e.target.value)}
                    />
                   <OtpInput
                        containerStyle={{
                            display: "flex",
                            margin: "0 auto",
                            justifyContent: "space-between"
                        }}
                        inputStyle={{
                            height: "60px",
                            width: "100%",
                            background: "#F8F8F8",
                            color: "#161C2C",
                            fontSize: "1.1rem",
                        }}
                        focusStyle={{
                            boxShadow: "none",
                            outline: "none",
                        }}
                        value={token}
                        onChange={e => setToken(e)}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        placeholder={"******"}
                    />
                    <br />
                    <PrimaryButton type="submit" text="Create password" icon="next" func={resetPassword} loading={loading} />
                    <Text mt="2rem" textAlign={"center"} fontSize={".9rem"} color="#475467">Already have an account? <span onClick={() => navigate("/login", {state: {userType: state?.userType}})} style={{fontWeight: "600", color: "#7F56D9", cursor: "pointer", transition: "250ms ease"}}>Login here</span></Text>
            </Box>
        </Box>
    )
}

export default ResetPassword;

