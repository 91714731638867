import React, { useState } from "react";
import { FormControl, FormLabel, Input, InputGroup, InputRightElement, Text, Textarea } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const CustomTextArea = ({ placeholder, type, label, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (!event.target.value) {
      setIsFocused(false);
    }
  };

  return (
    <FormControl>
          {label && <FormLabel fontSize={"13px"} bg="#FFF" width={"fit-content"} transform={"translateY(5px)"}>{label}</FormLabel>}
    <InputGroup position={"relative"} className={isFocused ? "focused" : ""}>
      <Textarea
        py="1.8rem"
        px="1rem"
        rows={"5"}
        zIndex={"6"}
        onFocus={handleFocus}
        borderColor={"#667085"}
        onBlur={handleBlur}
        _focus={{ borderColor: "#F9F5FF" }}
        _placeholder={{ opacity: 1 }}
        type={showPassword ? "text" : type}
        {...props}
      />
      {type === "password" && <InputRightElement zIndex={"7"} mt=".65rem" children={showPassword ? <ViewIcon color={"#8F939B"} cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} /> : <ViewOffIcon color={"#8F939B"} cursor={"pointer"} onClick={() => setShowPassword(!showPassword)} />} />}
    </InputGroup>
    </FormControl>
  );
};

export default CustomTextArea;
