import { Box, Flex, Text } from '@chakra-ui/react'
import CustomTabs from '../../../components/customTabs/customTabs';
import Map from '../../../components/mapView/mapView';
import { BsDot } from 'react-icons/bs';
import CopyButton from '../../../components/copyButton';
import moment from 'moment';
import { useState } from 'react';
import { FaPencilAlt } from "react-icons/fa";
import { HiArrowNarrowLeft } from 'react-icons/hi';
import EditStore from './editStore';

const ViewStore = ({data, setRefetch, setTriggerClose, src}) => {
  const [isEdit, setIsEdit] = useState(false)
  

  const datax = [
      {
          title: "Store Image",
          render: <Box height={"250px"} backgroundImage={data?.imageURL} backgroundSize={"cover"}></Box>
      },
      {
          title: "Map View",
          render: <Map coordinates={data?.location?.coordinates} height={"250px"} />
      }
  ]

  const storeDetails = [
    {
      title: "Outlet Name",
      value: data?.name,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Phone",
      value: data?.phoneNumber,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Location",
      value: `${data?.area}, ${data?.state}`,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Category",
      value: data?.category,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Type",
      value: data?.type,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Level",
      value: data?.level,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Contact Name",
      value: data?.ownerName,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Contact Phone",
      value: data?.ownerPhoneNumber,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Contact Email",
      value: data?.ownerEmail,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Brand",
      value: data?.brand,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Additional Information",
      value: data?.additionalInfo,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Products Source",
      value: data?.productSource,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Purchase Willingness",
      value: data?.purchaseWillingness,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Buying Preferences",
      value: data?.buyingPreferences?.join(', '),
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Status",
      value: data?.isApproved ? "Approved" : "Pending Approval",
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Date Created",
      value: moment(data?.createdAt).calendar(),
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Team",
      value: `Team ${data?.team}`,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Comments",
      value: data?.comment,
      icon: <BsDot color='#98A2B3' />
    },
  ]

  return (
    <Box>
      {src !== 'supervisor' &&
      <>
      {data?.isApproved === false &&
        <Flex onClick={() => setIsEdit(!isEdit)} cursor={"pointer"} my=".5rem" justifyContent={"flex-end"}>
       {isEdit === false ? 
       <FaPencilAlt style={{transform: "translateY(2px)", marginRight: ".3rem"}} />
        :
        <HiArrowNarrowLeft style={{transform: "translateY(2px)", marginRight: ".3rem"}} />}
        <Text fontSize={".85rem"} fontWeight={"500"}>{isEdit === true ? "View" : "Edit"} Outlet</Text>
      </Flex>}
      </>}

      {isEdit === false ?
        <>
      <CustomTabs data={datax} />
      <Box mt="1rem">
        {storeDetails?.map(({title, value, icon}) => (
          <Flex key={title} mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
            <Flex alignItems={"center"} gap="1rem">
              {/* {icon} */}
              {value && 
              <Box>
                <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>{title}</Text>
                <Text color={"#101928"} fontSize={".9rem"} fontWeight={"500"}>{value}</Text>
              </Box>}
            </Flex>
            {title === "Store ID" && <CopyButton toCopy={value} />}
          </Flex>
        ))}
      </Box>
      </>
      :
      <>
        <EditStore storeData={data} setRefetch={setRefetch} setTriggerClose={setTriggerClose} />
      </>
      }
    </Box>
  )
}

export default ViewStore;