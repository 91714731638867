import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import Shelf from './shelf/shelf';
import Posm from './posm/posm';
import CustomTabs from '../../../../components/customTabs/customTabs';
import axiosInstance from '../../../../services/api';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../../../utils/helper';

const ShelfAndPosm = ({data}) => {
    const state = useLocation()
    const id = state?.pathname?.split('/')[1]
    const [docs, setDocs] = useState([])
    const [loading, setLoading] = useState(false)
    const [refetch, setRefetch] = useState("")


    const [selectedDate, setSelectedDate] = useState(new Date());


    // Get the previous day
    const previousDay = new Date(selectedDate);
    previousDay.setDate(selectedDate.getDate() - 1);
    const previousDayFormatted = formatDate(previousDay);

    // Get the next day
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);
    const nextDayFormatted = formatDate(nextDay);


    const getDocs = async() => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(`/shelf-and-posm?initiativeStore=${id}&createdAt[gte]=${previousDayFormatted}&createdAt[lt]=${nextDayFormatted}`)
            setDocs(res?.data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDocs()
    }, [refetch, selectedDate])

    const datax1 = [
        {
            title: "Shelf",
            render: <Shelf data={docs?.filter(i => i?.type === 'shelf')} storeState={data?.store?.state} setRefetch={setRefetch} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
        },
        {
            title: "POSM",
            render: <Posm data={docs?.filter(i => i?.type === 'posm')} storeState={data?.store?.state} setRefetch={setRefetch} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
        }
    ]
  return (
    <Box>
        
        <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Examples</Heading>
        <Text mt=".2rem" color={"#000"} fontSize={".8rem"}>What shelf and POSM should look like for this brand</Text>
        <Flex mt=".7rem" gap="1.1rem" justifyContent="space-between" overflowX={"scroll"} sx={
            { 
            '::-webkit-scrollbar':{
                display:'none'
            }
            }
        }>
            {data?.initiative?.shelfImages?.map((i) => (
                <Box flex="1 0 150px">
                    <Box bg="lightgray" backgroundImage={i} backgroundSize={"cover"} height={"114px"} borderRadius={"4px"} />
                </Box>
            ))}
            {data?.initiative?.posmImages?.map((i) => (
                <Box flex="1 0 150px">
                    <Box bg="lightgray" backgroundImage={i} backgroundSize={"cover"} height={"114px"} borderRadius={"4px"} />
                </Box>
            ))}
        </Flex>
        <Heading mt="2.5rem" mb=".8rem" fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Uploads</Heading>
        <CustomTabs data={datax1} />
    </Box>
  )
}

export default ShelfAndPosm;