import { Box, Flex, IconButton, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/api'
import { useSelector } from 'react-redux'
import { HiOutlineArrowNarrowRight, HiUpload } from 'react-icons/hi'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import EmptyState from '../../../components/emptyState/emptyState'
import SkeletonLoader from '../../../components/skeletonLoader/skeletonLoader'
import CustomDrawer from '../../../components/customDrawer/customDrawer'
import { AddIcon } from '@chakra-ui/icons'
import CreateReport from './createReport'
import { truncateText } from '../../../utils/helper'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import EditReport from './editReport'

const Reports = () => {
    const [reports, setReports] = useState([])
    const [loading, setLoading] = useState(true)
    const [triggerClose, setTriggerClose] = useState('')
    const [refetch, setRefetch] = useState('')


    const [loadingc, setLoadingc] = useState(false)

    const currentUser = useSelector(({userData})=>  userData?.currentUser);
    const toast = useToast()

    const getReports = async() => {
        try {
          const {data} = await axiosInstance.get(`/reports?user=${currentUser?._id}`)
          setReports(data?.data)
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
          });
        } finally {
          setLoading(false)
        }
      }
    
      useEffect(() => {
        getReports()
      }, [loadingc, refetch])


  return (
    <Box height={"81vh"} overflowY={"scroll"}
    sx={
        { 
       '::-webkit-scrollbar':{
              display:'none'
          }
       }
     }>
        {loading ?
        <SkeletonLoader />
        :
        reports?.length < 1 ?
        <Flex justifyContent={"center"}>
         <Box>
            <EmptyState text={"No reports created yet"} />
            <CustomDrawer 
              position={"bottom"}
              title={"Add new report"}
              triggerClose={triggerClose}
              toggleElement={
                <PrimaryButton mt="1rem" width="" text="Add new report" icon="add" func={() => {}} loading={loading} />
              }
              content={
                <CreateReport source={'general'} loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} setRefetch={setRefetch} />
              }
            />
         </Box>
        </Flex>
        :
        reports?.map((({name, date, comment, uploadURLs, _id: id, initiative}) => {
                  const data = {name, date, comment, uploadURLs, _id: id, initiative}
            return (
                // <a href={uploadURLs[0]} target="_blank" rel="noopener noreferrer">
                <Flex position={"relative"} mb=".5rem" key={id} justifyContent={"space-between"} cursor={"pointer"} alignItems={"center"} p=".8rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                    <Flex alignItems={"center"} gap=".8rem">
                      <Flex width={"60px"} height={"50px"} borderRadius={"8px"} border="1px dashed lightgray" alignItems={"center"} justifyContent={"center"}>
                        <HiUpload opacity={".7"} />
                      </Flex>
                      <Box>
                          <Text mb=".3rem" fontSize={".92rem"} color="#475467" fontWeight={"600"} textTransform={"capitalize"}>{name}</Text>
                          <Text fontSize={".8rem"} color="#667085" fontWeight={"500"}>{truncateText(comment, 25)}</Text>
                      </Box>
                    </Flex>
                      <CustomDrawer
                          position={"bottom"} 
                          title={`Edit Report`} 
                          triggerClose={triggerClose}
                          toggleElement={
                            <BiDotsVerticalRounded style={{cursor: "pointer", transform: "translateY(-1.2rem)"}} />
                          }
                          content={
                            <EditReport source={"general"} datay={data} loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} setRefetch={setRefetch} />
                          }
                      />
                      <Text position={"absolute"} bottom={"12%"} right={"3%"} fontSize={".75rem"} color="#667085" fontWeight={"500"}>{date}</Text>
                </Flex>
                // </a>
            )
        }))
        }
        {reports?.length > 0 &&
        <CustomDrawer 
          position={"bottom"}
          title={"Add new report"}
          triggerClose={triggerClose}
          toggleElement={
            <IconButton
            position={"absolute"} 
            bottom={"5%"}
            right={"5%"}
            bg="#000000" 
            boxShadow="0px 4px 24px 0px #0000000D" 
            color="#FFF" borderRadius={"50%"} 
            boxSizing='border-box' 
            padding={"1.5rem 1rem"}
            _hover={{backgroundColor: "#000", color: "#FFF"}} 
            aria-label='Add new store' 
            icon={<AddIcon />} 
            />
          }
          content={
            <CreateReport source={'general'} loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} setRefetch={setRefetch} />
          }
        />
        }
    </Box>
  )
}

export default Reports