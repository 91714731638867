import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { QrReader } from 'react-qr-reader';
import axiosInstance from "../../../services/api";
// import { BsQrCodeScan } from "react-icons/bs";
import SpinLoader from "../../../components/spinLoader/spinLoader";
import CustomInput from "../../../components/customInput/customInput";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import ImageUploaderRaw from "../../../components/imageUploader/imageUploaderRaw";
import CustomSelect from "../../../components/customSelect/customSelect";

const conditions = [
    {
      title: "Poor",
      value: "POOR"
    },
    {
      title: "Average",
      value: "AVERAGE"
    },
    {
      title: "Good",
      value: "GOOD"
    }
  ]

const QrScanner = ({setRefetch, idx, setIdx, refetch, setTriggerClose}) => {
  const [result, setResult] = useState(idx);
  const [delay] = useState(100); // Delay can be set dynamically if needed.
  const [loading, setLoading] = useState(false);
  const [loadingu, setLoadingu] = useState(false);

  const [assetData, setAssetData] = useState(null)
  

  const [inventory, setInventory] = useState('');
  const [msl, setMsl] = useState('');
  const [condition, setCondition] = useState('');
  const [comment, setComment] = useState('');

  const [loadingx, setLoadingx] = useState('')
  

  const [imageURL, setImageURL] = useState('');
  const [coordinates, setCoordinates] = useState(null);

  const qrRef = useRef(null);

  const toast = useToast();

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude })
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  useEffect(() => {
    fetchUserLocation();
  }, []);

  

  const scanAsset = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/assets/${result}`);
      setAssetData(data?.data);
      setImageURL(data?.data?.assetsImage);
      setInventory(data?.data?.assetsInventory?.inventoryLevel)
      setMsl(data?.data?.assetsMsl?.mslLevel)
      setComment(data?.data?.assetsComment?.comment)
      setCondition(data?.data?.assetsCondition?.condition)
    } catch (error) {
        if(error?.response?.data?.message) {
            toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
        }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (src, payload, url) => {
    setLoadingx(src);
    try {
      const { data } = await axiosInstance.post(url, payload);
      console.log(data);
      toast({
        position: 'top',
        description: data?.message,
        status: "success",
      });
      setRefetch(Math.random());
      setIdx('');
    } catch (error) {
        if(error?.response?.data?.message) {
            toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
        }
      
    } finally {
      setLoadingx('');
    }
  };

  const handleUpdate = async (src, payload, url) => {
    setLoadingx(src);
    try {
      const { data } = await axiosInstance.patch(url, payload);
      toast({
        position: 'top',
        description: data?.message,
        status: "success",
      });
      setRefetch(Math.random());
      setIdx('');
    } catch (error) {
        if(error?.response?.data?.message) {
            toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
        }
      
    } finally {
      setLoadingx('');
    }
  };

  const handleImagesUpload = (images) => {
    const formData = new FormData()
    formData.append('image', images?.raw[0])
    formData.append('assetId', result)
    formData.append('latitude', coordinates?.latitude)
    formData.append('longitude', coordinates?.longitude)

    const formData2 = new FormData()
    formData2.append('image', images?.raw[0])
    formData2.append('assetId', result)

    setImageURL(images?.urls[0])
    if(imageURL === null) {
        handleSubmit('upload', formData, '/assets/upload/image')
    } else {
        handleUpdate('update', formData2, `/assets/update/image/${assetData?.assetsImage?._id}`)
    }
    
  };

  useEffect(() => {
    if (result !== "") {
      scanAsset();
    }
  }, [result, refetch]);

  const previewStyle = {
    height: 150,
    width: "100%",
    backgroundColor: "",
    border: "1px dashed gray",
    borderRadius: "6px",
    cursor: "pointer",
  };

  const inventoryPayload = {
    assetId: assetData?.asset?._id || result,
    inventory: inventory,
    location: {
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude
    }
  }
  const inventoryPayload2 = {
    assetId: assetData?.asset?._id || result,
    inventory: inventory,
  }

  const mslPayload = {
    assetId: assetData?.asset?._id || result,
    mslLevel: msl,
    location: {
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude
    }
  }
  const mslPayload2 = {
    assetId: assetData?.asset?._id || result,
    mslLevel: msl,
  }

  const conditionPayload = {
    assetId: assetData?.asset?._id || result,
    condition: condition,
    location: {
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude
    }
  }
  const conditionPayload2 = {
    assetId: assetData?.asset?._id || result,
    condition: condition,
  }

  const commentPayload = {
    assetId: assetData?.asset?._id || result,
    comment: comment,
    location: {
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude
    }
  }
  const commentPayload2 = {
    assetId: assetData?.asset?._id || result,
    comment: comment,
  }

  const close = () => {
    console.log('fire')
    setTriggerClose('close' + (Math.random()));
  }

  return (
    <>
      {result === "" ? (
        <Box position={"relative"}>
          {/* <BsQrCodeScan
            fontSize={"4rem"}
            opacity={".7"}
            style={{ position: "absolute", top: "12%", left: "43%" }}
          />
          <Text
            position={"absolute"}
            left={"39.5%"}
            fontSize={".85rem"}
            opacity={".7"}
            fontWeight={"600"}
            color={"#333"}
            bottom={"8%"}
          >
            Scan QR Code
          </Text> */}
          <QrReader
            ref={qrRef}
            delay={delay}
            style={previewStyle}
            onResult={(result, error) => {
              if (!!result) {
                setResult(result?.text);
              }
              if (!!error) {
                console.info(error);
              }
            }}
            constraints={{facingMode: "environment"}}
          />
          <p>{result}</p>
        </Box>
      ) : loading ? (
        <SpinLoader mt="1rem" />
      ) :
      assetData &&
       (
        <>
         <Flex borderRadius={"6px"} gap=".5rem" alignItems={"center"} border="1px solid lightgray" mb="1rem" p=".5rem">
            <Box height={"70px"} width={"80px"} borderRadius={"4px"} backgroundImage={assetData?.asset?.image} backgroundSize={"cover"} backgroundPosition={"center"} />
            <Box>
                <Text fontSize={".9rem"}>Brand: <strong style={{textTransform: 'capitalize', opacity: '.7'}}>{assetData?.asset?.brand}</strong></Text>
                <Text fontSize={".9rem"}>Category: <strong style={{textTransform: 'capitalize', opacity: '.7'}}>{assetData?.asset?.category}</strong></Text>
                <Text fontSize={".9rem"}>Type: <strong style={{textTransform: 'capitalize', opacity: '.7'}}>{assetData?.asset?.type}</strong></Text>
            </Box>
         </Flex>


          <Flex mb=".8rem" gap=".5rem">
            {imageURL?.image !== "" && (
              <Box
              backgroundColor={"lightgray"}
                backgroundImage={imageURL?.image}
                backgroundSize={"cover"}
                height={"100px"}
                width={"120px"}
                borderRadius={"4px"}
              />
            )}
            <ImageUploaderRaw
              onImagesUpload={handleImagesUpload}
              setLoading={setLoadingu}
              loading={loadingu}
              max={1}
              src="camera"
            />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
            <CustomInput
                type={"tel"}
                placeholder={"Inventory"}
                onChange={e => setInventory(e.target.value)}
                mb="0"
                mt="0"
                value={inventory}
            />
            <PrimaryButton onClick={assetData?.assetsInventory === null ? () => handleSubmit('inventory', inventoryPayload, '/assets/create/inventory') : () => handleUpdate('inventory', inventoryPayload2, `/assets/update/inventory/${assetData?.assetsInventory?._id}`)} width="100px" py="1.563rem" borderRadius="6px" text={assetData?.assetsInventory === null ? 'Add' : 'Update'} func={() => {}} loading={loadingx === 'inventory' ? true : false} />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
            <CustomInput
                type={"tel"}
                placeholder={"MSL"}
                onChange={e => setMsl(e.target.value)}
                mb="0"
                mt="0"
                value={msl}
            />
            <PrimaryButton onClick={assetData?.assetsMsl === null ? () => handleSubmit('msl', mslPayload, '/assets/create/msl') : () => handleUpdate('msl', mslPayload2, `/assets/update/msl/${assetData?.assetsMsl?._id}`)} width="100px" py="1.563rem" borderRadius="6px" text={assetData?.assetsMsl === null ? 'Add' : 'Update'} func={() => {}} loading={loadingx === 'msl' ? true : false} />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
          <CustomSelect
            required
            options={[...conditions?.filter(x => x.value?.toLowerCase() === condition?.toLowerCase()), ...conditions?.filter(x => x.value?.toLowerCase() !== condition?.toLowerCase())]}
            // placeholder={condition}
            onChange={e => setCondition(e.target.value)}
            value={condition}
          />
            <PrimaryButton onClick={assetData?.assetsCondition === null ? () => handleSubmit('condition', conditionPayload, '/assets/create/condition') : () => handleUpdate('condition', conditionPayload2, `/assets/update/condition/${assetData?.assetsCondition?._id}`)} width="100px" py="1.563rem" borderRadius="6px" text={assetData?.assetsCondition === null ? 'Add' : 'Update'} func={() => {}} loading={loadingx === 'condition' ? true : false} />
          </Flex>
          <Flex gap={'.5rem'} mb=".7rem">
            <CustomInput
                type={"text"}
                placeholder={"Comment"}
                onChange={e => setComment(e.target.value)}
                mb="0"
                mt="0"
                value={comment}
            />
            <PrimaryButton onClick={assetData?.assetsComment === null ? () => handleSubmit('comment', commentPayload, '/assets/create/comment') : () => handleUpdate('comment', commentPayload2, `/assets/update/comment/${assetData?.assetsComment?._id}`)} width="100px" py="1.563rem" borderRadius="6px" text={assetData?.assetsComment === null ? 'Add' : 'Update'} func={() => {}} loading={loadingx === 'comment' ? true : false} />
          </Flex>
          
          <Flex justifyContent={"center"} mt={"1rem"}>
            <PrimaryButton onClick={close} width="200px" py="1.563rem" borderRadius="6px" text={'Finish'} func={close} />
          </Flex>
        </>
        
      )}
    </>
  );
};

export default QrScanner;