import './App.css';
import { Routes, Route, useLocation } from "react-router-dom"
import { useEffect, useState } from 'react';
import { loaduser } from './redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Spinner } from '@chakra-ui/react';
import { userActionTypes } from './redux/constants/userActionTypes';
import Stores from './pages/stores/stores';
import Auth from './pages/auth/auth';
import Login from './pages/auth/login/login';
import Signup from './pages/auth/signup/signup';
import ForgotPassword from './pages/auth/forgotPassword/forgotPassword';
import ResetPassword from './pages/auth/forgotPassword/resetPassword';
import Verify from './pages/auth/verify/verify';
import VerifySuccess from './pages/auth/verifySuccess';
import StoreFront from './pages/stores/initiativeStores/storeFront/storeFront';
import ClockIn from './pages/stores/initiativeStores/clockIn/clockIn';
import Store from './pages/store/store';
import StoreDashboard from './pages/store/store';
import MenuBar from './components/menuBar/menuBar';
import Initiative from './pages/store/initiative/initiative';
import Attendance from './pages/store/attendance/attendance';
import Tasks from './pages/store/tasks/tasks';
import Messages from './pages/store/messages/messages';
import MessageWindow from './pages/store/messages/messageWindow/messageWindow';
import Notifications from './pages/store/notifications/notifications';
import ChangePassword from './pages/settings/components/changePassword';
import Profile from './pages/profile/profile';
import Promoter from './pages/promoter';
import Rewards from './pages/store/rewards/rewards';
import GameView from './pages/store/rewards/gameView/gameView';
import Surveys from './pages/surveys/surveys';
import QuickResponse from './pages/quickResponse/quickResponse';
import Games from './pages/store/rewards';



const payload = {
  meetingNumber: 84481641352,
  role: 0,
  sdkKey: 'IyeYz1WiTKCfAAs8rHIxLA',
  sdkSecret: 'aXlxqDZNzIA6YXUbdwCFcVuTdEz1txkE',
  passWord: 'J8tUJU',
  userName: 'Tech support',
  userEmail: '',
  leaveUrl: 'https://localhost:3000',
}

function App() {
  const dispatch = useDispatch();

  const loading = useSelector(({ userData }) => userData?.isLoading);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const showMenu = useSelector(({ userData }) => userData?.showMenu);
  const iid = useSelector(({ userData }) => userData?.initiativeId);

  const [initiative, setInitiative] = useState(null)

  console.log(initiative)

  const state = useLocation()

  useEffect(() => {
    if (localStorage.PRV1TK) {
      dispatch(loaduser())
    } else {
      dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null })
    }
  }, [])


  const userId = currentUser?._id
  const adminId = process.env.REACT_APP_ADMIN_CHAT_ID;

  return (
    <>
      {
        currentUser && userId !== adminId && <QuickResponse />
      }
      {loading ?
        <Flex marginTop={"20rem"} justifyContent={"center"}>
          <Spinner size={"lg"} color="#7F56D9" />
        </Flex>
        :
        <div className="App">
          <Routes>
            <Route path='/' element={currentUser ? <Stores /> : <Auth />} />
            <Route path='/login' element={<Login />} />
            <Route path='/stores' element={<Stores />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/forgot-password/reset' element={<ResetPassword />} />
            <Route path='/verify' element={<Verify />} />
            <Route path='/verify/success' element={<VerifySuccess />} />
            <Route path='/:id' element={<StoreFront />} />
            <Route path='/:id/clock-in' element={<ClockIn />} />
            <Route path='/:id/dashboard' element={<StoreDashboard setInitiative={setInitiative} />} />
            <Route path='/:id/dashboard/initiative' element={<Initiative />} />
            <Route path='/:id/attendance' element={<Attendance source={"main"} />} />
            <Route path='/:id/notifications' element={<Notifications />} />
            <Route path='/:id/tasks' element={<Tasks source={"in"} />} />
            <Route path='/:id/rewards' element={<Games />} />
            <Route path='/:id/rewards/:rid' element={<GameView />} />
            <Route path='/:id/messages' element={<Messages />} />
            <Route path='/:id/surveys' element={<Surveys src={"in"} initiativeId={initiative?.initiative?._id || iid} />} />
            <Route path='/:id/messages/:id' element={<MessageWindow />} />
            <Route path='/:id/settings' element={<ChangePassword />} />
            <Route path='/:id/promoter' element={<Promoter />} />
            <Route path='/profile' element={<Profile />} />
          </Routes>
          {showMenu && state?.pathname !== '/' && !state?.pathname?.includes('clock-in') && state?.pathname?.length !== 25 && !state?.pathname?.includes('profile') && <MenuBar />}
        </div>}
    </>
  );
}

export default App;