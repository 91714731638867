import { Box, Flex, IconButton, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SkeletonLoader from '../../../components/skeletonLoader/skeletonLoader'
import EmptyState from '../../../components/emptyState/emptyState'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { AddIcon } from '@chakra-ui/icons'
import CustomDrawer from '../../../components/customDrawer/customDrawer'
import axiosInstance from '../../../services/api'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Navbar from '../../../components/navbar/navbar'
import CreateMessage from './createMessage'
import moment from 'moment'
import { truncateText } from '../../../utils/helper'



const Messages = () => {
  const state = useLocation()
  const id = state?.pathname?.split('/')[1]

  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingc, setLoadingc] = useState(false)
  const [refetch, setRefetch] = useState("")
  const [triggerClose, setTriggerClose] = useState("")
  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  const toast = useToast()
  const navigate = useNavigate()

  

  const getStores = async() => {
    setLoading(true)
    try {
      const {data} = await axiosInstance.get(`messages?initiativeStore=${id}&sort=-updatedAt`)
      setMessages(data?.data)
    } catch(error) {
      // toast({
      //   title: "An Error Occurred",
      //   description: error?.response?.data?.message,
      //   status: "error",
      // });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStores()
  }, [triggerClose])

  return (
    <Box height={"81dvh"} overflowY={"scroll"}
    sx={
        { 
       '::-webkit-scrollbar':{
              display:'none'
          }
       }
     }>
        <Navbar title={"Messages Center"} showIcon={false} />
        <Text pt="3.5rem" px="1rem" color={"#667085"} fontSize={".9rem"}>All your messages in one place</Text>

        <Box mt="1rem" p ="1rem">
        
        {loading ?
        <SkeletonLoader />
        :
        messages?.length < 1 ?
        <Flex justifyContent={"center"}>
         <Box>
            <EmptyState text={"No messages yet"} />
            <CustomDrawer 
              position={"bottom"}
              title={"Create new message"}
              triggerClose={triggerClose}
              toggleElement={
                <PrimaryButton mt="1rem" width="" text="Create new message" icon="add" func={() => {}} loading={loading} />
              }
              content={
                <CreateMessage loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} id={id} />
              }
            />
         </Box>
        </Flex>
        :
        messages?.map(((i) => {
            return (
              <Flex key={i?._id} onClick={() => navigate(`/${id}/messages/${i?._id}`)} cursor={"pointer"} mb=".7rem" alignItems={"center"} gap=".5rem" p=".7rem .7rem .3rem .7rem" boxShadow="0px 4px 24px 0px #0000000D" border="1px solid #D0D5DD" borderRadius={"14px"}>
                <Box width={"100%"}>
                    <Text mb=".3rem" fontSize={".9rem"} color="#475467" fontWeight={"700"} textTransform={"capitalize"}>{truncateText(i?.title, 25)}</Text>
                    <Text fontSize={".85rem"} color="#667085" fontWeight={"500"} textTransform={"capitalize"}>{truncateText(i?.description, 35)}</Text>
                    <Flex justifyContent={"flex-end"}>
                        <Text fontSize={".75rem"} color="#667085" fontWeight={"400"} textTransform={"capitalize"}>{moment(i?.createdAt).calendar()}</Text>
                    </Flex>
                </Box>
              </Flex>
            )
        }))
        }
        {messages?.length > 0 &&
        <CustomDrawer 
          position={"bottom"}
          title={"Create new message"}
          triggerClose={triggerClose}
          toggleElement={
            <IconButton
            position={"absolute"} 
            bottom={"20%"}
            right={"5%"}
            zIndex={"5"}
            bg="#000000" 
            boxShadow="0px 4px 24px 0px #0000000D" 
            color="#FFF" borderRadius={"50%"} 
            boxSizing='border-box' 
            padding={"1.5rem 1rem"}
            _hover={{backgroundColor: "#000", color: "#FFF"}} 
            aria-label='Create new message' 
            icon={<AddIcon />} 
            />
          }
          content={
            <CreateMessage loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} id={id} />
          }
        />
        }
        </Box>
    </Box>
  )
}

export default Messages;















