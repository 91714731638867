import React, { useEffect, useState } from 'react'
import GoBack from '../../../components/goBack';
import { Box, Heading, Text } from '@chakra-ui/react';
import CustomTabs from '../../../components/customTabs/customTabs';
import Mechanics from './mechanics/mechanics';
import ShelfAndPosm from './shelfPosm/shelfPosm';
import Inventory from './inventory/inventory';
import Sales from './sales/sales';
import FixedTitleBar from '../../../components/fixedTitleBar';
import axiosInstance from '../../../services/api';
import { useLocation, useParams } from 'react-router-dom';
import SpinLoader from '../../../components/spinLoader/spinLoader';
import Competitors from './competitors/competitors';
import Attendance from '../attendance/attendance';
import Tasks from '../tasks/tasks';
import PriceChecks from './priceChecks/priceChecks';
import Shipments from './shipments/shipments';
import StoreInventory from './storeInventory/storeInventory';
import Orders from './orders/orders';
import Payments from './payments/payments';
import Msl from './msl/msl';
import Sos from './sos/sos';

const Initiative = () => {
    const state = useLocation()
    const id = state?.pathname?.split('/')[1]
    const title = state?.state?.title
    const [details, setDetails] = useState(null)
    const [loading, setLoading] = useState(false)

    const getInitiativeStoreDetails = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`/initiative-stores/${id}`)
            setDetails(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getInitiativeStoreDetails()
    }, [])

    const data = [
        {
            title: "Mechanics",
            render: <Mechanics data={details} />
        }, 
        {
            title: "Attendance",
            render: <Attendance />
        }, 
        {
            title: "Shelf & POSM",
            render: <ShelfAndPosm data={details} />
        },
        {
            title: "Shelf Inventory",
            render: <Inventory data={details} />
        },
        {
            title: "Shipments",
            render: <Shipments data={details} />
        },
        {
            title: "Store Inventory",
            render: <StoreInventory data={details} />
        },
        {
            title: "Sales",
            render: <Sales data={details} />
        },
        {
            title: "Competitors",
            render: <Competitors data={details} />
        },
        {
            title: "Price Checks",
            render: <PriceChecks data={details} />
        },
        {
            title: "Orders",
            render: <Orders data={details} />
        },
        {
            title: "Payments",
            render: <Payments data={details} />
        },
        {
            title: "MSL",
            render: <Msl data={details} />
        },
        {
            title: "SOS",
            render: <Sos data={details} />
        },
        {
            title: "Tasks",
            render: <Tasks data={details} />
        }
    ]

  return (
    <Box padding={"0 1rem"} overflowY={"scroll"}>
        <FixedTitleBar text={title} />
        {loading ?
        <SpinLoader />
        :
        <Box pt="3.5rem" pb="7rem">
            <CustomTabs defaultIndex={parseInt(state?.search?.slice(-1) === 1 ? '10' : state?.search?.slice(-1)) || 0} data={data} />
        </Box>}
    </Box>
  )
}

export default Initiative;