import { userActionTypes } from '../constants/userActionTypes'
import axiosInstance from '../../services/api';


export const loaduser = (history)=> async dispatch=> {
   try {
      if(localStorage.PRV1TK){
         const {data} = await axiosInstance.get("/employees/profile");
         dispatch({type: userActionTypes.LOADUSER_SUCCESS, payload: data?.data});
         dispatch({type: userActionTypes.SHOW_MENU, payload: true})
      }else{
         localStorage.removeItem('PRV1TK')
         window.location.href = '/'
      }
       
   } catch (error) {
      dispatch({type: userActionTypes.LOADUSER_FAIL, payload: null})
      localStorage.removeItem('PRV1TK')
      window.location.href = '/'
      console.log('heloooooo')
      if(error.response){
         console.log(error.response.data)
         localStorage.removeItem('PRV1TK')
         window.location.href = '/'
      }
   }
}
   