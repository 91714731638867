import { Box, Flex, Heading, Spinner, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userActionTypes } from "../../../redux/constants/userActionTypes";
import axiosInstance from "../../../services/api";
import GoBack from "../../../components/goBack";
import OtpInput from "react-otp-input";
import PrimaryButton from "../../../components/primaryButton/primarybutton";

const Verify = () => {

    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const {state} = useLocation()
    console.log(state)

    const toast = useToast()
    const navigate = useNavigate();

    const verify = async() => {
        setLoading(true)
        const datax = {
            email: state?.email,
            token: otp
        }
        try {
            const {data} = await axiosInstance.post("/auth/verify-email", datax)
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
            navigate("/login")
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    }

    const handleChange = (e) => {
        setOtp(e);
    };

    return (
        <Box>
            <GoBack />
            <Box padding="1rem">
                <Heading mt={6} fontWeight={500} fontSize="1.3rem" color="#030303" fontFamily='Roboto'>Verify your email</Heading>
                <Text mt={2} mb="2rem" color={"#667085"} fontSize={".9rem"}>Enter 6-digit code sent to {state?.email}</Text>
                <Box margin="0 auto">
                    <OtpInput
                        containerStyle={{
                            display: "flex",
                            margin: "0 auto",
                            justifyContent: "space-between"
                        }}
                        inputStyle={{
                            height: "60px",
                            width: "100%",
                            background: "#F8F8F8",
                            color: "#161C2C",
                            fontSize: "1.1rem",
                        }}
                        focusStyle={{
                            boxShadow: "none",
                            outline: "none",
                        }}
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        placeholder={"******"}
                    />
                </Box>
                <br /><br /><br />
                <PrimaryButton type="submit" text="Verify" icon="next" func={verify} loading={loading} />
                <Text mt="2rem" textAlign={"center"} fontSize={".9rem"} color="#475467">Didn’t receive OTP? <span onClick={() => {}} style={{fontWeight: "600", color: "#7F56D9", cursor: "pointer", transition: "250ms ease"}}>Resend</span></Text>
            </Box>
        </Box>
    )
}

export default Verify;

