import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/api";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import GoBack from "../../../components/goBack";
import CustomInput from "../../../components/customInput/customInput";
import CustomSelect from "../../../components/customSelect/customSelect";
import countriesData from '../../../utils/countries.json'

const Signup = () => {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    // Access individual query parameters
    const emailx = queryParams.get('email');
    const role = queryParams.get('role');
    const inviteId = queryParams.get('inviteId');
    const clientId = queryParams.get('clientId');

    const [email, setEmail] = useState(emailx);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [defaultCountry, setDefaultCountry] = useState('')

    const countries = countriesData?.map(x => ({
        title: x?.name,
        value: x?.name?.toLowerCase(),
        code: x?.code2,
        states: x?.states
      }))

    const toast = useToast()

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the user's country using ipinfo.io
        fetch("https://ipinfo.io/json?token=1920c15b6512bf")
          .then((response) => response.json())
          .then((data) => {
            const countryName = data.country; // ISO country code
            // const countryFullName = data.country_name || countryName; // Full country name (depends on the service used)
            console.log(countryName)
            // Format the response
            setDefaultCountry(countryName);
          })
          .catch((error) => console.error("Error fetching location:", error));
      }, []);
      

    const datax = clientId ?
     {
        firstName,
        lastName,
        email: email,
        password: password,
        phoneNumber: phone.charAt(0) === "0"
        ? "+234" + phone.slice(1)
        : "+234" + phone,
        role,
        inviteId,
        clientId,
        country: country || countries?.find(x => x.code === defaultCountry)?.value
    }
    :
    {
        firstName,
        lastName,
        email: email,
        password: password,
        phoneNumber: phone.charAt(0) === "0"
        ? "+234" + phone.slice(1)
        : "+234" + phone,
        role,
        inviteId,
        country: country || countries?.find(x => x.code === defaultCountry)?.value
    }
    

    const signup = async(e) => {
        e.preventDefault();
        if(password === cPassword) {
        setLoading(true)
        try {
            const {data} = await axiosInstance.post("auth/sign-up", datax)
            console.log("test",data)
            navigate("/verify", {state: {email: email}})
            toast({
                description: data?.message,
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } catch(error) {
            console.log(error?.response?.data?.message)
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
        } finally {
            setLoading(false)
        }
    } else {
        toast({
            description: "Password Mismatch, Please try again.",
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
    }
    }


    return (
        <Box>
            <GoBack />
            <Box padding="1rem">
                <Heading mt={6} fontWeight={500} fontSize="1.3rem" color="#030303" fontFamily='Roboto'>Sign up as a {role?.toLowerCase()}</Heading>
                <Text mt={2} mb="2rem" color={"#667085"} fontSize={".9rem"}>Enter your details to Sign up</Text>
                <form onSubmit={signup}>
                    <Flex gap=".8rem">
                    <CustomInput 
                    type={"text"}
                    required
                    placeholder={"First Name"}
                    mb={".8rem"}
                    onChange={e => setFirstName(e.target.value)}
                    />
                    <CustomInput 
                    type={"text"}
                    required
                    placeholder={"Last Name"}
                    mb={".8rem"}
                    onChange={e => setLastName(e.target.value)}
                    />
                    </Flex>
                    <CustomInput 
                    type={"email"}
                    required
                    placeholder={"Email Address"}
                    mb={".8rem"}
                    value={email}
                    />
                    <CustomSelect
                        required
                        options={[{title: countries?.find(x => x?.code === defaultCountry)?.title, value: countries?.find(x => x?.code === defaultCountry)?.value}, ...countries]}
                        // placeholder={"Country"}
                        onChange={e => setCountry(e.target.value)}
                        mb={".8rem"}
                    />
                    <CustomInput 
                    type={"tel"}
                    required
                    placeholder={"Phone Number"}
                    mb={".8rem"}
                    minLength={"11"}
                    maxLength={"11"}
                    onChange={e => setPhone(e.target.value)}
                    />
                    <CustomInput 
                    type={"password"}
                    required
                    placeholder={"Password"}
                    onChange={e => setPassword(e.target.value)}
                    mb={".8rem"}
                    />
                    <CustomInput 
                    type={"password"}
                    required
                    placeholder={"Confirm Password"}
                    onChange={e => setCPassword(e.target.value)}
                    />
                    <br /><br /><br />
                    <PrimaryButton type="submit" text="Sign Up" icon="next" func={() => {}} loading={loading} />
                    <Text mt="2rem" textAlign={"center"} fontSize={".9rem"} color="#475467">Already have an account? <span onClick={() => navigate("/login", {state: {userType: role}})} style={{fontWeight: "600", color: "#7F56D9", cursor: "pointer", transition: "250ms ease"}}>Login here</span></Text>
                </form>
            </Box>
        </Box>
    )
}

export default Signup;

