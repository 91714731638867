import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/api';
import { buildResourceURL } from '../../../utils/helper';
import LineSkeleton from '../../../components/lineSkeleton';
import EmptyState from '../../../components/emptyState/emptyState';
import { Box, Flex } from '@chakra-ui/react';
import CustomTable from '../../../components/customTable/customTable';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import CreateAssets from './createAssets';
import CustomDrawer from '../../../components/customDrawer/customDrawer';

const CreatedAssets = () => {
    const [assets,setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState('');
    const [triggerClose, setTriggerClose] = useState('');
    const [idx, setIdx] = useState('');

    const [idData, setIdData] = useState('');

    const queryParam = [
        {
            key: 'search',
            value: ''
        },
        {
            key: 'startDate',
            value: ''
        },
        {
            key: 'endDate',
            value: ''
        },
        {
            key: 'limit',
            value: ''
        },
        // {
        //     key: 'pending',
        //     value: 'true'
        // },
        {
            key: 'sort',
            value: '-createdAt'
        },
    ]

    const getAssets = async () => {
        setLoading(true);
        try {
          const { data } = await axiosInstance.get(buildResourceURL('/assets/on-field-users/registered', queryParam));
          // console.log(data?.data?.assets)
          setAssets(data?.data?.assets)
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false);
        }
    };
    
      useEffect(() => {
        getAssets();
      }, [refetch]);

      const tHeaders = ['Brand', 'Category', 'Type', 'Status', 'Comment']
      const tData = assets?.map(i => ({
        ...i,
        one: i?.brand,
        two: i?.category,
        three: i?.type,
        four: i?.pending === true ? 'pending' : 'approved',
        five: i?.additionalInfo,
        id: i?._id,
      }))

  return (
    <>
    <CustomDrawer
        position={"bottom"}
        title={"Create Asset"}
        triggerClose={triggerClose}
        toggleElement={
            <Flex justifyContent={'flex-end'}>
                <PrimaryButton width="" text="Create Asset" py="1rem" borderRadius="6px" icon="add" func={() => {}} loading={false} />
            </Flex>
        }
        content={
        <CreateAssets setTriggerClose={setTriggerClose} setRefetch={setRefetch} isEdit={false} />
        }
    />
    
        {loading ?
        <LineSkeleton num={8} />
        :
        assets?.length < 1 ? <EmptyState text={"No entries found for this day."} />
        :
        <Box marginTop={"1.5rem"}>
        <CustomTable
        headers={tHeaders}
        data={tData}
        setIdx2={setIdx}
        setIdx={setIdData}
        triggerClose={triggerClose}
        manage={
            <CreateAssets setTriggerClose={setTriggerClose} setRefetch={setRefetch} isEdit={true} idx={idx} idData={idData} />
        }
        />
        </Box>}
    </>
  )
}

export default CreatedAssets